import axios from "axios";
import { useState } from "react";
import { useAuth } from "../Auth/AuthProvider";
import { toast } from "react-toastify";
import { API_URL } from "../../config/api";
import { Withdraws } from "../../interfaces/withdraw";


export const useWithdraw = () => {  

    const [loadingWithdraw, setLoadingWithdraw] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const { jswToken } = useAuth();

 const createWithdraw = async (withdraw: Withdraws) => {
        setLoadingWithdraw(true);
        try {
            const response = await axios.post(`${API_URL}/`, withdraw, {
                headers: {
                    Authorization: `Bearer ${jswToken}`,
                },
            });
            toast.success("Withdraw send successfully");
            return response.data as Withdraws;
        } catch (error) {
            console.error("WITHDRAW", error);
            setError(error)
            toast.error(error.message)
        } finally {
            setLoadingWithdraw(false);
        }
    }

    return { createWithdraw, loadingWithdraw, error };
}