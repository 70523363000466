import { useEffect, useState } from "react";
import { useAffiliate } from "../../hooks/Affiliate/useAffiliate";
import { Nav } from "../../components/nav";
import { toast } from "react-toastify";

export const AffiliateDashboard = () => {
  const {
    affiliate,
    getAffiliateStats,
    createAffiliate,
    loadingAffiliateCreate,
  } = useAffiliate();
  const [isAffiliate, setIsAffiliate] = useState<boolean>(false);

  useEffect(() => {
    const init = async () => {
      const res = await getAffiliateStats();
      if (res) {
        setIsAffiliate(true);
      }
    };
    init();
  }, []);

  const handleCreateAffiliate = async () => {
    await createAffiliate();
  };
  return (
    <>
      <Nav />
      <section className="section-80 mt-5" id="advantage-section">
        <div className="custom-container">
          <div className="row">
            <div className="col-md-8 offset-md-2 text-center">
              <h2
                className="title-50 white mb-30"
                data-aos="fade-in"
                data-aos-easing="linear"
                data-aos-anchor-placement="top-bottom"
                data-aos-delay={200}
              >
                Welcome to your{" "}
                <span className="gradient-text-animate">Partner Dashboard</span>
              </h2>
              <p
                className="text-regular white mb-50"
                data-aos="fade-in"
                data-aos-easing="linear"
                data-aos-anchor-placement="top-bottom"
                data-aos-delay={400}
              >
                Ready for the next level? Earn up to 50% commissions on your refferals. 
              </p>
              <div
                className="mb-50"
                data-aos="fade-in"
                data-aos-easing="linear"
                data-aos-anchor-placement="top-bottom"
                data-aos-delay={400}
              ></div>
            </div>
          </div>
          <div
            className="row gx-3 justify-content-center my-4"
            style={{ height: "100px" }}
          >
            <div className="col-sm-8 col-md-5 d-flex align-items-center">
              <input
                disabled
                type="text"
                value={ "https://nextfunded.io/challenge?ref="+affiliate?.code || ""}
                className="input-blue me-2"
                style={{ width: "70%" }}
              />

              {!affiliate ? (
                <button
                  className="blue-btn w-50"
                  onClick={handleCreateAffiliate}
                  disabled={loadingAffiliateCreate}
                >
                  {loadingAffiliateCreate ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </>
                  ) : (
                    "Generate"
                  )}
                </button>
              ) : (
                <button
                  className="blue-btn "
                  onClick={() => {
                    navigator.clipboard
                      .writeText("https://nextfunded.io/challenge?ref=" + affiliate.code)
                      .then(() => {
                        toast.success("Affiliate code copied to clipboard!");
                      })
                      .catch((err) => {
                        console.error("Failed to copy affiliate code: ", err);
                      });
                  }}
                >
                  Copy
                </button>
              )}
            </div>
          </div>

          <div className="row gx-3">
            {/*1 card*/}
            <div className="col-md-4">
              <div
                className="card-affiliate"
                data-aos="fade-in"
                data-aos-easing="linear"
                data-aos-anchor-placement="top-bottom"
                data-aos-delay={600}
              >
                <h3 className="title-24 white">Withdraw available</h3>
                <p className="title-50 white">{affiliate?.amountWithdraw  ? affiliate.amountWithdraw + "$" : "0$"}</p>
              </div>
            </div>
         
            <div className="col-md-4">
              <div
                className="card-affiliate"
                data-aos="fade-in"
                data-aos-easing="linear"
                data-aos-anchor-placement="top-bottom"
                data-aos-delay={600}
              >
                <h3 className="title-24 white">Affiliate amount</h3>
                <p className="title-50 white">{affiliate?.amountReffered  ? affiliate.amountReffered + "$" : "0$"}</p>
              </div>
            </div>
            <div className="col-md-4">
              <div
                className="card-affiliate"
                data-aos="fade-in"
                data-aos-easing="linear"
                data-aos-anchor-placement="top-bottom"
                data-aos-delay={600}
              >
                <h3 className="title-24 white">Affiliate Member</h3>
                <p className="title-50 white">{affiliate?.usersReffered  ? affiliate.usersReffered : "0"}</p>
              </div>
            </div>
     
          </div>
        </div>
      </section>
    </>
  );
};
