import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from "react";
import { useAuth } from "../Auth/AuthProvider";
import { useOrders } from "../Order/useOrder";
import { usePositions } from "../Positions/usePositions";

interface SocketProviderProps {
  children: ReactNode;
}

interface WebSocketContextType {
  websocket: WebSocket | null;
  webSocketMessage: string;
  wsIsAuthenticated: boolean;
  refreshPositions: boolean;
  handleWebSocketMessage: (websocket: WebSocket, message: string) => void;
}

interface OrderUpdateMessage {
  type: string;
  orderId: string;
}

export const WebSocketContext = createContext<WebSocketContextType | null>(
  null
);

export const useWebSocket = () => {
  const context = useContext(WebSocketContext);
  if (context === null)
    throw new Error("useWebSocket must be used within a WebSocketProvider");
  return context;
};

export const WebSocketProvider: React.FC<SocketProviderProps> = ({
  children,
}) => {
  const [websocket, setWebsocket] = useState<WebSocket | null>(null);
  const [webSocketMessage, setWebSocketMessage] = useState<string>("");
  const { jswToken, getUser } = useAuth();
  const [refreshPositions, setRefreshPositions] = useState(false);
  const [wsIsAuthenticated, setWsIsAuthenticated] = useState(false);
  const { getAllOrders } = useOrders();
  const { getAllPositions } = usePositions();
  const handleWebSocketMessage = (
    websocket: WebSocket | null,
    message: any
  ) => {
    if (websocket && websocket.readyState === WebSocket.OPEN) {
      websocket.send(JSON.stringify(message));
      websocket.onmessage = (event) => {
        const messageReceive = JSON.parse(event.data);
        setWebSocketMessage(messageReceive);
        console.log(webSocketMessage);
      };
    } else {
      console.error("WebSocket is not open. Message not sent.");
    }
  };

  useEffect(() => {
    const newWebsocket = new WebSocket(
      "wss://api.nextfunded.io/stream/order-update"
    );

    newWebsocket.onopen = () => {
      console.log("WebSocket connection established");
      newWebsocket.send(
        JSON.stringify({
          type: "authenticate",
          token: jswToken,
        })
      );
    };

    newWebsocket.onmessage = async (event) => {
      const messageData = event.data;
      let messageJson;
      if (typeof messageData === "string") {
        try {
          messageJson = JSON.parse(messageData);
          setRefreshPositions(false);
          if (messageJson.type === "authenticated") {
            setWsIsAuthenticated(true);
            setWebSocketMessage(messageJson);
          }
        } catch (error) {
          console.error("Erreur lors du parsing du message JSON:", error);
        }
      } else if (messageData instanceof Blob) {
        const text = await messageData.text();
        try {
          const jsonData = await JSON.parse(text);
          if (jsonData.data.event === "pending_new" || jsonData.data.event === "new") {
            await getAllOrders();
            getUser(jswToken)
          }
          if (jsonData.data.event === "fill" || jsonData.data.event === "partially_filled") {
            await getAllOrders();
            await getAllPositions();
            setRefreshPositions(true);
            getUser(jswToken)
          }
        } catch (error) {
          console.error(
            "Erreur lors du parsing du JSON à partir des données binaires:",
            error
          );
        }
      }
     
    };

    newWebsocket.onerror = (error) => {
      console.error("WebSocket error", error);
    };

    newWebsocket.onclose = () => {
      setWsIsAuthenticated(false);
      console.log("WebSocket connection closed");
    };
    setWebsocket(newWebsocket);
  }, []);

  const value = {
    websocket,
    webSocketMessage,
    wsIsAuthenticated,
    handleWebSocketMessage,
    refreshPositions,
  };

  return (
    <WebSocketContext.Provider value={value}>
      {children}
    </WebSocketContext.Provider>
  );
};
