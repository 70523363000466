import bicolorLogo from "../media/logo/bicolor.png";

export const Footer = () => {

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <footer
      data-aos="fade-zoom-in"
      data-aos-easing="ease-in-back"
      data-aos-delay={300}
      data-aos-offset={0}
    >
      <div className="custom-container footer align">
        <div className="row align-items-center">
          <div className="col-md-5 text-lg-start text-center">
            <img
              className="mb-10"
              src={bicolorLogo}
              alt=""
              style={{ width: "200px", height: "auto" }}
            />
            <h4 className="title-16 white">
              The Next{" "}
              <span className="gradient-text-animate">profitable trader</span>{" "}
              is you
            </h4>
          </div>

          <div className="col-md-7  footer-responsive text-lg-end text-center">
         
          </div>

        </div>
        <hr style={{ borderColor: "white", color: "white" }} />
        <div className="row">
          <div className="col-md-6 text-start ">
            <p className="text-regular white-50 text-lg-start text-center">
              ©2024 Next Funded - All rights reserved
            </p>
          </div>
          <div className="col-md-6 text-center d-flex text-md-end" style={{flexDirection : "column"}}>
            <a
              className="footer-link-desktop white d-block d-md-inline mb-2 "
              href="mailto:support@nextfunded.io"
            >
              support@nextfunded.io
            </a>
            <a
              className="footer-link-desktop white d-block d-md-inline mb-2"
              href="/privacy"
            >
              Privacy Policies
            </a>
            <a
              className="footer-link-desktop white d-block d-md-inline mb-2 "
              href="/terms"
            >
              Terms &amp; Conditions
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};
