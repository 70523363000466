import { Nav } from "../../components/nav"
import { Foot } from "../../components/foot"
import { useState } from "react"
import faqData from "../../config/faq-data.json";
import { Navbar } from "../../components/navbar";


export const Faq = () => {

    const [categorySelected, setCategorySelected] = useState<any>("1");

    const handleSelectCategory = (e: any) => {
        setCategorySelected(e.target.id);
    }


    return (
        <>
            <Navbar />
            <section className="section-100">
                <div className="custom-container">
                    <div className="row">
                        <div className="col-md-3 text-start">
                            <span className="text-bold blue-pill d-inline-block mb-10">FAQ's</span>
                            <h2 className="title-40 white mb-30">Most asked <span className="gradient-text-animate">Questions</span></h2>

                            <button className={categorySelected == 1 ? "faq-category-selected w-100 text-start mb-10" :`faq-category w-100 text-start mb-10`} onClick={handleSelectCategory} id="1">
                                General
                            </button>
                            <button className={categorySelected == 2 ? "faq-category-selected w-100 text-start mb-10" :`faq-category w-100 text-start mb-10`} onClick={handleSelectCategory} id="2">
                                Getting started
                            </button>
                            <button className={categorySelected == 3 ? "faq-category-selected w-100 text-start mb-10" :`faq-category w-100 text-start mb-10`} onClick={handleSelectCategory} id="3">
                                Orders and Billing
                            </button>
                            <button className={categorySelected == 4 ? "faq-category-selected w-100 text-start mb-10" :`faq-category w-100 text-start mb-10`} onClick={handleSelectCategory} id="4">
                                Payouts
                            </button>
                            <button className={categorySelected == 5 ? "faq-category-selected w-100 text-start mb-10" :`faq-category w-100 text-start mb-10`} onClick={handleSelectCategory} id="5">
                                Platforms
                            </button>
                            <button className={categorySelected == 6 ? "faq-category-selected w-100 text-start mb-10" :`faq-category w-100 text-start mb-10`} onClick={handleSelectCategory} id="6">
                                Rules
                            </button>
                            <button className={categorySelected == 7 ? "faq-category-selected w-100 text-start mb-10" :`faq-category w-100 text-start mb-10`} onClick={handleSelectCategory} id="7">
                                Trading
                            </button>
                        </div>
                        <div className="col-md-1">
                            <hr />
                        </div>

                        <div className="col-md-8">
                            <div className="accordion faq-accordion" id="faqAccordion" style={{maxHeight : "100vh", overflow : "scroll"}}>


                                {
                                    faqData[categorySelected].map((item: any, index: number) => {
                                        return (
                                            <div className="accordion-item mb-20" key={index}>
                                                <h2 className="accordion-header" id={`heading${index}`}>
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="true" aria-controls={`collapse${index}`}>
                                                        {item.question}
                                                    </button>
                                                </h2>
                                                <div id={`collapse${index}`} className="accordion-collapse collapse" aria-labelledby={`heading${index}`} data-bs-parent="#faqAccordion">
                                                    <div className="accordion-body white">
                                                        {item.response}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    )
                                }
                            </div>

                        </div>
                    </div>

                </div>
            </section>
            <Foot />
        </>
    )
}