import bicolorLogo from "../media/logo/bicolor.png";

export const Foot = () => {


    return (
        <footer
       
        >
            <div className="custom-container footer align">
                <div className="row align-items-center">
                    <div className="col-md-5 text-lg-start text-center">
                        <a href="/">
                            <img
                                className="mb-10"
                                src={bicolorLogo}
                                alt=""
                                style={{ width: "200px", height: "auto" }}
                            />
                        </a>
                        <h4 className="title-16 white">
                            The Next{" "}
                            <span className="gradient-text-animate">profitable trader</span>{" "}
                            is you
                        </h4>
                    </div>



                </div>
                <hr style={{ borderColor: "white", color: "white" }} />
                <div className="row">
                    <div className="col-md-6 text-start ">
                        <p className="text-regular white-50 text-lg-start text-center">
                            ©2024 Next Funded - All rights reserved
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
};
