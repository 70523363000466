import { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../../config/api";
import { useAuth } from "../Auth/AuthProvider";
import { Asset, AssetBeforeInit } from "../../interfaces/asset";
import { toast } from "react-toastify";

export const useAssets = () => {
    const [currentPrice, setCurrentPrice] = useState(0);
    const [assets, setAssets] = useState<Asset[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [loadingAssets, setLoadingAssets] = useState<boolean>(false);
    const [lowPrice, setLowPrice] = useState<number>(0);
    const [highPrice, setHighPrice] = useState<number>(0);
    const [changePrice, setChangePrice] = useState<number>(0);
    const { jswToken } = useAuth();
      
    const getAllAssets = async () => {
        setLoadingAssets(true);
        try {
            const response = await axios.get(`${API_URL}/assets/all`, {
                headers: {
                    Authorization: `Bearer ${jswToken}`,
                },
            });
            setAssets(response.data as Asset[])
        } catch (error) {
            console.error("Erreur lors de la récupération de la data :", error);
            setError(error)
        }
        finally {
            setLoadingAssets(false);
        }

    }

  // fonction pour récupérer le prix actuel de l'asset sélectionné si c'est une crypto
  async function fetchCurrentPriceForCrypto(assetSelected: Asset | AssetBeforeInit) {
    const url = `${API_URL}/assets/price?symbol=${assetSelected.symbol}&assetClass=crypto`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${jswToken}`,
        },
      });
      console.log(response.data);
      setCurrentPrice(response.data.quote[assetSelected.symbol].ap);
    //  setLowPrice(response.data.bars.l.toFixed(2))
      //setHighPrice(response.data.bars.h.toFixed(2))
      //setChangePrice((response.data.bars.o - response.data.bars.c) / response.data.bars.o * 100)
    } catch (error) {
      console.error("Erreur lors de la récupération du prix :", error);
      setCurrentPrice(0);
    }
  }

  // fonction pour récupérer le prix actuel de l'asset sélectionné si c'est une action ou stock
  async function fetchCurrentPriceForAsset(assetSelected: Asset | AssetBeforeInit) {
    const url = `${API_URL}/assets/price?symbol=${assetSelected.symbol}&assetClass=us_equity`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${jswToken}`,
        },
      });
      setCurrentPrice(response.data.quote.ap);
      setLowPrice(response.data.bars.l)
      setHighPrice(response.data.bars.h)
      setChangePrice((response.data.bars.o - response.data.bars.c) / response.data.bars.o * 100)
    } catch (error) { 
      toast.error("Error retrieving price");
    }
  }

    useEffect(() => {
        getAllAssets();
    }, [])

    return {getAllAssets, fetchCurrentPriceForAsset, fetchCurrentPriceForCrypto, currentPrice, assets, loadingAssets, lowPrice, highPrice, changePrice, }
}