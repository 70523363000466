import React, { useState } from "react";
import { getAuth, confirmPasswordReset } from "firebase/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import logoBicolor from "../../media/logo/bicolor.png";

export const Resetpassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [ResetPasswordError, setResetPasswordError] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const oobCode = queryParams.get("oobCode");

  const handlePasswordReset = async () => {
    if (newPassword !== confirmPassword) {
      setResetPasswordError("Les mots de passe ne correspondent pas.");
      return;
    }
    setLoading(true);
    try {
      const auth = getAuth();
      await confirmPasswordReset(auth, oobCode, newPassword);
      toast.success("Your password has been successfully updated.");
      navigate("/signin");
    } catch (err) {
      setResetPasswordError(
        "Incorrect password, please try again." +
          err.message
      );
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="bg-full">
        <section className=" section-100 section-full d-flex justify-content-center align-items-center">
          <div className="custom-container">
            <div className="row justify-content-center">
              <div className="col-md-5 text-center">
                <a href="/">
                  <img
                    className="mb-20"
                    src={logoBicolor}
                    style={{ width: "150px", height: "auto" }}
                    alt=""
                  />
                </a>
                <h1 className="title-50 white mb-30">Reset your password</h1>
                <div className="input-groupp mb-20 text-start">
                  <span className="input-label white mb-10 d-block">
                    New password
                  </span>
                  <input
                    className="input-blue d-block"
                    type="password"
                    placeholder="Enter your new password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </div>
                <div className="input-groupp mb-20 text-start">
                  <span className="input-label white mb-10 d-block">
                    Confirm password
                  </span>
                  <input
                    className="input-blue d-block"
                    type="password"
                    placeholder="Confirm your new password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
                {ResetPasswordError && (
                  <p className="text-regular red">{ResetPasswordError}</p>
                )}
                <button
                  onClick={handlePasswordReset}
                  className="blue-btn mb-20 w-100"
                  style={{ zIndex: 999999 }}
                >
                  Reset my password
                  {loading && (
                    <span
                      className="spinner-border spinner-border-sm "
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
