import {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import { auth } from "../../config/firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword as firebaseSignInWithEmailAndPassword,
  getAuth,
} from "firebase/auth";
import {
  signInWithPopup,
  GoogleAuthProvider,
  onAuthStateChanged,
  signOut,
  FacebookAuthProvider,
} from "firebase/auth";
import axios from "axios";
import { API_URL } from "../../config/api";
import { User } from "../../interfaces/user";
import { getAuthErrorMessage } from "../../utils/utils";
import { Loading } from "../../components/loading";

interface AuthContextType {
  currentUser: User | null;
  logout: () => Promise<void>;
  getUser: (token: string) => Promise<User>;
  updateUser: (data: any, token: string) => Promise<User>;
  createAccount: (email: string, password: string, data: any) => Promise<User>;
  signInWithEmailAndPwd: (email: string, password: string) => Promise<User>;
  authError: string | null;
  jswToken: string;
  authLoading: boolean;
  setAuthError: (error: string) => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthContext = createContext<AuthContextType | null>(null);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [authLoading, setAuthLoading] = useState(true);
  const [authError, setAuthError] = useState<string | null>(null);
  const [jswToken, setJswToken] = useState("");

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const token = await user.getIdToken();
        setJswToken(token);
        await getUser(token);
      }
      setAuthLoading(false)
    });
    return unsubscribe;
  }, []);

  const getUser = async (token: string) => {
    if (!token) return;
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(`${API_URL}/users/me`, config);
      setCurrentUser(response.data as User);
      return response.data as User;
    } catch (error) {
      setAuthError(
        "AUTH PROVIDER :" + error
      );
      console.error(error);
      return null;
    }
  };

  const updateUser = async (data: any, token: string) => {
    if (!token) return;
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.put(`${API_URL}/users/me`, data, config);
      if (response) {
        setCurrentUser(data as User);
        return response.data as User;
      }
    } catch (error) {
      setAuthError(
        "AUTH PROVIDER :" + error
      );
      console.error(error);
      return null;
    }
  }

  const signInWithEmailAndPwd = async (email: string, password: string) => {
    try {
      await firebaseSignInWithEmailAndPassword(auth, email, password);
      const token = await auth.currentUser?.getIdToken();
      setJswToken(token);
      const user = await getUser(token);
      return user;
    } catch (error) {
      const errorMessage = getAuthErrorMessage(error);
      setAuthError(errorMessage);
      return null;
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      console.log("AUTH PROVIDER : Sign out successful.");
      window.location.reload()
    } catch (error) {
      setAuthError("Error :" + error);
      console.error(authError);
    }
  };

  const createAccount = async (email: string, password: string, data: any) => {
    const auth = getAuth();
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      if (userCredential.user) {
        const token = await userCredential.user.getIdToken();
        setJswToken(token);
        const user = await updateUser(data, token)
        return user;
      } else {
        console.error("Error : No userCredential found.");
        return null;
      }
    } catch (error) {
      const errorMessage = getAuthErrorMessage(error);
      setAuthError(errorMessage);
      return null;
    }
  };


  const value = {
    authError,
    currentUser,
    logout,
    signInWithEmailAndPwd,
    createAccount,
    jswToken,
    authLoading,
    getUser,
    setCurrentUser,
    updateUser,
    setAuthError
  };

  return (
    <AuthContext.Provider value={value}>
      {authLoading ? <Loading /> : children}
    </AuthContext.Provider>
  );
};
