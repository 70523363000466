import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/Auth/AuthProvider";
import { UserStatus } from "../interfaces/user";
import bicolorLogo from "../media/logo/bicolor.png";
import { useRef, useState } from "react";
import faqIcon from "../media/faq-icon.png";
import contactIcon from "../media/contact-icon.png";

export const Navbar = () => {
  const [isHovering, setIsHovering] = useState(false);
  const containerRef = useRef(null);
  const { currentUser, logout } = useAuth();
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <>
      {/* DESKTOP */}
      <nav className="fixed-top d-none d-md-block">
        <div className="nav-container">
          <div className="navbarz row align-items-center">
            <div className="col-auto">
              <a href="/">
                <img
                  src={bicolorLogo}
                  style={{ width: "150px", height: "auto" }}
                  alt=""
                />
              </a>
            </div>

            <div className="col-auto text-center   align-items-center mx-auto">
              <a
                style={{ cursor: "pointer" }}
                className="nav-link-desktop"
                onClick={() => scrollToSection("work-section")}
              >
                How it works
              </a>
              <a
                style={{ cursor: "pointer" }}
                className="nav-link-desktop"
                href="/challenge"
              >
                Programs
              </a>

              <div className="dropdown-container"
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
                ref={containerRef}>
                <span
                className="nav-link-desktop"
                >
                  Support
                </span>
                {isHovering && (
                  <div className="dropdown-menu">
                    <a href="/faq" className="nav-link-desktop dropdown-link"><img width={25} className="me-1" src={faqIcon} />FAQ</a>
                    <a href="/" className="nav-link-desktop dropdown-link"><img width={25} className="me-1" src={contactIcon} />Contact Us</a>
                  </div>
                )}
              </div>


              <a
                style={{ cursor: "pointer" }}
                className="nav-link-desktop"
                href="/signup?redirect=affiliateDashboard"
              >
                Become Partner
              </a>
            </div>

            <div className="col-auto">
              {currentUser ? (
                currentUser.status === UserStatus.ACTIVE ? (
                  // Si currentUser existe et est actif
                  <>
                    <a
                      className="nav-button-transparent"
                      style={{ cursor: "pointer" }}
                      onClick={logout}
                    >
                      Logout
                    </a>
                    <a
                      style={{ cursor: "pointer" }}
                      className="nav-button"
                      href="/app"
                    >
                      Launch App
                    </a>
                  </>
                ) : (
                  <>
                    <a
                      className="nav-button-transparent"
                      style={{ cursor: "pointer" }}
                      onClick={logout}
                    >
                      Logout
                    </a>
                    <a
                      href="/challenge"
                      style={{ cursor: "pointer" }}
                      className="nav-button"
                    >
                      Get Funded
                    </a>
                  </>
                )
              ) : (
                <>
                  <a href="/signin" className="nav-button-transparent">
                    Sign In
                  </a>
                  <a
                    href="/challenge"
                    style={{ cursor: "pointer" }}
                    className="nav-button"
                  >
                    Get Funded
                  </a>
                </>
              )}
            </div>
          </div>
        </div>
      </nav>

      {/* MOBILE */}
      <nav className=" navbarz-mobile fixed-top d-block d-md-none">
        <div className="nav-container">
          <div className="row align-items-center justify-content-between mx-auto my-auto">
            <div className="col-auto">
              <a href="/">
                <img
                  src={bicolorLogo}
                  style={{ width: "150px", height: "auto" }}
                  alt=""
                />
              </a>
            </div>
            <div className="col-auto">
              <a
                href="/"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRight"
                aria-controls="offcanvasRight"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width={32}
                  height={32}
                  fill="rgba(255,255,255,1)"
                >
                  <path d="M16 18V20H5V18H16ZM21 11V13H3V11H21ZM19 4V6H8V4H19Z" />
                </svg>
              </a>
              <div
                className="nav-mobile offcanvas offcanvas-end"
                tabIndex={-1}
                id="offcanvasRight"
                aria-labelledby="offcanvasRightLabel"
              >
                <div
                  className="offcanvas-header mb-40"
                  style={{ marginTop: "10px" }}
                >
                  <img
                    src={bicolorLogo}
                    className="img-fluid"
                    style={{ width: "150px", height: "auto" }}
                    alt=""
                  />
                  <button
                    type="button"
                    className="btn-close text-reset white"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  />
                </div>
                <div
                  className="offcanvas-body d-flex flex-column justify-content-between"
                  style={{ height: "100%" }}
                >
                  <div className="col-auto text-center   align-items-center mx-auto">
                  <a
                      style={{ cursor: "pointer" }}
                      className="nav-link-mobile"
                      onClick={() => scrollToSection("work-section")}
                    >
                      How it works
                    </a>

                    <a
                      style={{ cursor: "pointer" }}
                      className="nav-link-mobile"
                      href="/challenge"
                    >
                      Programs
                    </a>


                    <a
                      style={{ cursor: "pointer" }}
                      className="nav-link-mobile"
                      href="/signup?redirect=affiliateDashboard"
                    >
                      Become Partner
                    </a>

                    <a
                      style={{ cursor: "pointer" }}
                      className="nav-link-mobile"
                      href="/faq"
                    >
                      FAQ
                    </a>
                  </div>

                  <div>
                    <hr className="hr-white-50 mb-20" />
                    {currentUser ? (
                      currentUser.status === UserStatus.ACTIVE ? (
                        <>
                          <a
                            style={{ cursor: "pointer" }}
                            className="blue-btn mr-10 text-center d-block mb-20"
                            href="/app"
                          >
                            Launch App
                          </a>
                        </>
                      ) : (
                        <>
                          <a
                            className="transparent-btn mr-10 text-center d-block"
                            style={{ cursor: "pointer", width: "100%" }}
                            onClick={logout}
                          >
                            Logout
                          </a>
                          <a
                            style={{ cursor: "pointer" }}
                            className="blue-btn mr-10 text-center d-block mb-20"
                            href="/challenge"
                          >
                            Get Funded
                          </a>
                        </>
                      )
                    ) : (
                      <>
                        <a
                          href="/signin"
                          className="transparent-btn mr-10 text-center d-block"
                        >
                          Sign In
                        </a>
                        <a
                          style={{ cursor: "pointer" }}
                          className="blue-btn mr-10 text-center d-block mb-20"
                          href="/challenge"
                        >
                          Get Funded
                        </a>
                      </>
                    )}

                    <hr className="hr-white-50 mb-20" />

                    <a
                      style={{ cursor: "pointer" }}
                      className="nav-minilink-mobile"
                      href="https://discord.gg/Sd7XdA5DYQ"
                      target="_blank"
                    >
                      Discord
                    </a>
                    <a
                      style={{ cursor: "pointer" }}
                      className="nav-minilink-mobile"
                      href="https://www.instagram.com/next_funded/"
                      target="_blank"
                    >
                      Instagram
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};
