import { useContext, useEffect, useState } from "react";
import { redirect, useLocation, useNavigate } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";
import Hotjar from "@hotjar/browser";
import { Nav } from "../../components/nav";
import { Foot } from "../../components/foot";
import { useCheckout } from "../../hooks/Checkout/useCheckout";
import { AuthContext, useAuth } from "../../hooks/Auth/AuthProvider";
import pricingDATA from "../../config/pricing.json";
import thunderImg from "../../media/thunder1.png";
import thunder2Img from "../../media/thunder2.png";
import MetaTraderLogo from "../../media/logo/MetaTrader2.png";
import { toast } from "react-toastify";
import countryJson from "../../config/country.json";

export const SelectChallenge: React.FC = () => {
  const { currentUser, createAccount, authError, setAuthError } =
    useContext(AuthContext);
  const navigate = useNavigate();
  const { checkDiscountCode } = useCheckout();
  const [userForm, setUserForm] = useState({
    firstname: "",
    lastname: "",
    city: "",
    addressLine1: "",
    addressLine2: "",
    zipCode: "",
    state: "",
    country: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [currency, setCurrency] = useState(
    queryParams.get("currency") ? queryParams.get("currency") : "eur"
  );
  const [insuranceAdded, setInsuranceAdded] = useState(false);
  const [profitSplit, setProfitSplit] = useState(false);
  const [selectedPhase, setSelectedPhase] = useState(
    queryParams.get("phase") ? parseInt(queryParams.get("phase")) : 1
  );
  const [selectMetaTrader, setSelectMetaTrader] = useState(4);
  const [stepPrice, setStepPrice] = useState<null | string>();
  const [indexSelected, setIndexSelected] = useState(
    queryParams.get("index") ? parseInt(queryParams.get("index")) : 3
  );
  const [referral, setReferral] = useState(queryParams.get("ref"));
  const [totalPrice, setTotalPrice] = useState<null | number>(
    pricingDATA[currency][selectedPhase][indexSelected].base
  );
  const [discountActive, setDiscountActive] = useState(false);
  const [discount, setDiscount] = useState<any>(null);
  const [promo, setPromo] = useState<string | null>(queryParams.get("promo"));
  const [promoError, setPromoError] = useState("");
  const [selectChallengeError, setSelectChallengeError] = useState<
    string | null
  >();
  const [formDone, setFormDone] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    ReactPixel.track("ViewContent", { page: "challenge" });
    Hotjar.stateChange(location.pathname);
    if (promo) {
      handlePromoCode();
    }
  }, []);

  useEffect(() => {
    setInsuranceAdded(false);
    setProfitSplit(false);
    setTotalPrice(pricingDATA[currency][selectedPhase][indexSelected].base);
  }, [selectedPhase, indexSelected]);

  const isObjectFieldsValid = (obj) => {
    return Object.values(userForm).every((value) => value.trim() !== "");
  };

  const handleSubmit = async () => {
    setLoading(true);

    if (userForm.addressLine2 == "") {
      userForm.addressLine2 = userForm.addressLine1;
    }
    if (userForm.password !== userForm.confirmPassword) {
      setAuthError("Passwords do not match.");
      scrollToSection("form-section");
      setLoading(false);
      return false;
    }

    if (!isObjectFieldsValid(userForm) && !currentUser) {
      toast.error("Please fill out all required fields.");
      scrollToSection("form-section");
      setLoading(false);
      return false;
    }

    try {
      setFormDone(true);
      await createAccount(userForm.email, userForm.password, {
        firstName: userForm.firstname,
        lastName: userForm.lastname,
        city: userForm.city,
        addressLine1: userForm.addressLine1,
        addressLine2: userForm.addressLine2,
        zipCode: userForm.zipCode,
        state: userForm.state,
        country: userForm.country,
      });
      setLoading(false);
      return true;
    } catch (error) {
      console.log(error);
      setLoading(false);
      return false;
    }
  };

  const handleAddInsurance = (e) => {
    const newInsuranceState = !insuranceAdded;
    setInsuranceAdded(newInsuranceState);
    e.preventDefault();
    if (newInsuranceState) {
      setTotalPrice((prevPrice) =>
        parseFloat(
          (
            prevPrice +
            pricingDATA["eur"][selectedPhase][indexSelected].insurance
          ).toFixed(2)
        )
      );
    } else {
      setTotalPrice((prevPrice) =>
        parseFloat(
          (
            prevPrice -
            pricingDATA["eur"][selectedPhase][indexSelected].insurance
          ).toFixed(2)
        )
      );
    }
    Hotjar.event("insurance-click");
  };

  const handleProfitSplit = (e) => {
    const newProfitSplitState = !profitSplit;
    setProfitSplit(newProfitSplitState);
    e.preventDefault();

    if (newProfitSplitState) {
      setTotalPrice((prevPrice) =>
        parseFloat(
          (
            prevPrice + pricingDATA[currency][selectedPhase][indexSelected].pf
          ).toFixed(2)
        )
      );
    } else {
      setTotalPrice((prevPrice) =>
        parseFloat(
          (
            prevPrice - pricingDATA[currency][selectedPhase][indexSelected].pf
          ).toFixed(2)
        )
      );
    }
  };

  const handlePhaseClick = (phase: number) => {
    setPromo("");
    setDiscountActive(false);
    setSelectedPhase(phase);
  };

  const handleAccountSelected = (index: number) => {
    setIndexSelected(index);
    setPromo("");
    setDiscountActive(false);
  };

  const handleTermsChange = (event) => {
    setIsTermsChecked(event.target.checked);
  };

  const handlePaymentClick = async () => {
    setLoading(true);

    try {
      if (!currentUser) {
        const res = await handleSubmit();
        if (!res) {
          setLoading(false);
          return;
        }
      }

      if (!selectedPhase || indexSelected === undefined) {
        toast.error("Please select a challenge");
        scrollToSection("price-section");
        setLoading(false);
        return;
      }

      if (!isTermsChecked) {
        toast.error("Please accept the terms and conditions");
        setLoading(false);
        return;
      }

      Hotjar.event("add-to-card");

      let challengeId;
      let upsellCode = 0;
      if (selectedPhase === 1) {
        challengeId = indexSelected + 1;
      } else {
        challengeId = indexSelected + 1 + pricingDATA[currency]["1"].length;
      }

      if (profitSplit && insuranceAdded) upsellCode = 3;
      else if (profitSplit) upsellCode = 2;
      else if (insuranceAdded) upsellCode = 1;

      const url = discountActive
        ? `/checkoutform?challenge-id=${parseInt(
            challengeId
          )}&options=${upsellCode}&currency=${currency}&promo=${promo.toUpperCase()}&ref=${referral}&platform=${selectMetaTrader}&indexselected=${indexSelected}`
        : `/checkoutform?challenge-id=${parseInt(
            challengeId
          )}&options=${upsellCode}&currency=${currency}&ref=${referral}&platform=${selectMetaTrader}&indexselected=${indexSelected}`;

       ReactPixel.track("AddToCart", {
        challengeId: challengeId,
        price: totalPrice,
        currency: currency,
        content_type: "product",
        options: upsellCode,
      });

      setLoading(false);
      navigate(url);
      window.scrollTo(0, 0);
      return url;
    } catch (error) {
      console.error("Error during handlePaymentClick:", error);
      setLoading(false);
    }
  };

  const handlePromoCode = async () => {
    if (promo.length < 1) return;

    const discount = await checkDiscountCode(promo.toUpperCase());

    if (!discount) {
      setPromoError("Invalid promo code");
      return;
    }

    if (
      !discount.challenges.some(
        (item) => item.challengeId === indexSelected + 1
      )
    ) {
      setDiscountActive(false);
      setPromoError("Invalid challenge for this code");
      return;
    }
    setPromoError("");
    setDiscount(discount);
    setDiscountActive(true);
    setTotalPrice((prevPrice) =>
      Math.round(prevPrice - prevPrice * (discount.discount / 100))
    );
  };

  return (
    <>
      <Nav />
      {/*start trading journey DESKTOP*/}
      <section className="section-100 ">
        <div className="checkout-progress ">
          <div className="checkout-progress-stage checkout-progress-stage--active ">
            <span className="checkout-progress-stage__number title-16">1</span>
            <div className="checkout-progress-stage__content">
              <div className="flex-col">
                <span className="title-16 ">You Are Here</span>
                <br />
                <span className="title-18">Choose Your Account</span>
              </div>
            </div>
          </div>
          <div className="checkout-progress-stage ">
            <span className="checkout-progress-stage__number title-16">2</span>
            <div className="checkout-progress-stage__content">
              <div className="flex-col">
                <span className="title-16 white-70">Next Stage</span>
                <br />
                <span className="title-18">Review Your Order</span>
              </div>
            </div>
          </div>
          <div className="checkout-progress-stage">
            <span className="checkout-progress-stage__number title-16">3</span>
            <div className="checkout-progress-stage__content">
              <div className="flex-col">
                <span className="title-16 white-70">Last Stage</span>
                <br />
                <span className="title-18">Start Trading</span>
              </div>
            </div>
          </div>
        </div>

        <div className="custom-container ">
          <div className="row gx-4">
            <div className="col-md-8 col-12 ">
              <h2 className="title-35 white mb-0">Start a New Challenge</h2>
              <p className="text-regular white-70 mb-30">
                Select a one phase or two phase assessment to start your trading
                journey today.
              </p>
              <div className="row gx-2">
                <div
                  className="col-md-6 mb-10"
                  onClick={() => handlePhaseClick(1)}
                >
                  <div
                    className={`phase-selector mb-10 ${
                      selectedPhase === 1 ? "price-selector-selected" : ""
                    }`}
                  >
                    <div className="row justify-content-center align-items-center">
                      <div className="col-12 text-center mb-20">
                        <img
                          src={thunderImg}
                          width="80px"
                          className="img-fluid mb-10"
                          alt=""
                        />
                        <h3 className="title-24 white mb-0">1 phase</h3>
                      </div>
                      <div className="col-12 my-2 text-center"></div>
                    </div>
                  </div>
                </div>

                <div
                  className="col-md-6 mb-10"
                  onClick={() => handlePhaseClick(2)}
                >
                  <div
                    className={`phase-selector mb-10 ${
                      selectedPhase === 2 ? "price-selector-selected" : ""
                    }`}
                  >
                    <div className="row justify-content-center align-items-center">
                      <div className="col-12 text-center mb-20">
                        <img
                          src={thunder2Img}
                          width="80px"
                          className="img-fluid mb-10"
                          alt=""
                        />
                        <h3 className="title-24 white mb-0">2 phase</h3>
                      </div>
                      <div className="col-12 my-2 text-center"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 mb-50" id="price-section">
                <h2 className="title-24 white">Account Balance</h2>
                <p className="text-regular white-70 mb-30">
                  Select your initial starting capital.
                </p>
                <div className="row gx-2">
                  {/*1 item*/}
                  <div className="col-md-6 ">
                    <div
                      className={`price-selector mb-10 ${
                        indexSelected === 0 ? "price-selector-selected" : ""
                      }`}
                      onClick={() => handleAccountSelected(0)}
                    >
                      <div className="row justify-content-center align-items-center">
                        <div className="col-2 text-start">
                          <label className="custom-radio-btn">
                            <input
                              type="radio"
                              name="accountBalance"
                              checked={indexSelected === 0}
                            />
                            <span className="checkmark" />
                          </label>
                        </div>
                        <div className="col-6 text-center">
                          <h3 className="title-24 white mb-0">
                            <span className="text-regular">$</span>5000
                          </h3>
                        </div>
                        <div className="col-4 text-end">
                          <span className="blue-pill title-16 white text-bold">
                            {pricingDATA["eur"][selectedPhase][0].base}$
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`price-selector mb-10 ${
                        indexSelected === 1 ? "price-selector-selected" : ""
                      }`}
                      onClick={() => handleAccountSelected(1)}
                    >
                      <div className="row justify-content-center align-items-center">
                        <div className="col-2 text-start">
                          <label className="custom-radio-btn">
                            <input
                              type="radio"
                              name="accountBalance"
                              checked={indexSelected === 1}
                            />
                            <span className="checkmark" />
                          </label>
                        </div>
                        <div className="col-6 text-center">
                          <h3 className="title-24 white mb-0">
                            <span className="text-regular">$</span>10 000
                          </h3>
                        </div>
                        <div className="col-4 text-end">
                          <span className="blue-pill title-16 white text-bold">
                            {pricingDATA["eur"][selectedPhase][1].base}$
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`price-selector mb-10 ${
                        indexSelected === 2 ? "price-selector-selected" : ""
                      }`}
                      onClick={() => handleAccountSelected(2)}
                    >
                      <div className="row justify-content-center align-items-center">
                        <div className="col-2 text-start">
                          <label className="custom-radio-btn">
                            <input
                              type="radio"
                              name="accountBalance"
                              checked={indexSelected === 2}
                            />
                            <span className="checkmark" />
                          </label>
                        </div>
                        <div className="col-6 text-center">
                          <h3 className="title-24 white mb-0">
                            <span className="text-regular">$</span>15 000
                          </h3>
                        </div>
                        <div className="col-4 text-end">
                          <span className="blue-pill title-16 white text-bold">
                            {pricingDATA["eur"][selectedPhase][2].base}$
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`price-selector mb-10 ${
                        indexSelected === 3 ? "price-selector-selected" : ""
                      }`}
                      onClick={() => handleAccountSelected(3)}
                    >
                      <div className="row justify-content-center align-items-center">
                        <div className="col-2 text-start">
                          <label className="custom-radio-btn">
                            <input
                              type="radio"
                              name="accountBalance"
                              checked={indexSelected === 3}
                            />
                            <span className="checkmark" />
                          </label>
                        </div>
                        <div className="col-6 text-center">
                          <h3 className="title-24 white mb-0">
                            <span className="text-regular">$</span>25 000
                          </h3>
                        </div>
                        <div className="col-4 text-end">
                          <span className="blue-pill title-16 white text-bold">
                            {pricingDATA["eur"][selectedPhase][3].base}$
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-10 ">
                    <div
                      className={`price-selector mb-10 ${
                        indexSelected === 4 ? "price-selector-selected" : ""
                      }`}
                      onClick={() => handleAccountSelected(4)}
                    >
                      <div className="row justify-content-center align-items-center">
                        <div className="col-2 text-start">
                          <label className="custom-radio-btn">
                            <input
                              type="radio"
                              name="accountBalance"
                              checked={indexSelected === 4}
                            />
                            <span className="checkmark" />
                          </label>
                        </div>
                        <div className="col-6 text-center">
                          <h3 className="title-24 white mb-0">
                            <span className="text-regular">$</span>50 000
                          </h3>
                        </div>
                        <div className="col-4 text-end">
                          <span className="blue-pill title-16 white text-bold">
                            {pricingDATA["eur"][selectedPhase][4].base}$
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`price-selector mb-10 ${
                        indexSelected === 5 ? "price-selector-selected" : ""
                      }`}
                      onClick={() => handleAccountSelected(5)}
                    >
                      <div className="row justify-content-center align-items-center">
                        <div className="col-2 text-start">
                          <label className="custom-radio-btn">
                            <input
                              type="radio"
                              name="accountBalance"
                              checked={indexSelected === 5}
                            />
                            <span className="checkmark" />
                          </label>
                        </div>
                        <div className="col-6 text-center">
                          <h3 className="title-24 white mb-0">
                            <span className="text-regular">$</span>100 000
                          </h3>
                        </div>
                        <div className="col-4 text-end">
                          <span className="blue-pill title-16 white text-bold">
                            {pricingDATA["eur"][selectedPhase][5].base}$
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`price-selector mb-10 ${
                        indexSelected === 6 ? "price-selector-selected" : ""
                      }`}
                      onClick={() => handleAccountSelected(6)}
                    >
                      <div className="row justify-content-center align-items-center">
                        <div className="col-2 text-start">
                          <label className="custom-radio-btn">
                            <input
                              type="radio"
                              name="accountBalance"
                              checked={indexSelected === 6}
                            />
                            <span className="checkmark" />
                          </label>
                        </div>
                        <div className="col-6 text-center">
                          <h3 className="title-24 white mb-0">
                            <span className="text-regular">$</span>200 000
                          </h3>
                        </div>
                        <div className="col-4 text-end">
                          <span className="blue-pill title-16 white text-bold">
                            {pricingDATA["eur"][selectedPhase][6].base}$
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`price-selector mb-10 ${
                        indexSelected === 7 ? "price-selector-selected" : ""
                      }`}
                      onClick={() => handleAccountSelected(7)}
                    >
                      <div className="row justify-content-center align-items-center">
                        <div className="col-2 text-start">
                          <label className="custom-radio-btn">
                            <input
                              type="radio"
                              name="accountBalance"
                              checked={indexSelected === 7}
                            />
                            <span className="checkmark" />
                          </label>
                        </div>
                        <div className="col-6 text-center">
                          <h3 className="title-24 white mb-0">
                            <span className="text-regular">$</span>400 000
                          </h3>
                        </div>
                        <div className="col-4 text-end">
                          <span className="blue-pill title-16 white text-bold">
                            {pricingDATA["eur"][selectedPhase][7].base}$
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h2 className="title-35 white mb-0">Platform</h2>
              <p className="text-regular white-70 mb-30">
                Please select your trading platform.
              </p>
              <div className="row gx-2">
                {/* MetaTrader 4 */}
                <div
                  className="col-md-6 mb-10"
                  onClick={() => setSelectMetaTrader(4)}
                >
                  <div
                    className={`phase-selector mb-10 ${
                      selectMetaTrader === 4 ? "price-selector-selected" : ""
                    }`}
                  >
                    <div className="row justify-content-center align-items-center">
                      <div className="col-12 text-center mb-10">
                        <img
                          src={MetaTraderLogo}
                          width="80px"
                          className="img-fluid "
                          alt=""
                        />
                        <h3 className="title-24 white mb-0">
                          MetaTrader{" "}
                          <span className="blue-gradient title-24">4</span>
                        </h3>
                        <span className="text-bold p-2 m-2 title-16 blue-pill d-inline-block ">
                          Best for EAs
                        </span>
                      </div>
                      <div className="col-12 text-center">
                        <input
                          type="radio"
                          name="phaseSelection"
                          checked={selectMetaTrader === 4}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* MetaTrader 5*/}
                <div
                  className="col-md-6 mb-10"
                  onClick={() => setSelectMetaTrader(5)}
                >
                  <div
                    className={`phase-selector mb-10 ${
                      selectMetaTrader === 5 ? "price-selector-selected" : ""
                    }`}
                  >
                    <div className="row justify-content-center align-items-center">
                      <div className="col-12 text-center mb-10">
                        <img
                          src={MetaTraderLogo}
                          width="80px"
                          className="img-fluid "
                          alt=""
                        />
                        <h3 className="title-24 white mb-0">
                          MetaTrader{" "}
                          <span className="blue-gradient title-24">5</span>
                        </h3>
                        <span className="text-bold p-2 m-2 title-16 blue-pill d-inline-block ">
                          Best for Web Trading
                        </span>
                      </div>
                      <div className="col-12 text-center">
                        <input
                          type="radio"
                          name="phaseSelection"
                          checked={selectMetaTrader === 5}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 mb-50" id="option-section">
                <span className="text-bold blue-pill d-inline-block mb-10">
                  optional
                </span>
                <h2 className="title-24 white">Addons</h2>
                <p className="text-regular white-70 mb-30 ">
                  Tailor your account to suit your trading style and preference.
                </p>
                <div className="row gx-2">
                  <div className="col-md-6 text-center mb-10">
                    {/* Première card */}
                    <div className="card-journey-white mb-10">
                      <div className="mb-30">
                        <div className="card-journey-row mb-20">
                          <h4 className="title-24 blue-gradient mb-10 d-block">
                            90/10 Profit Split
                          </h4>
                          <p
                            className="text-regular white"
                            style={{ minHeight: "50px" }}
                          >
                            Increase your share of the profits to 90%
                          </p>
                        </div>
                        <div className="card-journey-row">
                          <button
                            className={`outline-white-btn ${
                              profitSplit ? "outline-white-btn-clicked" : ""
                            }`}
                            onClick={handleProfitSplit}
                          >
                            {profitSplit ? "Option Added" : "+ Add this option"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 text-center">
                    {/* Seconde card */}
                    <div className="card-journey-white">
                      <div className="mb-30">
                        <div className="card-journey-row mb-20">
                          <h4 className="title-24 blue-gradient mb-10 d-block">
                            Insurance
                          </h4>
                          <p className="text-regular white">
                            Secure a second chance with our Insurance option.
                          </p>
                        </div>
                        <div className="card-journey-row">
                          <button
                            className={`outline-white-btn ${
                              insuranceAdded ? "outline-white-btn-clicked" : ""
                            }`}
                            onClick={handleAddInsurance}
                          >
                            {insuranceAdded
                              ? "Option Added"
                              : "+ Add this option"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row align-items-center">
                {currentUser ? (
                  <>
                    <div className="col-md-12">
                      <div className="row d-flex justify-content-center">
                        <div
                          className="col-md-10 account-connected-sign-up align-items-center mb-20"
                          style={{
                            backgroundColor: "rgba(255, 255, 255, 0.2)",
                            padding: "20px",
                            borderRadius: "10px",
                            textAlign: "center",
                          }}
                        >
                          <h3 className="title-16 white">
                            <svg
                              className="d-block mb-20"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width={24}
                              height={24}
                              fill="rgba(3,255,149,1)"
                            >
                              <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11.0026 16L18.0737 8.92893L16.6595 7.51472L11.0026 13.1716L8.17421 10.3431L6.75999 11.7574L11.0026 16Z" />
                            </svg>
                            Account connected
                          </h3>
                          <p className="text-regular white">
                            {currentUser.email}
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="col-md-12">
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-10 col-12" id="form-section">
                        <h2 className="title-24 white">Create your account</h2>
                        <p className="text-regular white-70 mb-30 ">
                          Tailor your account to suit your trading style and
                          preference.
                        </p>
                        <div className="row ">
                          <div className="col-md-6 mb-20">
                            <span className="input-label white mb-10 d-block text-start">
                              Firstname
                            </span>
                            <input
                              required
                              name="firstname"
                              className="input-blue d-block"
                              type="text"
                              placeholder="Enter your Firstname"
                              value={userForm.firstname}
                              onChange={(e) =>
                                setUserForm({
                                  ...userForm,
                                  firstname: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="col-md-6 mb-20">
                            <span className="input-label white mb-10 d-block text-start">
                              Lastname
                            </span>
                            <input
                              required
                              name="lastname"
                              className="input-blue d-block"
                              type="text"
                              placeholder="Enter your Lastname"
                              value={userForm.lastname}
                              onChange={(e) =>
                                setUserForm({
                                  ...userForm,
                                  lastname: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className=" mb-20">
                          <span className="input-label white mb-10 d-block text-start">
                            Adress Line 1
                          </span>
                          <input
                            required
                            name="adressline1"
                            className="input-blue d-block"
                            type="text"
                            placeholder="Enter your adress line 1"
                            value={userForm.addressLine1}
                            onChange={(e) =>
                              setUserForm({
                                ...userForm,
                                addressLine1: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className=" mb-20">
                          <span className="input-label white mb-10 d-block text-start">
                            Adress Line 2
                          </span>
                          <input
                            name="adressline2"
                            className="input-blue d-block"
                            type="text"
                            placeholder="Enter your adress line 2"
                            value={userForm.addressLine2}
                            onChange={(e) =>
                              setUserForm({
                                ...userForm,
                                addressLine2: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="mb-20">
                          <div className="row">
                            <div className="col-md-6 mb-20">
                              <span className="input-label white mb-10 d-block text-start">
                                Country
                              </span>
                              <select
                                required
                                name="country"
                                className="input-blue d-block"
                                value={userForm.country}
                                onChange={(e) =>
                                  setUserForm({
                                    ...userForm,
                                    country: e.target.value,
                                  })
                                }
                              >
                                <option value="">Select your country</option>
                                {countryJson.map((country) => (
                                  <option
                                    key={country.code}
                                    value={country.code}
                                  >
                                    {country.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="col-md-6">
                              <span className="input-label white mb-10 d-block text-start">
                                State
                              </span>
                              <input
                                name="state"
                                className="input-blue d-block "
                                type="text"
                                placeholder="Enter your state"
                                value={userForm.state}
                                onChange={(e) =>
                                  setUserForm({
                                    ...userForm,
                                    state: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row mb-30">
                          <div className="col-md-6 mb-20">
                            <span className="input-label white mb-10 d-block text-start">
                              City
                            </span>
                            <input
                              name="city"
                              className="input-blue d-block"
                              type="text"
                              placeholder="Enter your city"
                              value={userForm.city}
                              onChange={(e) =>
                                setUserForm({
                                  ...userForm,
                                  city: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="col-md-6">
                            <span className="input-label white mb-10 d-block text-start">
                              Postcode / ZIP
                            </span>
                            <input
                              name="zip"
                              className="input-blue d-block"
                              type="text"
                              placeholder="Postcode / ZIP"
                              value={userForm.zipCode}
                              onChange={(e) =>
                                setUserForm({
                                  ...userForm,
                                  zipCode: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <hr className="hr-white-50 mb-30" />
                        <div className="mb-20">
                          <span className="input-label white mb-10 d-block text-start">
                            E-mail
                          </span>
                          <input
                            required
                            name="email"
                            className="input-blue d-block"
                            type="email"
                            placeholder="Enter your e-mail"
                            value={userForm.email}
                            onChange={(e) =>
                              setUserForm({
                                ...userForm,
                                email: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="mb-20">
                          <span className="input-label white mb-10 d-block text-start">
                            Password
                          </span>
                          <input
                            required
                            name="password"
                            className="input-blue d-block"
                            type="password"
                            placeholder="Choose your password"
                            value={userForm.password}
                            onChange={(e) =>
                              setUserForm({
                                ...userForm,
                                password: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className=" mb-30">
                          <span className="input-label white mb-10 d-block text-start">
                            Confirm password
                          </span>
                          <input
                            required
                            name="confirmPassword"
                            className="input-blue d-block"
                            type="password"
                            placeholder="Confirm your password"
                            value={userForm.confirmPassword}
                            onChange={(e) =>
                              setUserForm({
                                ...userForm,
                                confirmPassword: e.target.value,
                              })
                            }
                          />
                        </div>

                        {authError && (
                          <p className="text-regular mb-10">{authError}</p>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-4 col-12 " style={{ top: "100px" }}>
              <div className="card-total-price text-center mt-5">
                <p className="text-mini white">Total</p>
                <h3 className="title-50 mb-40 gradient-text-animate">
                  {totalPrice}$
                </h3>
                <div className="input-group-custom mb-20">
                  <input
                    value={promo}
                    onChange={(e) => {
                      setPromo(e.target.value);
                    }}
                    className="input-custom d-block"
                    type="text"
                    placeholder="Discount code"
                    disabled={discountActive}
                  />
                  <button
                    onClick={handlePromoCode}
                    className="nav-button-custom"
                    disabled={discountActive}
                  >
                    {discountActive ? "Applied!" : "Apply"}
                  </button>
                </div>
                {promoError.length > 0 ? (
                  <p className="text-danger mt-2">
                    <strong>{promoError}</strong>
                  </p>
                ) : (
                  " "
                )}
                <div className="form-check d-flex align-items-center text-start mb-20">
                  <input
                    className="form-check-input p-2"
                    type="checkbox"
                    id="flexCheckDefault"
                    onChange={handleTermsChange}
                    checked={isTermsChecked}
                  />
                  <label
                    className="form-check-label ms-2 text-white"
                    htmlFor="flexCheckDefault"
                  >
                    <span className="text-regular">
                      I declare that I have read and agree with{" "}
                      <a href="/terms">Terms &amp; Conditions</a>
                    </span>
                  </label>
                </div>
                <button
                  className="blue-btn w-80"
                  disabled={loading}
                  onClick={handlePaymentClick}
                >
                  {loading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </>
                  ) : (
                    "Proceed to payment"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*start trading journey*/}
      <Foot />
    </>
  );
};
