import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import "./App.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { AuthProvider, useAuth } from "./hooks/Auth/AuthProvider";
import { Home } from "./pages/home";
import { Signin } from "./pages/SignIn/signin";
import { Resetpassword } from "./pages/SignIn/resetpassword";
import { Forgotpassword } from "./pages/SignIn/forgotpassword";
import { SuccessPage } from "./pages/Checkout/success";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { WebSocketProvider } from "./hooks/Websocket/WebSocket";
import { PaymentFailed } from "./pages/Checkout/paymentFailed";
import { Dashboard } from "./pages/Dashboard/dashboard";
import { SelectChallenge } from "./pages/Challenge/selectChallenge";
import ReactPixel from "react-facebook-pixel";
import { useEffect } from "react";
import { Terms } from "./pages/Legal/terms";
import { Privacy } from "./pages/Legal/privacy";
import { Withdraw } from "./pages/Withdraw/withdraw";
import { Analytics } from "@vercel/analytics/react";
import Hotjar from "@hotjar/browser";
import { AffiliateDashboard } from "./pages/Affiliate/AffiliateDashboard";
import { SignupForm } from "./pages/Signup/signupForm";
import { Faq } from "./pages/Faq";
import { CheckoutForm } from "./pages/Checkout/checkoutForm";

const options = {
  autoConfig: true,
  debug: false,
};

ReactPixel.init("356450557370096", null, options);

const ProtectedRoute = ({ children }) => {
  const { currentUser, authLoading } = useAuth();
  const location = useLocation();

  if (currentUser) {
    return children;
  } else {
    return <Navigate to="/signin" state={{ from: location.pathname }} />;
  }
};

const App = () => {
  useEffect(() => {
    Hotjar.init(3902157, 6);
    ReactPixel.pageView();
  }, []);

  return (
    <Router>
      <AuthProvider>
        <ToastContainer />
        <Analytics />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/signup" element={<SignupForm />} />
          <Route path="/resetpassword" element={<Resetpassword />} />
          <Route path="/forgotpassword" element={<Forgotpassword />} />
          <Route path="/success" element={
            <ProtectedRoute>
              <SuccessPage />
            </ProtectedRoute>
          } />
          <Route path="/failed" element={<PaymentFailed />} />
          <Route path="/challenge" element={<SelectChallenge />} />
          <Route path="/faq" element={<Faq />} />
          <Route
            path="/checkoutform"
            element={
              <ProtectedRoute>
                <CheckoutForm />
              </ProtectedRoute>
            }
          />
          <Route
            path="/app"
            element={
              <ProtectedRoute>
                <WebSocketProvider>
                  <Dashboard />
                </WebSocketProvider>
              </ProtectedRoute>
            }
          />

          <Route
            path="/affiliate"
            element={
              <ProtectedRoute>
                <AffiliateDashboard />
              </ProtectedRoute>
            }
          />
          <Route path="/paymentfailed" element={<PaymentFailed />} />
          <Route path="/withdraw" element={<Withdraw />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
};

export default App;
