//// Faudra faire un fichier order et subscription avec leur interface et les import ici
//// les interfaces côtés serveur sont là : https://github.com/NextFunddd/nextFunded-server/blob/main/prisma/schema.prisma
/// faudra les mettre en interface typescript, sur gpt tu lui demande de convertir en TS ça fait le taff
/// ex ici : https://chat.openai.com/share/afc12ef4-5a4d-4095-a03d-6cd568d8819f

import { Challenge } from "./challenge";
import { Checkout } from "./checkout";

/// Dans le Auth provider du coup t'auras : [user, setUser] = useState<User | null>(null);

export interface User {
  id: number;
  uid: string;
  username?: string;
  email: string;
  status: UserStatus;
  role: UserRole;
  availableFunds: number;
  challengeStep?: number;
  challengeId?: number;
  alpacaKeyId?: number;
  alpacaAccount ?: AlpacaAccount
  checkout: Checkout[];
  challenge: Challenge;
  totalTrades: number | null;
  tradingVolume: number | null;
  winningTrades: number | null;
  fundingRatio: number | null;
}



// Enum types also need to be defined in TypeScript
export enum UserStatus {
  ACTIVE = "ACTIVE",
  FUNDED = "FUNDED",
  PENDING = "PENDING",
  CLOSED = "CLOSED"
}

enum UserRole {
  "ADMIN",
  "USER",
}

interface AlpacaAccount {
  account_blocked: boolean;
  account_number: string;
  accrued_fees: string;
  admin_configurations: object;
  balance_asof: string;
  bod_dtbp: string;
  buying_power: string;
  cash: string;
  created_at: string;
  crypto_status: string;
  crypto_tier: number;
  currency: string;
  daytrade_count: number;
  daytrading_buying_power: string;
  effective_buying_power: string;
  equity: string;
  id: string;
  initial_margin: string;
  last_equity: string;
  last_maintenance_margin: string;
  long_market_value: string;
  maintenance_margin: string;
  multiplier: string;
  non_marginable_buying_power: string;
  pattern_day_trader: boolean;
  pending_transfer_in: string;
  portfolio_value: string;
  position_market_value: string;
  regt_buying_power: string;
  short_market_value: string;
  shorting_enabled: boolean;
  sma: string;
  status: string;
  trade_suspended_by_user: boolean;
  trading_blocked: boolean;
  transfers_blocked: boolean;
  user_configurations: null | object;
}

