import { toast } from "react-toastify";
import { API_URL } from "../../config/api";
import axios from "axios";
import { useState } from "react";
import { Email } from "../../interfaces/email";

export const useNewsletter = () => {

    const [newsletterLoading, setNewsletterLoading] = useState(false);

    const subscribeNewsletter = async (email: Email) => {  
        setNewsletterLoading(true);
        try {
            const response = await axios.post(`${API_URL}/newsletters/subscribe`, 
                email
            , {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            return response.data; 
        } catch (error) {
            console.error("NEWSLETTER" + error);
        }
        finally {
            setNewsletterLoading(false);
        }
    }
    
    return { subscribeNewsletter, newsletterLoading };

}