import { Foot } from "../../components/foot"
import { Footer } from "../../components/footer"
import { Nav } from "../../components/nav"


export const Privacy = () => {
    return (
        <>
            <Nav />

            <div className="container d-flex justify-content-center   mt-5 py-5 px-5 mb-5">
                <div className="col-md-8 col-12 text-md-start text-center">
                    <h1 className="title-50 white">Privacy Policies of <span className="color-blue">Next Funded</span></h1>
                    <p className="white">
                        When you use our services, we collect and store personal data about you. This privacy statement (“Policy”) informs you of the methods used to manage your personal data.

                        This privacy policy includes information on the following points:
                        <br></br>
                        <br/>• How to contact the person in charge of your personal data?
                        <br/>• In what ways do we obtain and what information do we collect about you?
                        <br/>• How do we use the information you provide about yourself?
                        <br/>• How do we ensure the security and protection of our customers’ personal data?
                        <br/>• Who do we share your personal information with?
                        <br/>• What about international organizations and third countries?
                        <br/>• How to assert your rights regarding the protection of your personal data?
                        <br/>
                        <br></br>
                        <h2 className="title-24 mt-3">WHO IS RESPONSIBLE FOR YOUR PERSONAL DATA AND WHO TO CONTACT?</h2>
                        The controller of personal data is Next Funded, whose registered office is located at 254 CHAPMAN RD, STE 208 #15841, NEWARK, DE 19702, USA. You can contact Next Funded at support@nextfunded.io for any questions and to exercise your rights related to the processing of your personal data.

                        <br/><br/><h2 className="title-24 mt-3">WHAT PERSONAL INFORMATION DO WE COLLECT ABOUT YOU AND HOW DO WE COLLECT IT?</h2>
                        <br/>This section aims to give you a general overview of how we collect personal data about you and the types of data we collect. Personal data includes all information that can be used to identify or link to a specific person.

                        <br/><br/>When you use our services, you provide us with many details about yourself, especially if you create an account, provide personal information, place an order, participate in an event or project, or contact us through customer service or social media. Identification and contact data include your name, surname, phone number, email address, postal address, date of birth, username and password, as well as any warranty claim you may have made. Payment data includes your bank account information and other data, such as records of communication between you and our company or information about any warranty claim.

                        <br/><br/>Your personal information that we automatically collect when you use our products or services includes information about your device (such as your IP address), information on how you use a website (such as the time and date of your visit and the country from which you accessed the website), and information on how you use a service (such as login and logout information, your account settings, the value of your fictitious capital, your account currency, and your trading strategy).

                        <br/><br/>If you choose to link your social media accounts to your account on the website, third parties may provide us with information about you. Examples of these third parties include the trading platform providers you choose to trade on using our services. Financial data (such as the type of instrument, the start and end time of a trade), as well as data from social networks (such as profit or loss), are included (username, profile picture, email address associated with the social network account). For details on how trading platform providers and social network operators use your personal information for their own purposes, please refer to their privacy policies.

                        <br/><br/><h2 className="title-24 mt-3">HOW DO WE PROTECT YOUR PERSONAL INFORMATION?</h2>
                       <br/>Personal data is stored on computers and other devices in an encrypted database. Organizational and technical security measures have been put in place to protect the data we collect and handle from unauthorized access, alteration, disclosure, or destruction.

                        <br/><br/><h2 className="title-24 mt-3">HOW LONG DO WE PROCESS YOUR INFORMATION?</h2>
                        <br/>As mentioned above, personal data is only processed to the extent necessary to achieve the stated purposes and as long as required by law. They are then destroyed or anonymized. We collect and use personal data for the following periods:

                        <br/>• The processing of personal data is limited to the duration necessary to execute the contract and to exercise the rights and obligations of the parties under it, such as managing claims, resolving compensation requests, or initiating legal action.
                    </p>
                </div>
            </div>
        <Foot/>
        </>
    )
}