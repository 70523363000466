export const formatExpirationDate = (value) => {
  const cleanedValue = value.replace(/\D/g, '');
  if (cleanedValue.length >= 3) {
    return `${cleanedValue.substring(0, 2)}/${cleanedValue.substring(2, 4)}`;
  } else {
    return cleanedValue;
  }
};


  export const formatCVV = (value) => {
    const cleanedValue = value.replace(/\D/g, '');
    return cleanedValue.substring(0, 3);
  };

export  const formatCardNumber = (value) => {
  const cleanedValue = value.replace(/\D/g, '').substring(0, 16);
  const groups = cleanedValue.match(/.{1,4}/g);
  return groups ? groups.join(' ') : cleanedValue;
};

export const checkFormatDate = (value) => {
  const cleanedValue = value.replace(/[^0-9/]/g, '');

  const isValidFormat = /^(\d{2})\/(\d{4})$/.test(cleanedValue);
  const isShortYearFormat = /^(\d{2})\/(\d{2})$/.test(cleanedValue);

  if (isValidFormat) {
    return cleanedValue;
  } else if (isShortYearFormat) {
    const parts = cleanedValue.split('/');
    const month = parts[0];
    const year = '20' + parts[1];
    return `${month}/${year}`;
  } else {
    return ''; 
  }
};


export const detectCardType = (number) => {
  const cleanedNumber = number.replace(/\s+/g, '');
  const visaRegex = /^4[0-9]{0,15}$/;
  const mastercardRegex = /^5[1-5][0-9]{0,14}$/;
  const amexRegex = /^3[47][0-9]{0,13}$/;

  if (visaRegex.test(cleanedNumber)) {
    return 'visa';
  } else if (mastercardRegex.test(cleanedNumber)) {
    return 'mastercard';
  } else if (amexRegex.test(cleanedNumber)) {
    return 'amex';
  } else {
    return null;
  }
};