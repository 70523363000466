import React, { useEffect, useState } from "react";
import pricingDATA from "../config/pricing.json";
import TooltipBtn from "./tooltip";
import { useNavigate } from "react-router-dom";
import ReactPixel from 'react-facebook-pixel';
import { useCheckout } from "../hooks/Checkout/useCheckout";
import Hotjar from '@hotjar/browser';

interface ChallengeCard {
    nextRoute: string;
    index: number;
    currency: string;
    phaseSelected: number;
    promoURL: string;
}

export const ChallengeCard: React.FC<ChallengeCard> = ({ nextRoute, index, currency, phaseSelected, promoURL }) => {

    const navigate = useNavigate()
    const [challengeIndex, setChallengeIndex] = useState(index);
    const [twoPhaseChallengeIndex, setTwoPhaseChallengeIndex] = useState(index);
    const [oneStepChallengePrice, setOneStepChallengePrice] = useState(pricingDATA["eur"]["1"][challengeIndex].base);
    const [twoStepChallengePrice, setTwoStepChallengePrice] = useState(pricingDATA["eur"]["2"][twoPhaseChallengeIndex].base);

    const { checkDiscountCode } = useCheckout()

    const [profitSplit, setProfitSplit] = useState(false);
    const [insurance, setInsurance] = useState(false);

    const [profitSplitTwoPhase, setProfitSplitTwoPhase] = useState(false);
    const [insuranceTwoPhase, setInsuranceTwoPhase] = useState(false);
    const [discountActive, setDiscountActive] = useState(false);
    const [discount, setDiscount] = useState<any>(null);
    const [promo, setPromo] = useState('');
    const [promoError, setPromoError] = useState('');

    const [phase, setPhase] = useState(phaseSelected);


    const handleCheckboxChangeProfit = () => {
        const newProfitSplitState = !profitSplit;
        setProfitSplit(newProfitSplitState);

        if (newProfitSplitState) {
            setOneStepChallengePrice(prevPrice => parseFloat((prevPrice + pricingDATA["eur"]["1"][challengeIndex].pf).toFixed(2)));
        } else {
            setOneStepChallengePrice(prevPrice => parseFloat((prevPrice - pricingDATA["eur"]["1"][challengeIndex].pf).toFixed(2)));
        }
        Hotjar.event('profit-click');
    };

    const handleCheckboxChangeInsurance = () => {
        const newInsuranceState = !insurance;
        setInsurance(newInsuranceState);

        if (newInsuranceState) {
            setOneStepChallengePrice(prevPrice => parseFloat((prevPrice + pricingDATA["eur"]["1"][challengeIndex].insurance).toFixed(2)));
        } else {
            setOneStepChallengePrice(prevPrice => parseFloat((prevPrice - pricingDATA["eur"]["1"][challengeIndex].insurance).toFixed(2)));
        }
        Hotjar.event('insurance-click');
    }

    const handleCheckboxChangeProfitTwoPhase = () => {
        const newProfitSplitState = !profitSplitTwoPhase;
        setProfitSplitTwoPhase(newProfitSplitState);

        if (newProfitSplitState) {
            setTwoStepChallengePrice(prevPrice => parseFloat((prevPrice + pricingDATA["eur"]["2"][twoPhaseChallengeIndex].pf).toFixed(2)));
        } else {
            setTwoStepChallengePrice(prevPrice => parseFloat((prevPrice - pricingDATA["eur"]["2"][twoPhaseChallengeIndex].pf).toFixed(2)));
        }
    };

    const handleCheckboxChangeInsuranceTwoPhase = () => {
        const newInsuranceState = !insuranceTwoPhase;
        setInsuranceTwoPhase(newInsuranceState);

        if (newInsuranceState) {
            setTwoStepChallengePrice(prevPrice => parseFloat((prevPrice + pricingDATA["eur"]["2"][twoPhaseChallengeIndex].insurance).toFixed(2)));
        } else {
            setTwoStepChallengePrice(prevPrice => parseFloat((prevPrice - pricingDATA["eur"]["2"][twoPhaseChallengeIndex].insurance).toFixed(2)));
        }
    }

    const handlePromoCode = async () => {
        if (promo.length < 1) return

        const discount = await checkDiscountCode(promo.toUpperCase());

        if (!discount) {
            setPromoError("Invalid promo code");
            return
        }

        if (phase == 1) {
            if (discount.challenge[0].id != challengeIndex + 1) {
                setDiscountActive(false);
                setPromoError("Invalid challenge for this code");
                return
            }
            setPromoError("")
            setDiscount(discount);
            setDiscountActive(true);
        }
        else {
            if (discount.challenge[1].id != twoPhaseChallengeIndex + 1 + pricingDATA[currency]["1"].length) {
                setDiscountActive(false);
                setPromoError("Invalid challenge for this code");
                return
            }
            setPromoError("")
            setDiscount(discount);
            setDiscountActive(true);
        }

    }


    const nextPrice = () => {
        const index = challengeIndex + 1;
        if (index === pricingDATA[currency]["1"].length) return
        setChallengeIndex((prevIndex) => (prevIndex + 1) % pricingDATA[currency]["1"].length);
        setOneStepChallengePrice(pricingDATA[currency]["1"][index].base);
        setInsurance(false);
        setProfitSplit(false);
    };

    const prevPrice = () => {
        const index = challengeIndex - 1;
        if (index < 0) return
        setChallengeIndex((prevIndex) => (prevIndex - 1 + pricingDATA[currency]["1"].length) % pricingDATA[currency]["1"].length);
        setOneStepChallengePrice(pricingDATA[currency]["1"][index].base);
        setInsurance(false);
        setProfitSplit(false);
    }

    const nextPriceTwoPhase = () => {
        const index = twoPhaseChallengeIndex + 1;
        if (index === pricingDATA[currency]["2"].length) return
        setTwoPhaseChallengeIndex((prevIndex) => (prevIndex + 1) % pricingDATA[currency]["2"].length);
        setTwoStepChallengePrice(pricingDATA[currency]["2"][index].base);
        setInsuranceTwoPhase(false);
        setProfitSplitTwoPhase(false);
    }

    const prevPriceTwoPhase = () => {
        const index = twoPhaseChallengeIndex - 1;
        if (index < 0) return
        setTwoPhaseChallengeIndex((prevIndex) => (prevIndex - 1 + pricingDATA[currency]["2"].length) % pricingDATA[currency]["2"].length);
        setTwoStepChallengePrice(pricingDATA[currency]["2"][index].base);
        setInsuranceTwoPhase(false);
        setProfitSplitTwoPhase(false);
    }

    const handleSelectChallenge = () => {

        let challengeId;
        let upsellCode;
        if (phase == 1) {
            challengeId = challengeIndex + 1;
            if (profitSplit && insurance) upsellCode = 3;
            else if (profitSplit) upsellCode = 2;
            else if (insurance) upsellCode = 1;
            else upsellCode = 0;
        }
        else {
            challengeId = twoPhaseChallengeIndex + 1 + pricingDATA[currency]["1"].length;
            if (profitSplitTwoPhase && insuranceTwoPhase) upsellCode = 3;
            else if (profitSplitTwoPhase) upsellCode = 2;
            else if (insuranceTwoPhase) upsellCode = 1;
            else upsellCode = 0;
        }

        ReactPixel.track('AddToCart', { challengeId: challengeId, price: phase == 1 ? oneStepChallengePrice : twoStepChallengePrice, currency: currency, content_type: 'product', options: upsellCode });

        if (discountActive) {
            navigate(`${nextRoute}?challenge-id=${challengeId}&options=${upsellCode}&currency=${currency}&promo=${promo.toUpperCase()}`);
        }
        else {
            navigate(`${nextRoute}?challenge-id=${challengeId}&options=${upsellCode}&currency=${currency}`);
        }
      
        Hotjar.event(`select-challenge-click ${challengeId}`);
    }


    return (
        <div className="col-md-7">
            <div className="card-journey-blue">
                <ul
                    className="nav nav-tabs d-flex mb-20"
                    id="phaseTab"
                    role="tablist"
                >
                    <li
                        className="nav-item flex-fill"
                        role="presentation"
                        style={{ width: "50%" }}
                    >
                        <a
                            className={"nav-link phase-btn" + (phaseSelected === 1 ? " active" : "")}
                            id="one-phase-tab"
                            data-bs-toggle="tab"
                            href="#one-phase"
                            role="tab"
                            aria-controls="one-phase"
                            aria-selected={phaseSelected === 1 ? "true" : "false"}
                            style={{ width: "50%" }}
                            onClick={() => setPhase(1)}
                        >
                            <h5 className="title-24 my-4">1 phase</h5>
                      
                        </a>
                    </li>
                    <li
                        className="nav-item flex-fill"
                        role="presentation"
                        style={{ width: "50%" }}
                    >
                        <a
                            className={"nav-link phase-btn" + (phaseSelected === 2 ? " active" : "")}
                            id="two-phase-tab"
                            data-bs-toggle="tab"
                            href="#two-phase"
                            role="tab"
                            aria-controls="two-phase"
                            aria-selected={phaseSelected === 2 ? "true" : "false"}
                            onClick={() => setPhase(2)}
                        >
                            <h5 className="title-24 my-4">2 phases</h5>
                        </a>
                    </li>
                </ul>
                {/* Tab content */}
                <div className="tab-content" id="phaseTabContent">
                    {/* 1 PHASE */}
                    <div
                        className="tab-pane fade show active"
                        id="one-phase"
                        role="tabpanel"
                        aria-labelledby="one-phase-tab"
                    >
                        <div className="card-journey-row">
                            <div
                                className="row align-items-center"
                                style={{
                                    padding: "10px 20px",
                                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                                    borderRadius: "100px",
                                }}
                            >
                                <div className="col-2 d-flex justify-content-start">
                                    <button
                                        className="btn rounded-circle p-2 btn-custom-arrow"
                                        onClick={prevPrice}
                                    >
                                        {/* SVG pour bouton de diminution */}
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            width="24"
                                            height="24"
                                            fill="rgba(255,255,255,1)"
                                        >
                                            <path d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z"></path>
                                        </svg>
                                    </button>
                                </div>
                                <div className="col-8 d-flex justify-content-center">
                                    <div className="text-center">
                                        <h5 className="title-24 white">
                                            <span>${pricingDATA[currency]["1"][challengeIndex].size}</span>
                                        </h5>
                                        <h5 className="text-mini white">
                                            Trading size account
                                        </h5>
                                    </div>
                                </div>
                                <div className="col-2 d-flex justify-content-end">
                                    <button
                                        className="btn rounded-circle p-2 btn-custom-arrow"
                                        onClick={nextPrice}
                                    >
                                        {/* SVG pour bouton d'augmentation */}
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            width="24"
                                            height="24"
                                            fill="rgba(255,255,255,1)"
                                        >
                                            <path d="M13.1717 12.0007L8.22192 7.05093L9.63614 5.63672L16.0001 12.0007L9.63614 18.3646L8.22192 16.9504L13.1717 12.0007Z"></path>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/*1 row*/}

                  


                        <hr className="hr-white" />
                        {/*1 row*/}
                        <div className="card-journey-row">
                            <div className="row align-items-center">
                                <div className="col-12 text-start">
                                    <span className="top-title-mini blue-gradient">
                                        Unique phase
                                    </span>
                                    <h4 className="title-16 white-50">
                                        Verification Phase
                                    </h4>
                                    <p className="text-mini white">
                                        One phase to pass to access real funding and
                                        weekly payouts (challenge refunded once passed).
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/*1 row*/}
                        <hr className="hr-white" />
                        {/*1 row*/}
                        <div className="card-journey-row">
                            <div className="row align-items-center">
                                <div className="col-9 col-md-9 text-start">
                                    <h4 className="title-16 white-50">Profit Target</h4>
                                    <p className="text-mini white">
                                        
                                        10% of the initial balance.
                                    </p>
                                </div>
                                <div className="col-3 col-md-3 text-end">
                                    <h4 className="title-16 white-50">${parseInt(pricingDATA[currency]["1"][challengeIndex].size + (pricingDATA[currency]["1"][challengeIndex].size * 0.1))}</h4>
                                    <p className="text-mini white">10%</p>
                                </div>
                            </div>
                        </div>
                        {/*1 row*/}
                        <hr className="hr-white" />
                        {/*1 row*/}
                        <div className="card-journey-row">
                            <div className="row align-items-center">
                                <div className="col-9 text-start">
                                    <h4 className="title-16 white-50">Trading period</h4>
                                    <p className="text-mini white">
                                        Trade at your own
                                        pace without a time limit.
                                    </p>
                                </div>
                                <div className="col-3 text-end">
                                    <h4 className="title-16 white-50">Unlimited</h4>
                                </div>
                            </div>
                        </div>
                        {/*1 row*/}
                        <hr className="hr-white" />
                        {/*1 row*/}
                        <div className="card-journey-row">
                            <div className="row align-items-center">
                                <div className="col-9 text-start">
                                    <h4 className="title-16 white-50">
                                        Maxium Daily Loss
                                    </h4>
                                    <p className="text-mini white">
                                        The daily loss limit is set at 4% of the initial
                                        balance.
                                    </p>
                                </div>
                                <div className="col-3 text-end">
                                    <h4 className="title-16 white-50">4%</h4>
                                </div>
                            </div>
                        </div>
                        {/*1 row*/}
                        <hr className="hr-white" />
                        {/*1 row*/}
                        <div className="card-journey-row mb-20">
                            <div className="row align-items-center">
                                <div className="col-9 text-start">
                                    <h4 className="title-16 white-50">Max Total Loss</h4>
                                    <p className="text-mini white">
                                        Maximum drawdown limit is set at 6% of the initial
                                        balance.
                                    </p>
                                </div>
                                <div className="col-3 text-end">
                                    <h4 className="title-16 white-50">6%</h4>
                                </div>
                            </div>
                        </div>
                        <div className="text-center">

                            <h4 className="title-24 white mb-0">{discountActive ?
                                <>
                                    <span className="">
                                        <span className="crossed-out">{parseInt(oneStepChallengePrice.toString())}</span><span className="text-bold white ms-n3">$ <span className="text-bold">
                                            <span className="green">-{discount.discount}%</span>
                                        </span></span>

                                    </span>
                                </>
                                : ''
                            }
                            </h4>

                            <h4 className="title-50 white mb-0">
                                {discountActive ? Math.round(oneStepChallengePrice - (oneStepChallengePrice * discount.discount / 100)) : oneStepChallengePrice}<span className="text-bold  white">$</span>
                            </h4>
                            <span className="text-mini white d-block mb-30">
                                One time
                            </span>
                            <div className="card-journey-row">
                                <div className="card-journey-row  mb-20">
                                    <a className="blue-btn" href="/challenge">Select this challenge</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* 2 PHASE */}
                    <div
                        className="tab-pane fade"
                        id="two-phase"
                        role="tabpanel"
                        aria-labelledby="two-phase-tab"
                    >
                        {/*Choose your account size 2 PHASE*/}
                        <div className="card-journey-row">
                            <div
                                className="row align-items-center"
                                style={{
                                    padding: "10px 20px",
                                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                                    borderRadius: "100px",
                                }}
                            >
                                <div className="col-2 d-flex justify-content-start">
                                    <button className="btn rounded-circle p-2 btn-custom-arrow" onClick={prevPriceTwoPhase}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            width={24}
                                            height={24}
                                            fill="rgba(255,255,255,1)"
                                        >
                                            <path d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z" />
                                        </svg>
                                    </button>
                                </div>
                                <div className="col-8 d-flex justify-content-center">
                                    <div className="text-center">
                                        <h5 className="title-24 white"><span>${pricingDATA[currency]["2"][twoPhaseChallengeIndex].size}</span></h5>
                                        <h5 className="text-mini white">Trading size account</h5>

                                    </div>
                                </div>
                                <div className="col-2 d-flex justify-content-end">
                                    <button className="btn rounded-circle p-2 btn-custom-arrow" onClick={nextPriceTwoPhase}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            width={24}
                                            height={24}
                                            fill="rgba(255,255,255,1)"
                                        >
                                            <path d="M13.1717 12.0007L8.22192 7.05093L9.63614 5.63672L16.0001 12.0007L9.63614 18.3646L8.22192 16.9504L13.1717 12.0007Z" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                
                        {/*1 row*/}
                        <hr className="hr-white" />
                        {/*1 row*/}
                        <div className="card-journey-row">
                            <div className="row align-items-center">
                                <div className="col-12 text-start">
                                    <span className="top-title-mini blue-gradient">
                                        Phase 1
                                    </span>
                                    <h4 className="title-16 white-50">Challenge phase</h4>
                                    <p className="text-mini white">
                                        
                                        Hit the first profit 8% profit target and respect maximum drawdown

                                    </p>
                                </div>
                            </div>
                        </div>
                        {/*1 row*/}
                        <hr className="hr-white" />
                        {/*1 row*/}
                        <div className="card-journey-row">
                            <div className="row align-items-center">
                                <div className="col-12 text-start">
                                    <span className="top-title-mini">Phase 2</span>
                                    <h4 className="title-16 white-50">
                                        Verification Phase
                                    </h4>
                                    <p className="text-mini white">
                                    Hit the second profit target of 5% on your account while respecting maximum daily drawdown.
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/*1 row*/}
                        <hr className="hr-white" />
                        {/*1 row*/}
                        <div className="card-journey-row">
                            <div className="row align-items-center">
                                <div className="col-12 col-md-6 text-start">
                                    <h4 className="title-16 white-50">Profit Target</h4>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="row">
                                        <div className="col-6 col-md-6 text-start">
                                            <span className="top-title-mini">Phase 1</span>
                                            <h4 className="title-16 white-50">${parseInt(pricingDATA[currency]["2"][twoPhaseChallengeIndex].size + (pricingDATA[currency]["2"][twoPhaseChallengeIndex].size * 0.08))}</h4>
                                            <p className="text-mini white">8%</p>
                                        </div>
                                        <div className="col-6 col-md-6 text-start">
                                            <span className="top-title-mini">Phase 2</span>
                                            <h4 className="title-16 white-50">${parseInt(pricingDATA[currency]["2"][twoPhaseChallengeIndex].size + (pricingDATA[currency]["2"][twoPhaseChallengeIndex].size * 0.13))}</h4>
                                            <p className="text-mini white">5%</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*1 row*/}
                        <hr className="hr-white" />
                        {/*1 row*/}
                        <div className="card-journey-row">
                            <div className="row align-items-center">
                                <div className="col-9 text-start">
                                    <h4 className="title-16 white-50">Trading period</h4>
                                    <p className="text-mini white">
                                        Of the initial account balance for all phases.
                                    </p>
                                </div>
                                <div className="col-3 text-end">
                                    <h4 className="title-16 white-50">Unlimited</h4>
                                </div>
                            </div>
                        </div>
                        {/*1 row*/}
                        <hr className="hr-white" />
                        {/*1 row*/}
                        <div className="card-journey-row">
                            <div className="row align-items-center">
                                <div className="col-9 text-start">
                                    <h4 className="title-16 white-50">
                                        Maxium Daily Loss
                                    </h4>
                                    <p className="text-mini white">
                                        Of the initial account balance for all phases.
                                    </p>
                                </div>
                                <div className="col-3 text-end">
                                    <h4 className="title-16 white-50">5%</h4>
                                </div>
                            </div>
                        </div>
                        {/*1 row*/}
                        <hr className="hr-white" />
                        {/*1 row*/}
                        <div className="card-journey-row">
                            <div className="row align-items-center">
                                <div className="col-9 text-start">
                                    <h4 className="title-16 white-50">Max Total Loss</h4>
                                    <p className="text-mini white">
                                        Maximum drawdown from the initial balance.
                                    </p>
                                </div>
                                <div className="col-3 text-end">
                                    <h4 className="title-16 white-50">10%</h4>
                                </div>
                            </div>
                        </div>
                        <div className="text-center mb-20">

                            <h4 className="title-24 white mb-0">{discountActive ?
                                <>
                                    <span className="">
                                        <span className="crossed-out">{parseInt(twoStepChallengePrice.toString())}</span><span className="text-bold white ms-n3">$ <span className="text-bold">
                                            <span className="green">-{discount.discount}%</span>
                                        </span></span>

                                    </span>
                                </>
                                : ''
                            }



                            </h4>

                            <h4 className="title-50 white mb-0">
                                {discountActive ? Math.round(twoStepChallengePrice - (twoStepChallengePrice * discount.discount / 100)) : twoStepChallengePrice}<span className="text-bold  white">€</span>
                            </h4>
                            <span className="text-mini white d-block mb-30">
                                One time
                            </span>
                            <div className="card-journey-row">
                                <a className="blue-btn  mb-20-block" href="/challenge">Select this challenge</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}