import { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../../config/api";
import { useAuth } from "../Auth/AuthProvider";
import { Position } from "../../interfaces/position";
import { toast } from "react-toastify";

export const usePositions = () => {

    const [positions, setPositions] = useState<Position[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [loadingPositions, setLoadingPositions] = useState<boolean>(false);
    const { jswToken } = useAuth();


    const closePosition = async (symbol: string) => {
        try {
          const response = await axios.post(`${API_URL}/positions/close-position/${symbol}`, {}, {
            headers: {
              Authorization: `Bearer ${jswToken}`,
            },
          });
          setPositions(positions.filter(order => order.symbol !== symbol));
          response.data as Position; 
          toast.success("Position closed successfully");
          return 
        } catch (error) {
          console.error("POSITIONS" + error);
          toast.error('Error closing order')
        }
      }
      

    const getPositionById = async (id: number) => {
        try {
            const response = await axios.get(`${API_URL}/orders/${id}`, {
                headers: {
                    Authorization: `Bearer ${jswToken}`,
                },
            });
            setPositions([...positions, response.data as Position])
            return response.data as Position;
        } catch (error) {
            console.error("POSITIONS", error);
            setError(error)
        }
    }

    const getAllPositions = async () => {
        setLoadingPositions(true);
        try {
            const response = await axios.get(`${API_URL}/positions/all`, {
                headers: {
                    Authorization: `Bearer ${jswToken}`,
                },
            });
            setPositions(response.data as Position[])
        } catch (error) {
            console.error("POSITIONS", error);
            setError(error)
        }
        finally {
            setLoadingPositions(false);
        }

    }

    useEffect(() => {
        getAllPositions();
    }, [])



    return { positions, getAllPositions, getPositionById, closePosition }
}