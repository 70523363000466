import axios from "axios";
import { useAuth } from "../Auth/AuthProvider"
import { API_URL } from "../../config/api";

export const useCheckout = () => {

    const { jswToken, currentUser } = useAuth();

    const getCheckout = async (session_id : string) => {

        if (currentUser) {
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${jswToken}`,
                    },
                };
                const response = await axios.get(`${API_URL}/checkout/check-checkout/${session_id}`, config);
                return response.data;
            } catch (error) {
                console.error(error);
            }
        }

    }

    const checkDiscountCode = async (code: string) => {

        try {
            const response = await axios.get(`${API_URL}/check-discount/${code}`);
            return response.data;
        } catch (error) {
            
            console.error(error);
        }


    }

    return { getCheckout, checkDiscountCode }
}