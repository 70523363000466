import { useState } from "react";
import axios from "axios";
import { API_URL } from "../../config/api";
import { useAuth } from "../Auth/AuthProvider";
import { toast } from "react-toastify";
import { Affiliate } from "../../interfaces/affiliate";

export const useAffiliate = () => {

    const [affiliate, setAffiliate] = useState<Affiliate | null>();
    const [error, setError] = useState<string | null>(null);
    const [loadingAffiliate, setLoadingAffiliate] = useState<boolean>(false);
    const [loadingAffiliateCreate, setLoadingAffiliateCreate] = useState<boolean>(false);
    const { jswToken } = useAuth();

    
    const createAffiliate = async () => {
        setLoadingAffiliateCreate(true);
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${jswToken}`,
                },
            };
            const response = await axios.post(`${API_URL}/referrals/new`, {}, config);
            
            setAffiliate(response.data as Affiliate)
            toast.success("Affiliate code successfully created");
            return response.data as Affiliate;
    
        } catch (error) {
            console.error(error);
            setError(error)
        } finally {
            setLoadingAffiliateCreate(false);
        }
    };
    
      
    const getAffiliateStats = async () => {
        setLoadingAffiliate(true);
        try {
            const response = await axios.get(`${API_URL}/referrals/stats `, {
                headers: {
                    Authorization: `Bearer ${jswToken}`,
                },
            });
            setAffiliate(response.data as Affiliate)
            return response.data as Affiliate;
        } catch (error) {
            console.error("AFFILIATE" + error);
        }finally {
            setLoadingAffiliate(false);
        }
    }

    return { affiliate, getAffiliateStats, createAffiliate, loadingAffiliateCreate, loadingAffiliate }
}