import { Footer } from "../components/footer";
import { Navbar } from "../components/navbar";

import firstSectionImg from "../media/firstsection.png";
import newsletterImg from "../media/newsletter.png";
import firstSectionImgMobile from "../media/firstsection-mobile.png";
import trustpilotImg from "../media/trustpilot.png";
import discordLogo from "../media/discordLogo.png";
import trustPilotCard from "../media/logo/trustpilot.webp";
import nneonImg from "../media/nneon.png";
import withdrawImg from "../media/withdraw.png";
import dashboardImg from "../media/dashboard.png";
import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar } from "swiper/modules";
import { Modal } from "react-bootstrap";
import { ChallengeCard } from "../components/challengeCard";
import ReactPixel from "react-facebook-pixel";
import { useNewsletter } from "../hooks/Newsletter/useNewsletter";
import Hotjar from "@hotjar/browser";
import faqData from "../config/faq-data.json";

export const Home = () => {
  const [email, setEmail] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const { subscribeNewsletter, newsletterLoading } = useNewsletter();
  useEffect(() => {
    ReactPixel.track("ViewContent", { page: "home" });
    Hotjar.stateChange("/home");
  }, []);

  const [openId, setOpenId] = useState(null);

  const toggleOpen = (id) => {
    if (openId === id) {
      setOpenId(null);
    } else {
      setOpenId(id);
    }
  };
  /*
    useEffect(() => {
      setTimeout(() => {
        setShow(true);
      }, 25000);
    }, []);
  */
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      email: email,
    };
    await subscribeNewsletter(data);
    handleClose();
  };

  return (
    <>
      <Modal
        style={{ borderRadius: "30px" }}
        show={show}
        onHide={handleClose}
        centered
        aria-labelledby="newsletter-modal-title"
        className="modal fade"
      >
        <Modal.Header closeButton />
        <Modal.Body className="text-center modal-body">
          <img
            src={newsletterImg}
            alt="Newsletter"
            className="img-fluid mb-20"
            style={{ width: "50%" }}
          />
          <h2 className="title-24 white mb-30">
            Receive a free trading course &amp; 10% off on your subscription
          </h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-30 d-block text-start">
              <input
                required
                className="input-blue"
                type="email"
                placeholder="Enter your e-mail"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <button
              type="submit"
              className="blue-btn w-100 d-block mb-10"
              disabled={newsletterLoading}
            >
              {newsletterLoading ? (
                <div className="spinner-border text-light" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                "Subscribe"
              )}
            </button>
          </form>
        </Modal.Body>
      </Modal>

      {/*hero*/}
      <div className="bg-full">
        <Navbar />
        <section style={{ minHeight: "100vh", paddingTop : "180px" }}>
          <div className="custom-container">
            <div className="row align-items-center">
              <div className="col-12 col-md-4 col-lg-4 order-2 order-md-1">
                <h1 className="title-50 white mb-30 text-center text-md-start">
                  The Next profitable trader is you
                </h1>
                <p className="text-regular mb-50 text-center text-md-start">
                  Discover a new way to trade and be profitable with no capital
                  limits
                </p>
                <a
                  href="/challenge"
                  className="blue-btn mr-10 text-center d-inline-block"
                >
                  Start now
                </a>
              </div>
              <div className="col-12 col-md-7 offset-md-1 text-center order-1 order-md-2">
                <img
                  src={firstSectionImg}
                  alt=""
                  className="d-none d-md-block img-fluid"
                />
                <img
                  src={firstSectionImgMobile}
                  alt=""
                  className=" d-block d-md-none img-fluid"
                />
              </div>
            </div>
          </div>
        </section>
        {/*hero*/}
        {/*advantages*/}
        <section className="section-80" id="advantage-section">
          <div className="custom-container">
            <div className="row">
              <div className="col-md-8 ">
                <span
                  className="text-bold blue-pill d-inline-block mb-10"
                  data-aos="fade-in"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-easing="linear"
                  data-aos-delay={100}
                >
                  Advantages
                </span>
                <h2
                  className="title-50 white mb-30"
                  data-aos="fade-in"
                  data-aos-easing="linear"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-delay={200}
                >
                  Step into the Realm of{" "}
                  <span className="gradient-text-animate">Elite Traders</span>
                </h2>
                <p
                  className="text-regular white mb-50"
                  data-aos="fade-in"
                  data-aos-easing="linear"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-delay={400}
                >
                  Ready for the next level? The challenge we provide is your
                  path to professional trading, offering significant capital to
                  manage, real-world market experience, and personalized support
                  to refine your strategy.
                </p>
                <div
                  className="mb-50"
                  data-aos="fade-in"
                  data-aos-easing="linear"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-delay={400}
                >
                  <a href="/challenge" className="blue-btn mb-50">
                    Start Now
                  </a>
                </div>
              </div>
            </div>
            <div className="row gx-3">
              {/*1 card*/}
              <div className="col-md-4">
                <div
                  className="card-advantages"
                  data-aos="fade-in"
                  data-aos-easing="linear"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-delay={500}
                >
                  <svg
                    className="mb-30"
                    xmlns="http://www.w3.org/2000/svg"
                    width={46}
                    height={46}
                    viewBox="0 0 46 46"
                    fill="none"
                  >
                    <path
                      d="M23 3.53223C19.2453 3.53223 15.5748 4.64564 12.4529 6.73167C9.33089 8.8177 6.89762 11.7827 5.46073 15.2516C4.02385 18.7205 3.6479 22.5377 4.38041 26.2203C5.11293 29.9029 6.92102 33.2856 9.57603 35.9406C12.231 38.5956 15.6137 40.4037 19.2963 41.1362C22.9789 41.8687 26.7961 41.4928 30.265 40.0559C33.734 38.619 36.6989 36.1857 38.7849 33.0638C40.871 29.9418 41.9844 26.2714 41.9844 22.5166C41.9788 17.4833 39.9769 12.6578 36.4178 9.09879C32.8588 5.53974 28.0333 3.53781 23 3.53223ZM23 37.2822C20.0796 37.2822 17.2249 36.4162 14.7967 34.7938C12.3685 33.1713 10.4759 30.8652 9.35835 28.1672C8.24077 25.4691 7.94837 22.5002 8.5181 19.636C9.08784 16.7717 10.4941 14.1407 12.5591 12.0757C14.6241 10.0107 17.2551 8.60443 20.1194 8.03469C22.9836 7.46496 25.9525 7.75737 28.6506 8.87494C31.3486 9.99252 33.6547 11.8851 35.2772 14.3133C36.8996 16.7415 37.7656 19.5962 37.7656 22.5166C37.7614 26.4314 36.2044 30.1847 33.4363 32.9528C30.6681 35.721 26.9148 37.278 23 37.2822ZM31.5236 23.8367C31.9199 24.233 32.1425 24.7704 32.1425 25.3309C32.1425 25.8913 31.9199 26.4287 31.5236 26.825C31.1274 27.2213 30.5899 27.4439 30.0295 27.4439C29.4691 27.4439 28.9316 27.2213 28.5354 26.825L23 21.2861L17.4611 26.8215C17.0649 27.2178 16.5274 27.4404 15.967 27.4404C15.4066 27.4404 14.8691 27.2178 14.4729 26.8215C14.0766 26.4252 13.854 25.8878 13.854 25.3273C13.854 24.7669 14.0766 24.2295 14.4729 23.8332L21.5041 16.802C21.7001 16.6053 21.9329 16.4493 22.1893 16.3428C22.4457 16.2363 22.7206 16.1815 22.9982 16.1815C23.2759 16.1815 23.5508 16.2363 23.8072 16.3428C24.0636 16.4493 24.2964 16.6053 24.4924 16.802L31.5236 23.8367Z"
                      fill="#3EBBD7"
                    />
                  </svg>
                  <h3 className="title-24 white mb-10">
                    Increase <br />
                    leverage
                  </h3>
                  <p className="text-regular white">
                    Trade with confidence and take advantage of increased
                    leverage on your dedicated account.
                  </p>
                </div>
              </div>
              {/*1 card*/}
              {/*1 card*/}
              <div className="col-md-4">
                <div
                  className="card-advantages"
                  data-aos="fade-in"
                  data-aos-easing="linear"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-delay={600}
                >
                  <svg
                    className="mb-30"
                    xmlns="http://www.w3.org/2000/svg"
                    width={45}
                    height={45}
                    viewBox="0 0 45 45"
                    fill="none"
                  >
                    <path
                      d="M42.4379 9.6416V20.6416C42.4379 21.1886 42.2206 21.7132 41.8338 22.1C41.447 22.4868 40.9224 22.7041 40.3754 22.7041C39.8284 22.7041 39.3038 22.4868 38.917 22.1C38.5302 21.7132 38.3129 21.1886 38.3129 20.6416V14.626L25.3346 27.606C25.143 27.7983 24.9153 27.9508 24.6646 28.0549C24.4139 28.159 24.1451 28.2126 23.8737 28.2126C23.6022 28.2126 23.3335 28.159 23.0828 28.0549C22.8321 27.9508 22.6044 27.7983 22.4128 27.606L17.0004 22.1885L6.08463 33.1008C5.69717 33.4883 5.17165 33.706 4.62369 33.706C4.07574 33.706 3.55022 33.4883 3.16276 33.1008C2.77529 32.7134 2.55762 32.1878 2.55762 31.6399C2.55762 31.0919 2.77529 30.5664 3.16276 30.1789L15.5378 17.8039C15.7294 17.6117 15.9571 17.4591 16.2078 17.355C16.4585 17.2509 16.7272 17.1973 16.9987 17.1973C17.2701 17.1973 17.5389 17.2509 17.7896 17.355C18.0403 17.4591 18.268 17.6117 18.4596 17.8039L23.8754 23.2197L35.391 11.7041H29.3754C28.8284 11.7041 28.3038 11.4868 27.917 11.1C27.5302 10.7132 27.3129 10.1886 27.3129 9.6416C27.3129 9.09459 27.5302 8.56999 27.917 8.18319C28.3038 7.7964 28.8284 7.5791 29.3754 7.5791H40.3754C40.9224 7.5791 41.447 7.7964 41.8338 8.18319C42.2206 8.56999 42.4379 9.09459 42.4379 9.6416Z"
                      fill="#3EBBD7"
                    />
                  </svg>
                  <h3 className="title-24 white">
                    Extra <br /> 15% Performance
                  </h3>
                  <p className="text-regular white">
                    Achieve and surpass your target in the challenge, and enjoy
                    boosted payouts as a reward for your exceptional trading
                    performance.
                  </p>
                </div>
              </div>
              {/*1 card*/}
              {/*1 card*/}
              <div className="col-md-4">
                <div
                  className="card-advantages"
                  data-aos="fade-in"
                  data-aos-easing="linear"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-delay={700}
                >
                  <svg
                    className="mb-30"
                    xmlns="http://www.w3.org/2000/svg"
                    width={46}
                    height={46}
                    viewBox="0 0 46 46"
                    fill="none"
                  >
                    <path
                      d="M37.0625 6.34473H8.9375C8.0051 6.34473 7.11089 6.71512 6.45158 7.37443C5.79227 8.03374 5.42188 8.92795 5.42188 9.86035V20.1963C5.42188 36.4314 19.1504 41.8174 21.9031 42.7279C22.6148 42.967 23.3852 42.967 24.0969 42.7279C26.8496 41.8139 40.5781 36.4314 40.5781 20.1963V9.86035C40.5781 8.92795 40.2077 8.03374 39.5484 7.37443C38.8891 6.71512 37.9949 6.34473 37.0625 6.34473ZM36.3594 20.1963C36.3594 33.1268 25.7193 37.6918 23 38.6533C20.2807 37.7006 9.64062 33.1355 9.64062 20.2051V10.5635H36.3594V20.1963ZM14.4764 25.4152C14.2802 25.219 14.1245 24.9861 14.0183 24.7297C13.9121 24.4734 13.8575 24.1986 13.8575 23.9211C13.8575 23.6436 13.9121 23.3688 14.0183 23.1125C14.1245 22.8561 14.2802 22.6232 14.4764 22.427C14.6726 22.2307 14.9055 22.0751 15.1619 21.9689C15.4183 21.8627 15.693 21.8081 15.9705 21.8081C16.248 21.8081 16.5228 21.8627 16.7791 21.9689C17.0355 22.0751 17.2684 22.2307 17.4646 22.427L20.1875 25.1533L28.5389 16.802C28.9351 16.4057 29.4726 16.1831 30.033 16.1831C30.5934 16.1831 31.1309 16.4057 31.5271 16.802C31.9234 17.1982 32.146 17.7357 32.146 18.2961C32.146 18.8565 31.9234 19.394 31.5271 19.7902L21.6834 29.634C21.4874 29.8306 21.2546 29.9867 20.9982 30.0931C20.7418 30.1996 20.4669 30.2544 20.1893 30.2544C19.9116 30.2544 19.6367 30.1996 19.3803 30.0931C19.1239 29.9867 18.8911 29.8306 18.6951 29.634L14.4764 25.4152Z"
                      fill="#3EBBD7"
                    />
                  </svg>
                  <h3 className="title-24 white">
                    Tailor your
                    <br />
                    Risk
                  </h3>
                  <p className="text-regular white">
                    Trade with confidence and take advantage of increased
                    leverage on your dedicated account.
                  </p>
                </div>
              </div>
              {/*1 card*/}
            </div>
          </div>
        </section>

        <section className="section-80" id="pricing-section">
          <div className="custom-container">
            <div className="row">
              <div className="col-md-6 offset-md-3 text-center">
                <span className="text-bold blue-pill d-inline-block mb-10">
                  Pricing
                </span>
                <h2 className="title-50 white mb-30">
                  Choose your{" "}
                  <span className="gradient-text-animate"> Account</span>
                </h2>
              </div>
            </div>
            <div className="row d-flex justify-content-center">
              <ChallengeCard
                promoURL=""
                nextRoute="/register"
                currency="eur"
                index={4}
                phaseSelected={1}
              />
            </div>
          </div>
        </section>

        <section className="section-80" id="work-section">
          <div className="custom-container">
            <span
              className="text-bold blue-pill d-inline-block mb-10"
              data-aos="fade-in"
              data-aos-easing="linear"
              data-aos-anchor-placement="top-bottom"
              data-aos-delay={100}
            >
              How it works
            </span>
            <h2
              className="title-50 white"
              data-aos="fade-in"
              data-aos-easing="linear"
              data-aos-anchor-placement="top-bottom"
              data-aos-delay={400}
            >
              3 <span className="gradient-text-animate">simples steps</span>
            </h2>
            <p
              className="text-regular white mb-50"
              data-aos="fade-in"
              data-aos-easing="linear"
              data-aos-anchor-placement="top-bottom"
              data-aos-delay={500}
            >
              Trade the way you want , how you want , for as long as you want...
            </p>
            <div className="row gx-3">
              <>
                {/*Step 1*/}
                <div className="col-md-4">
                  <div
                    className="card-step"
                    data-aos="fade-in"
                    data-aos-easing="linear"
                    data-aos-anchor-placement="top-bottom"
                    data-aos-delay={600}
                  >
                    <h2 className="title-50 gradient-text-animate">01</h2>
                    <h3 className="title-24 white">Choose</h3>
                    <p className="text-regular white">
                      Traders can receive up to 90% split on their live
                      accounts.
                    </p>
                  </div>
                </div>
                {/*Step 1*/}
                {/*Step 2*/}
                <div className="col-md-4">
                  <div
                    className="card-step"
                    data-aos="fade-in"
                    data-aos-easing="linear"
                    data-aos-anchor-placement="top-bottom"
                    data-aos-delay={700}
                  >
                    <h2 className="title-50 gradient-text-animate">02</h2>
                    <h3 className="title-24 white">Trade</h3>
                    <p className="text-regular white">
                      Trade the 1 phase or 2 phase assessment. Express or take
                      as much time as your need.
                    </p>
                  </div>
                </div>
                {/*Step 2*/}
                {/*Step 3*/}
                <div className="col-md-4">
                  <div
                    className="card-step"
                    data-aos="fade-in"
                    data-aos-easing="linear"
                    data-aos-anchor-placement="top-bottom"
                    data-aos-delay={800}
                  >
                    <h2 className="title-50 gradient-text-animate">03</h2>
                    <h3 className="title-24 white">Get funded</h3>
                    <p className="text-regular white">
                      Trade up to 400,000$ starting capital after passing the
                      assessment.
                    </p>
                  </div>
                </div>
                {/*Step 3*/}
              </>
            </div>
          </div>
        </section>

        <section className="section-80" id="Withdraw-section">
          <div className="custom-container">
            <div className="row gx-3 align-items-center">
              <div className="col-md-6 order-2 order-md-1">
                <span
                  className="text-bold blue-pill d-inline-block mb-10"
                  data-aos="fade-in"
                  data-aos-easing="linear"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-delay={100}
                >
                  Payouts
                </span>
                <h2
                  className="title-50 mb-30 white"
                  data-aos="fade-in"
                  data-aos-easing="linear"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-delay={400}
                >
                  Withdrawal{" "}
                  <span className="gradient-text-animate">on demand</span>
                </h2>
                <p
                  className="text-regular white mb-30"
                  data-aos="fade-in"
                  data-aos-easing="linear"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-delay={500}
                >
                  For the first payout, traders can request a withdrawal
                  on-demand.
                </p>

                <div className="row">
                  {/*1 card*/}
                  <div className="col-md-6">
                    <div
                      className="card-withdraw"
                      data-aos="fade-in"
                      data-aos-easing="linear"
                      data-aos-anchor-placement="top-bottom"
                      data-aos-delay={600}
                    >
                      <h3 className="title-24 white mb-20">90% perform.</h3>
                      <p className="text-regular white">
                        Traders can receive up to 90% split on their live
                        accounts.
                      </p>
                    </div>
                  </div>
                  {/*1 card*/}
                  {/*1 card*/}
                  <div className="col-md-6">
                    <div
                      className="card-withdraw"
                      data-aos="fade-in"
                      data-aos-easing="linear"
                      data-aos-anchor-placement="top-bottom"
                      data-aos-delay={700}
                    >
                      <h3 className="title-24 white mb-20">Easy</h3>
                      <p className="text-regular white">
                        Experience an easy and seamless withdrawal process.
                      </p>
                    </div>
                  </div>
                  {/*1 card*/}
                  {/*1 card*/}
                  <div className="col-md-6">
                    <div
                      className="card-withdraw"
                      data-aos="fade-in"
                      data-aos-easing="linear"
                      data-aos-anchor-placement="top-bottom"
                      data-aos-delay={800}
                    >
                      <h3 className="title-24 white mb-20">Flexible</h3>
                      <p className="text-regular white">
                        There are up to 15 different payout options to choose.
                      </p>
                    </div>
                  </div>
                  {/*1 card*/}
                  {/*1 card*/}
                  <div className="col-md-6">
                    <div
                      className="card-withdraw"
                      data-aos="fade-in"
                      data-aos-easing="linear"
                      data-aos-anchor-placement="top-bottom"
                      data-aos-delay={800}
                    >
                      <h3 className="title-24 white mb-20">On demand</h3>
                      <p className="text-regular white">
                        Traders can request a withdrawal on-demand.
                      </p>
                    </div>
                  </div>
                  {/*1 card*/}
                </div>
              </div>
              <div className="col-md-6 order-1 order-md-2 text-center">
                <img
                  data-aos="fade-in"
                  data-aos-easing="linear"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-delay={300}
                  src={withdrawImg}
                  alt=""
                  className="img-fluid mb-20"
                />
              </div>
            </div>
          </div>
        </section>
        {/*withdraw on demand*/}

        {/*more advantages*/}
        <section
          className="section-80"
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-delay={300}
          data-aos-offset={0}
        >
          {/*advantages*/}
          <div className="custom-container">
            <div className="row">
              <div className="col-md-8">
                <span
                  className="text-bold blue-pill d-inline-block mb-10"
                  data-aos="fade-in"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-easing="linear"
                  data-aos-delay={100}
                >
                  Trading rules
                </span>
                <h2
                  className="title-50 white mb-30"
                  data-aos="fade-in"
                  data-aos-easing="linear"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-delay={200}
                >
                  Unleashing
                  <span className="gradient-text-animate">
                    Your Trading Potential
                  </span>
                </h2>
                <p
                  className="text-regular white-50 mb-50"
                  data-aos="fade-in"
                  data-aos-easing="linear"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-delay={400}
                >
                  Trade with freedom and flexibility, enjoying unparalleled
                  conditions, instant withdrawals, and access to a wide range of
                  markets. Your gateway to a rewarding trading journey.
                </p>
              </div>
            </div>
            <div className="row gx-3">
              {/*1 card*/}
              <div className="col-md-4">
                <div
                  className="card-advantages"
                  data-aos="fade-in"
                  data-aos-easing="linear"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-delay={500}
                >
                  <svg
                    className="mb-10"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={46}
                    height={46}
                    fill="rgba(62,187,215,1)"
                  >
                    <path d="M3 19H21V21H3V19ZM13 13.1716L19.0711 7.1005L20.4853 8.51472L12 17L3.51472 8.51472L4.92893 7.1005L11 13.1716V2H13V13.1716Z" />
                  </svg>
                  <h3 className="title-24 white mb-10">
                    Payouts <br /> on Demand
                  </h3>
                  <p className="text-regular white-50">
                    Withdraw anytime after your success
                  </p>
                </div>
              </div>
              {/*1 card*/}
              {/*1 card*/}
              <div className="col-md-4">
                <div
                  className="card-advantages "
                  data-aos="fade-in"
                  data-aos-easing="linear"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-delay={600}
                >
                  <svg
                    className="mb-10"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={46}
                    height={46}
                    fill="rgba(62,187,215,1)"
                  >
                    <path d="M12.0049 22.0027C6.48204 22.0027 2.00488 17.5256 2.00488 12.0027C2.00488 6.4799 6.48204 2.00275 12.0049 2.00275C17.5277 2.00275 22.0049 6.4799 22.0049 12.0027C22.0049 17.5256 17.5277 22.0027 12.0049 22.0027ZM12.0049 20.0027C16.4232 20.0027 20.0049 16.421 20.0049 12.0027C20.0049 7.58447 16.4232 4.00275 12.0049 4.00275C7.5866 4.00275 4.00488 7.58447 4.00488 12.0027C4.00488 16.421 7.5866 20.0027 12.0049 20.0027ZM8.50488 14.0027H14.0049C14.281 14.0027 14.5049 13.7789 14.5049 13.5027C14.5049 13.2266 14.281 13.0027 14.0049 13.0027H10.0049C8.62417 13.0027 7.50488 11.8835 7.50488 10.5027C7.50488 9.12203 8.62417 8.00275 10.0049 8.00275H11.0049V6.00275H13.0049V8.00275H15.5049V10.0027H10.0049C9.72874 10.0027 9.50488 10.2266 9.50488 10.5027C9.50488 10.7789 9.72874 11.0027 10.0049 11.0027H14.0049C15.3856 11.0027 16.5049 12.122 16.5049 13.5027C16.5049 14.8835 15.3856 16.0027 14.0049 16.0027H13.0049V18.0027H11.0049V16.0027H8.50488V14.0027Z" />
                  </svg>
                  <h3 className="title-24 white">
                    Over <br /> $14M Paid Out
                  </h3>
                  <p className="text-regular white-50">
                    Supporting traders' success.
                  </p>
                </div>
              </div>
              {/*1 card*/}
              {/*1 card*/}
              <div className="col-md-4">
                <div
                  className="card-advantages"
                  data-aos="fade-in"
                  data-aos-easing="linear"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-delay={700}
                >
                  <svg
                    className="mb-10"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={46}
                    height={46}
                    fill="rgba(62,187,215,1)"
                  >
                    <path d="M12.0006 18.26L4.94715 22.2082L6.52248 14.2799L0.587891 8.7918L8.61493 7.84006L12.0006 0.5L15.3862 7.84006L23.4132 8.7918L17.4787 14.2799L19.054 22.2082L12.0006 18.26ZM12.0006 15.968L16.2473 18.3451L15.2988 13.5717L18.8719 10.2674L14.039 9.69434L12.0006 5.27502L9.96214 9.69434L5.12921 10.2674L8.70231 13.5717L7.75383 18.3451L12.0006 15.968Z" />
                  </svg>
                  <h3 className="title-24 white">
                    Unrivalled
                    <br /> Conditions
                  </h3>
                  <p className="text-regular white-50">
                    Optimal settings for your trades.
                  </p>
                </div>
              </div>
              {/*1 card*/}
              <div className="col-md-4">
                <div
                  className="card-advantages"
                  data-aos="fade-in"
                  data-aos-easing="linear"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-delay={700}
                >
                  <svg
                    className="mb-10"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={46}
                    height={46}
                    fill="rgba(62,187,215,1)"
                  >
                    <path d="M5 3V19H21V21H3V3H5ZM20.2929 6.29289L21.7071 7.70711L16 13.4142L13 10.415L8.70711 14.7071L7.29289 13.2929L13 7.58579L16 10.585L20.2929 6.29289Z" />
                  </svg>
                  <h3 className="title-24 white">
                    90% <br /> Performance Split
                  </h3>
                  <p className="text-regular white-50">
                    Earn up to 90% of profits.
                  </p>
                </div>
              </div>
              {/*1 card*/}
              <div className="col-md-4">
                <div
                  className="card-advantages"
                  data-aos="fade-in"
                  data-aos-easing="linear"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-delay={700}
                >
                  <svg
                    className="mb-10"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={46}
                    height={46}
                    fill="rgba(62,187,215,1)"
                  >
                    <path d="M15.5018 20.0048C14.8432 21.5841 13.5794 22.848 12 23.5066C10.4206 22.848 9.15679 21.5841 8.49816 20.0048H10.7575C11.0838 20.4934 11.5051 20.9171 12 21.248C12.4949 20.9171 12.9162 20.4934 13.2425 20.0048H15.5018ZM18 14.8095L20 17.0777V19.0048H4V17.0777L6 14.8095V9.00478C6 5.52153 8.50442 2.55822 12 1.45999C15.4956 2.55822 18 5.52153 18 9.00478V14.8095ZM17.2692 17.0048L16 15.5653V9.00478C16 6.68694 14.4292 4.57431 12 3.58384C9.57078 4.57431 8 6.68694 8 9.00478V15.5653L6.73076 17.0048H17.2692ZM12 11.0048C10.8954 11.0048 10 10.1093 10 9.00478C10 7.90021 10.8954 7.00478 12 7.00478C13.1046 7.00478 14 7.90021 14 9.00478C14 10.1093 13.1046 11.0048 12 11.0048Z" />
                  </svg>
                  <h3 className="title-24 white">
                    Up to <br /> $400K Capital
                  </h3>
                  <p className="text-regular white-50">
                    Start big, grow bigger.
                  </p>
                </div>
              </div>
              {/*1 card*/}
              <div className="col-md-4">
                <div
                  className="card-advantages"
                  data-aos="fade-in"
                  data-aos-easing="linear"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-delay={700}
                >
                  <svg
                    className="mb-10"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={46}
                    height={46}
                    fill="rgba(62,187,215,1)"
                  >
                    <path d="M13.5 2C13.5 2.44425 13.3069 2.84339 13 3.11805V5H18C19.6569 5 21 6.34315 21 8V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V8C3 6.34315 4.34315 5 6 5H11V3.11805C10.6931 2.84339 10.5 2.44425 10.5 2C10.5 1.17157 11.1716 0.5 12 0.5C12.8284 0.5 13.5 1.17157 13.5 2ZM6 7C5.44772 7 5 7.44772 5 8V18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18V8C19 7.44772 18.5523 7 18 7H13H11H6ZM2 10H0V16H2V10ZM22 10H24V16H22V10ZM9 14.5C9.82843 14.5 10.5 13.8284 10.5 13C10.5 12.1716 9.82843 11.5 9 11.5C8.17157 11.5 7.5 12.1716 7.5 13C7.5 13.8284 8.17157 14.5 9 14.5ZM15 14.5C15.8284 14.5 16.5 13.8284 16.5 13C16.5 12.1716 15.8284 11.5 15 11.5C14.1716 11.5 13.5 12.1716 13.5 13C13.5 13.8284 14.1716 14.5 15 14.5Z" />
                  </svg>
                  <h3 className="title-24 white">
                    EAs <br /> Allowed
                  </h3>
                  <p className="text-regular white-50">
                    Use Expert Advisors freely.
                  </p>
                </div>
              </div>
              {/*1 card*/}
              <div className="col-md-4">
                <div
                  className="card-advantages"
                  data-aos="fade-in"
                  data-aos-easing="linear"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-delay={700}
                >
                  <svg
                    className="mb-10"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={46}
                    height={46}
                    fill="rgba(62,187,215,1)"
                  >
                    <path d="M6 4H4V2H20V4H18V6C18 7.61543 17.1838 8.91468 16.1561 9.97667C15.4532 10.703 14.598 11.372 13.7309 12C14.598 12.628 15.4532 13.297 16.1561 14.0233C17.1838 15.0853 18 16.3846 18 18V20H20V22H4V20H6V18C6 16.3846 6.81616 15.0853 7.8439 14.0233C8.54682 13.297 9.40202 12.628 10.2691 12C9.40202 11.372 8.54682 10.703 7.8439 9.97667C6.81616 8.91468 6 7.61543 6 6V4ZM8 4V6C8 6.88457 8.43384 7.71032 9.2811 8.58583C10.008 9.33699 10.9548 10.0398 12 10.7781C13.0452 10.0398 13.992 9.33699 14.7189 8.58583C15.5662 7.71032 16 6.88457 16 6V4H8ZM12 13.2219C10.9548 13.9602 10.008 14.663 9.2811 15.4142C8.43384 16.2897 8 17.1154 8 18V20H16V18C16 17.1154 15.5662 16.2897 14.7189 15.4142C13.992 14.663 13.0452 13.9602 12 13.2219Z" />
                  </svg>
                  <h3 className="title-24 white">
                    Unlimited <br /> Trading Days
                  </h3>
                  <p className="text-regular white-50">
                    Trade on your schedule.
                  </p>
                </div>
              </div>
              {/*1 card*/}
              <div className="col-md-4">
                <div
                  className="card-advantages"
                  data-aos="fade-in"
                  data-aos-easing="linear"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-delay={700}
                >
                  <svg
                    className="mb-10"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={46}
                    height={46}
                    fill="rgba(62,187,215,1)"
                  >
                    <path d="M5.46257 4.43262C7.21556 2.91688 9.5007 2 12 2C17.5228 2 22 6.47715 22 12C22 14.1361 21.3302 16.1158 20.1892 17.7406L17 12H20C20 7.58172 16.4183 4 12 4C9.84982 4 7.89777 4.84827 6.46023 6.22842L5.46257 4.43262ZM18.5374 19.5674C16.7844 21.0831 14.4993 22 12 22C6.47715 22 2 17.5228 2 12C2 9.86386 2.66979 7.88416 3.8108 6.25944L7 12H4C4 16.4183 7.58172 20 12 20C14.1502 20 16.1022 19.1517 17.5398 17.7716L18.5374 19.5674Z" />
                  </svg>
                  <h3 className="title-24 white">
                    No <br /> Consistency Rules
                  </h3>
                  <p className="text-regular white-50">Trade your way.</p>
                </div>
              </div>
              {/*1 card*/}
              <div className="col-md-4">
                <div
                  className="card-advantages"
                  data-aos="fade-in"
                  data-aos-easing="linear"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-delay={700}
                >
                  <svg
                    className="mb-10"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={46}
                    height={46}
                    fill="rgba(62,187,215,1)"
                  >
                    <path d="M4 3H20C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3ZM5 5V19H19V5H5ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z" />
                  </svg>
                  <h3 className="title-24 white">
                    No <br /> Trading Fees
                  </h3>
                  <p className="text-regular white-50">
                    Keep more of your profits.
                  </p>
                </div>
              </div>
              {/*1 card*/}
              <div className="col-md-4">
                <div
                  className="card-advantages"
                  data-aos="fade-in"
                  data-aos-easing="linear"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-delay={700}
                >
                  <svg
                    className="mb-10"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={46}
                    height={46}
                    fill="rgba(62,187,215,1)"
                  >
                    <path d="M9 1V3H15V1H17V3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3H7V1H9ZM20 8H4V19H20V8ZM15.0355 10.136L16.4497 11.5503L11.5 16.5L7.96447 12.9645L9.37868 11.5503L11.5 13.6716L15.0355 10.136Z" />
                  </svg>
                  <h3 className="title-24 white">Bi-weekly payouts</h3>
                  <p className="text-regular white-50">
                    Regular earnings access.
                  </p>
                </div>
              </div>
              {/*1 card*/}
              <div className="col-md-4">
                <div
                  className="card-advantages"
                  data-aos="fade-in"
                  data-aos-easing="linear"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-delay={700}
                >
                  <svg
                    className="mb-10"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={46}
                    height={46}
                    fill="rgba(62,187,215,1)"
                  >
                    <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 10.5858L14.8284 7.75736L16.2426 9.17157L13.4142 12L16.2426 14.8284L14.8284 16.2426L12 13.4142L9.17157 16.2426L7.75736 14.8284L10.5858 12L7.75736 9.17157L9.17157 7.75736L12 10.5858Z" />
                  </svg>
                  <h3 className="title-24 white">No SL Requirement</h3>
                  <p className="text-regular white-50">
                    Trade without stop-loss limits.
                  </p>
                </div>
              </div>
              {/*1 card*/}
              <div className="col-md-4">
                <div
                  className="card-advantages"
                  data-aos="fade-in"
                  data-aos-easing="linear"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-delay={700}
                >
                  <svg
                    className="mb-10"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={46}
                    height={46}
                    fill="rgba(62,187,215,1)"
                  >
                    <path d="M2 13H8V21H2V13ZM16 8H22V21H16V8ZM9 3H15V21H9V3ZM4 15V19H6V15H4ZM11 5V19H13V5H11ZM18 10V19H20V10H18Z" />
                  </svg>
                  <h3 className="title-24 white">Weekend holding</h3>
                  <p className="text-regular white-50">
                    Keep positions open over weekends.
                  </p>
                </div>
              </div>
              {/*1 card*/}
            </div>
          </div>
          {/*advantages*/}
        </section>

        {/*Dashboard*/}
        <section
          className="section-80"
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-delay={300}
          data-aos-offset={0}
        >
          <div className="custom-container">
            <div className="row align-items-center">
              <div className="col-md-6 order-2 order-md-1">
                <span
                  className="text-bold blue-pill d-inline-block mb-10"
                  data-aos="fade-in"
                  data-aos-easing="linear"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-delay={100}
                >
                  Dashboard
                </span>
                <h2 className="title-50 white mb-30">
                  <span
                    className="gradient-text-animate"
                    data-aos="fade-in"
                    data-aos-easing="linear"
                    data-aos-anchor-placement="top-bottom"
                    data-aos-delay={200}
                  >
                    Revolutionary
                  </span>{" "}
                  Trading Dashboard
                </h2>
                <p
                  className="text-regular white mb-30"
                  data-aos="fade-in"
                  data-aos-easing="linear"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-delay={300}
                >
                  Our cutting-edge trading dashboard is equipped with advanced
                  analytics that empower traders to comprehensively evaluate
                  their trading performance and statistics.
                </p>
                <a
                  href="/challenge"
                  className="blue-btn d-inline-block"
                  data-aos="fade-in"
                  data-aos-easing="linear"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-delay={400}
                >
                  Start now
                </a>
              </div>
              <div className="col-md-6 order-1 order-md-2 text-center">
                <img
                  src={dashboardImg}
                  alt=""
                  className="img-fluid mb-30"
                  data-aos="fade-in"
                  data-aos-easing="linear"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-delay={500}
                />
              </div>
            </div>
          </div>
        </section>
        {/*Dashboard*/}

        <section className="section-80" id="faq-section">
          <div className="custom-container">
            <div className="">
              <div
                className="text-bold blue-pill d-inline-block"
                data-aos="fade-in"
                data-aos-easing="linear"
                data-aos-anchor-placement="top-bottom"
                data-aos-delay={100}
                style={{ marginBottom: "40px" }}
              >
                Most Asked Questions
              </div>
            </div>

            <div className="accordion">
              {faqData[1].map((item: any, id: number) => (
                <div
                  className="card-advantages"
                  key={id}
                  onClick={() => toggleOpen(id)}
                >
                  <div className="card-header" id={`heading${id}`}>
                    <h5 className="mb-0">
                      <button
                        className={`button-accordion w-100 text-start title-24 ${
                          openId === id ? "" : "collapsed"
                        }`}
                        type="button"
                        aria-expanded={openId === id}
                        aria-controls={`collapse${id}`}
                      >
                        {item.question}
                      </button>
                    </h5>
                  </div>
                  <div
                    id={`collapse${id}`}
                    className={`collapse  ${openId === id ? "show" : ""}`}
                    aria-labelledby={`heading${id}`}
                    data-parent="#accordionExample"
                  >
                    <div className="text-regular white mt-3">
                      {item.response}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/*trustpillot*/}
        <section className="section-80">
          <div className="custom-container">
            <div className="col-md-8 offset-md-2 text-center">
              <a
                href="/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="image-wrapper">
                  <img
                    src={trustpilotImg}
                    alt=""
                    className="img-fluid trustpilot-img mb-20"
                  />
                </div>
              </a>

              <h2
                className="title-50 white mb-30"
                data-aos="fade-in"
                data-aos-easing="linear"
                data-aos-anchor-placement="top-bottom"
                data-aos-delay={100}
              >
                <span className="green">Everything You’d Expect</span> From
                a&nbsp;Retail Broker, in a Prop Firm
              </h2>
              <p
                className="text-regular white mb-30"
                data-aos="fade-in"
                data-aos-easing="linear"
                data-aos-anchor-placement="top-bottom"
                data-aos-delay={200}
              >
                Trade on MT4/MT5 with a leading Multi-Asset Broker offer 100%
                True STP execution across 300+ instruments including Crypto,
                Indices, Stocks & more.
              </p>

              <div className="row gx-3 text-start px-2">
                <Swiper
                  spaceBetween={30}
                  slidesPerView={1}
                  scrollbar={{
                    hide: true,
                  }}
                  pagination={true} modules={[Pagination]}
                  breakpoints={{
                    // when window width is >= 640px
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    // when window width is >= 768px
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 30,
                    },
                    // when window width is >= 1024px
                    1024: {
                      slidesPerView: 2,
                      spaceBetween: 40,
                    },
                    // when window width is >= 1440px
                    1440: {
                      slidesPerView: 2,
                      spaceBetween: 50,
                    },
                  }}
                >
                  <SwiperSlide>
                    <div className="my-2">
                      <div
                    className="card-trustpilot "
                        data-aos="fade-in"
                        data-aos-easing="linear"
                        data-aos-anchor-placement="top-bottom"
                        data-aos-delay={700}
                      >
                        <img
                          src={trustPilotCard}
                          className="img-fluid mb-3 w-75"
                          alt="Trust Pilot"
                        />
                        <h3 className="title-16 white">Awesome platform</h3>
                        <p className="text-regular white">
                          Awesome products, great promos, and cool customer
                          support.
                        </p>
                        <p className="text-regular white">
                          <span className="blue-gradient">Raul</span>, May
                          11
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="my-2">
                      <div
                              className="card-trustpilot "
                        data-aos="fade-in"
                        data-aos-easing="linear"
                        data-aos-anchor-placement="top-bottom"
                        data-aos-delay={700}
                      >
                        <img
                          src={trustPilotCard}
                          className="img-fluid mb-3 w-75"
                          alt="Trust Pilot"
                        />
                        <h3 className="title-16 white">Must Buy this prop firm challenge very supportive</h3>
                        <p className="text-regular white">
                          I'm very happu work this prop firm. It's legit prop firm support very quick re...
                        </p>
                        <p className="text-regular white">
                          <span className="blue-gradient">Awais Akram</span>, May
                          8
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="my-2">
                      <div
                           className="card-trustpilot "
                        data-aos="fade-in"
                        data-aos-easing="linear"
                        data-aos-anchor-placement="top-bottom"
                        data-aos-delay={700}
                      >
                        <img
                          src={trustPilotCard}
                          className="img-fluid mb-3 w-75"
                          alt="Trust Pilot"
                        />
                        <h3 className="title-16 white">Fast & swift payout process</h3>
                        <p className="text-regular white">
                        Fast & swift payout process. As long as you have done the request correctly. Within a...
                        </p>
                        <p className="text-regular white">
                          <span className="blue-gradient">Warren</span>, May
                          4
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="my-2">
                      <div
                               className="card-trustpilot "
                        data-aos="fade-in"
                        data-aos-easing="linear"
                        data-aos-anchor-placement="top-bottom"
                        data-aos-delay={700}
                      >
                        <img
                          src={trustPilotCard}
                          className="img-fluid mb-3 w-75"
                          alt="Trust Pilot"
                        />
                        <h3 className="title-16 white">Great company 👍</h3>
                        <p className="text-regular white">
                          I have been trading on Next Funded since a few months now, the support is very re...
                        </p>
                        <p className="text-regular white">
                          <span className="blue-gradient">Morten</span>, May
                          1
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="my-2">
                      <div
                                     className="card-trustpilot "
                        data-aos="fade-in"
                        data-aos-easing="linear"
                        data-aos-anchor-placement="top-bottom"
                        data-aos-delay={700}
                      >
                        <img
                          src={trustPilotCard}
                          className="img-fluid mb-3 w-75"
                          alt="Trust Pilot"
                        />
                        <h3 className="title-16 white">Great prop firm</h3>
                        <p className="text-regular white">
                         Great prop firm, been collaborating with them for over 3 mounts and payout are always...
                        </p>
                        <p className="text-regular white">
                          <span className="blue-gradient">Michael Caporaso</span>, March
                          19
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="my-2">
                      <div
                           className="card-trustpilot "
                        data-aos="fade-in"
                        data-aos-easing="linear"
                        data-aos-anchor-placement="top-bottom"
                        data-aos-delay={700}
                      >
                        <img
                          src={trustPilotCard}
                          className="img-fluid mb-3 w-75"
                          alt="Trust Pilot"
                        />
                        <h3 className="title-16 white">Excellent service</h3>
                        <p className="text-regular white">
                        Excellent service, very professional and helpful. I would recommend this company to any...
                        </p>
                        <p className="text-regular white">
                          <span className="blue-gradient">Christina Campos</span>, February
                          19
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="my-2">
                      <div
                                  className="card-trustpilot "
                        data-aos="fade-in"
                        data-aos-easing="linear"
                        data-aos-anchor-placement="top-bottom"
                        data-aos-delay={700}
                      >
                        <img
                          src={trustPilotCard}
                          className="img-fluid mb-3 w-75"
                          alt="Trust Pilot"
                        />
                        <h3 className="title-16 white">Received 3 payouts</h3>
                        <p className="text-regular white">
                        Received 3 payouts. A very faire profit split and a very good support a...
                        </p>
                        <p className="text-regular white">
                          <span className="blue-gradient">Elis Thomas</span>, February
                          8
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="my-2">
                      <div
                                       className="card-trustpilot "
                        data-aos="fade-in"
                        data-aos-easing="linear"
                        data-aos-anchor-placement="top-bottom"
                        data-aos-delay={700}
                      >
                        <img
                          src={trustPilotCard}
                          className="img-fluid mb-3 w-75"
                          alt="Trust Pilot"
                        />
                        <h3 className="title-16 white">Great client care</h3>
                        <p className="text-regular white">
                        Best cutomer support I have ever experienced. 🙂
                        </p>
                        <p className="text-regular white">
                          <span className="blue-gradient">Ingrid Sebastian</span>, January
                          29
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
              <p className="title-16 white">Rated <strong>4.6</strong> / 5 based on <a href="/">1,379 reviews</a></p>
            
            </div>
          </div>
        </section>
        {/*trustpillot*/}
        {/* DISCORD */}
        <section
          className="mt-4"
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-delay={300}
          data-aos-offset={0}
        >
          <div className="custom-container">
            <div className="row align-items-center">
              <div className="col-md-6 order-2 order-md-2">
                <span
                  className="text-bold blue-pill d-inline-block mb-10"
                  data-aos="fade-in"
                  data-aos-easing="linear"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-delay={100}
                >
                  Community
                </span>
                <h2 className="title-50 white mb-30">
                  <span
                    className="gradient-text-animate"
                    data-aos="fade-in"
                    data-aos-easing="linear"
                    data-aos-anchor-placement="top-bottom"
                    data-aos-delay={200}
                  >
                    Explore
                  </span>{" "}
                 Community payouts
                </h2>
                <a
                  href="https://discord.gg/Sd7XdA5DYQ"
                  className="blue-btn d-inline-block"
                  data-aos="fade-in"
                  data-aos-easing="linear"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-delay={400}
                >
                  View payouts<svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M16.0037 9.41421L7.39712 18.0208L5.98291 16.6066L14.5895 8H7.00373V6H18.0037V17H16.0037V9.41421Z"></path></svg>
                </a>
              </div>
              <div className="col-md-6 order-1 order-md-1 text-center">
                <img
                  src={discordLogo}
                  alt=""
                  className="img-fluid mb-30"
                  data-aos="fade-in"
                  data-aos-easing="linear"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-delay={500}
                />
              </div>
            </div>
          </div>
        </section>
        {/* DISCORD */}
        {/*Join*/}
        <section
          className="section-80"
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-delay={300}
          data-aos-offset={0}
        >
          <div className="custom-container">
            <div className="card-join " style={{ overflow: "hidden" }}>
              <div className="row align-items-center">
                <div className="col-lg-7 ">
                  <h2 className="title-40 white mb-30">
                    Join our Team of Experienced Traders
                  </h2>
                  <p className="text-regular white mb-50">
                    Traders can take their trading to the next level and embark
                    on their journey to become funded prop traders. Choose
                    between a 1 phase and 2 phase account and customize it to
                    suit their trading strategy.
                  </p>
                  <div className="row mb-2">
                    <div className="col-lg-3">
                      <a
                        href="https://discord.gg/Sd7XdA5DYQ"
                        target="_blank"
                        className="transparent-btn w-100"
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        Discord
                      </a>
                    </div>
                    <div className="col-lg-3">
                      <a
                        target="_blank"
                        href="https://www.instagram.com/next_funded"
                        style={{ textDecoration: "none", color: "white" }}
                        className="transparent-btn w-100 marginbottom"
                      >
                        Instagram
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 text-end">
                  <img
                    src={nneonImg}
                    className="n-join img-scale img-fluid neonImg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*Join*/}
        <Footer />
      </div>
    </>
  );
};
