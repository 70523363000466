import { Foot } from "../../components/foot";
import { Footer } from "../../components/footer";
import { Nav } from "../../components/nav";

export const Terms = () => {
  return (
    <>
      <Nav />

      <div className="container d-flex justify-content-center   mt-5 py-5 px-5 mb-5">
        <div className="col-md-8 col-12 text-md-start text-center">
          <h1 className="title-50 white ">
            General Terms of Service of{" "}
            <span className="color-blue">Next Funded</span>
          </h1>
          <p className="white mt-4">
          <h2 className="title-24 mt-4">
            This Privacy Policy (the “Policy”) informs international citizens,
            excluding residents of the United States and Canada, about the
            processing of personal data by NextFunded LLC when using our
            services. This policy outlines how we collect, use, store, and
            safeguard your personal data and informs you of your rights
            regarding this data.
            </h2>
            <h2 className="title-24 mt-4">
              Controller of Personal Data and Contact Information
            </h2>
            NextFunded LLC, located at 254 Chapman Rd, Ste 208 #15841, Newark,
            Delaware 19702, USA, is the controller of your personal data. For
            any queries or to exercise your rights related to the processing of
            your personal data, please contact us at support@nextfunded.com.
            <h2 className="title-24 mt-4">Data Collection and Sources</h2>
            We collect personal data directly from you through your interactions
            with our services, including registration on our website, user
            account information, service orders, and communications with us. The
            data includes identification, contact, payment details, device and
            usage information, and trading activities through the Alpaca API.
            <h2 className="title-24 mt-4">Use of Personal Data</h2>
            Your personal data are used for registration and user account
            management, service provision, marketing communications, customer
            support, service improvement, and analytics based on the performance
            of contracts, legal obligations, consent, or our legitimate
            interests.
            <h2 className="title-24 mt-4">Data Storage and Security</h2>
            Personal data are stored in encrypted databases with organizational
            and technical measures in place to prevent unauthorized access and
            ensure data security.
            <h2 className="title-24 mt-4">Data Retention</h2>
            We process and retain personal data for durations necessary for
            their intended purposes or as legally required, after which they are
            deleted or anonymized.
            <h2 className="title-24 mt-4">
              Data Sharing and International Transfers
            </h2>
            Personal data may be shared with third-party service providers,
            including the trading platform provider Alpaca for simulated trading
            activities. Data may be transferred internationally in compliance
            with applicable laws and with proper safeguards.
            <h2 className="title-24 mt-4">Section 8</h2>
            Intellectual Property Rights The Service and its original content,
            features, and functionality are and will remain the exclusive
            property of Next Funded and its licensors.
            <h2 className="title-24 mt-4">Your Rights</h2>
            You have rights to access, rectify, erase, restrict, and port your
            data, to object to processing, withdraw consent, and not be subject
            to automated decision-making. Requests can be made via
            support@nextfunded.com.
            <h2 className="title-24 mt-4">Changes to the Policy</h2>
            We reserve the right to update this policy as necessary and will
            notify you of any changes. The current version is always available
            in your Client Section.
            <br />
            This comprehensive approach ensures your rights and privacy are
            fully respected and protected while engaging with our services.
            Here's a continuation and deeper exploration of some key points
            outlined in our privacy framework.
            <h2 className="title-24 mt-4">Data Protection and Compliance</h2>
            NextFunded LLC is committed to the highest standards of data
            protection and complies with applicable data protection laws and
            regulations. Our policies and procedures are designed to protect
            your personal information from unauthorized access, alteration,
            disclosure, or destruction. We continually assess and update our
            security measures in response to emerging threats and technological
            advancements.
            <h2 className="title-24 mt-4">International Data Transfers</h2>
            Given the global nature of our services, personal data may be
            transferred to, and processed in, countries outside of your country
            of residence. These international transfers are carried out in
            compliance with legal requirements, using standard contractual
            clauses approved by relevant regulatory bodies or other legally
            recognized mechanisms to ensure the protection of your personal
            data.
            <h2 className="title-24 mt-4">Use of Third-Party Services</h2>
            Our services may integrate with, or be complemented by, services
            provided by third parties, including but not limited to the Alpaca
            API for trading simulation. While we ensure that our partners adhere
            to compatible standards of data protection, we encourage you to
            review the privacy policies of any third-party service providers to
            understand their practices and how they may process your personal
            data.
            <h2 className="title-24 mt-4">Marketing Communications</h2>
            With your consent, or where we have a legitimate interest, we may
            use your personal data to send you newsletters, promotions, or other
            marketing communications regarding our products and services. You
            have the right to withdraw your consent or object to this processing
            at any time, easily opting out of receiving these communications.
            <h2 className="title-24 mt-4">Exercising Your Rights</h2>
            You have comprehensive rights under data protection law, including
            the right to access, correct, delete, or transfer your personal
            data, as well as the right to restrict its processing or object to
            the same. To exercise these rights, or if you have any questions or
            concerns about how we handle your personal data, please contact us
            at support@nextfunded.com. We aim to respond to all requests in a
            timely and respectful manner.
            <h2 className="title-24 mt-4">Updates to This Policy</h2>
            This Privacy Policy may be updated periodically to reflect changes
            in our personal data practices or relevant laws. We will post a
            prominent notice on our website to notify you of any significant
            changes and indicate at the top of the policy when it was last
            updated. We encourage you to review this policy periodically to stay
            informed about how we are protecting your personal data.
            <br />
            By using NextFunded LLC's services, you acknowledge that you have
            read and understood this Privacy Policy. Your continued use of our
            services after any changes or updates signifies your acceptance of
            the revised policy. If you do not agree to the terms of this policy,
            please do not use our services.
            <h2 className="title-24 mt-4">
              Disclaimer: Services Not Available to US and Canadian Citizens
            </h2>
            Please be advised that NextFunded LLC's services, including all
            trading activities, simulations, and any other offerings, are not
            available to citizens or residents of the United States of America
            and Canada. This exclusion applies regardless of the platform or
            medium through which the services are accessed or offered.
            <br />
            By using our services, you affirm that you are not a citizen or
            resident of these countries. NextFunded LLC reserves the right to
            verify the eligibility of its users and may restrict access to its
            services if necessary to comply with applicable laws and
            regulations.
            <br />
            This policy is in strict adherence to regulatory requirements and is
            intended to prevent unauthorized access to our services from the
            specified regions. We appreciate your understanding and cooperation.
            <h2 className="title-24 mt-4">Contact Us</h2>
            If you have any questions about this Privacy Policy or our data
            practices, please contact us at: Email: support@nextfunded.io
            Address: NextFunded LLC, 254 Chapman Rd, Ste 208 #15841, Newark,
            Delaware 19702, USA Thank you for trusting NextFunded LLC with your
            trading simulation needs. We are committed to delivering exceptional
            services while respecting your privacy and protecting your personal
            data.
            <br />
          </p>
        </div>
      </div>
      <Foot />
    </>
  );
};
