import React from "react";

export const PaymentFailed = () => {

    return (
        <div>
        <div>
          <section className="section-100 section-full">
            <div className="custom-container">
              <div className="row justify-content-center align-items-center">
                <div className="col-md-5 text-center">
                  <div className="mb-30">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={80} height={80} fill="rgba(255,0,0,1)"><path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11 15H13V17H11V15ZM11 7H13V13H11V7Z" data-aos="fade-in" data-aos-easing="linear" data-aos-anchor-placement="top-bottom" data-aos-delay={300} /></svg>
                  </div>
                  <h2 className="title-50 white mb-20" data-aos="fade-in" data-aos-easing="linear" data-aos-anchor-placement="top-bottom" data-aos-delay={400}>Payment failed</h2>
                  <p className="text-regular white mb-50 " data-aos="fade-in" data-aos-easing="linear" data-aos-anchor-placement="top-bottom" data-aos-delay={500}>Something went wrong, please try again</p>
                  <a href="/challenge" className="blue-btn d-block text-center mb-30 w-100" data-aos="fade-in" data-aos-easing="linear" data-aos-anchor-placement="top-bottom" data-aos-delay={700}>Try again</a>
                </div>
              </div>
            </div>
          </section>
          {/*start trading journey*/}
        </div>
        {/* Sign in modal */}
        <div className="modal fade " id="attention" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content text-center" style={{borderRadius: '30px'}}>
              <div className="modal-header text-end">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body">
                <h2 className="title-50 red">Attention !</h2>
                <p className="text-regular white mb-30">You are about to leave the challenge registration process. If you return to the homepage, any entered information may not be saved. Are you sure you want to leave?</p>
                <button  className="blue-btn w-100 d-block mb-10" data-bs-dismiss="modal" aria-label="Close">Continue registration</button>
                <a className="a-link" href="/">Quit</a>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
}