import React, { useState } from "react";
import { Offcanvas, Row, Col } from "react-bootstrap";
import { Asset } from "../../../interfaces/asset";
import { useAssets } from "../../../hooks/Assets/useAssets";

export const AssetSelectorOffcanvas = ({ assets, setAssetsSelected }) => {
  const [show, setShow] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { loadingAssets } = useAssets();
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleAssetSelected = (asset: Asset) => {
    setAssetsSelected(asset);
    handleClose();
  };

  // Liste des symboles d'assets prioritaires à afficher en premier
  const priorityAssetsSymbols = [
    "BTC/USD",
    "GOOGL",
    "ETH/USD",
    "AMZN",
    "TSLA",
    "AAPL",
  ];

  const sortAssetsWithPriority = (
    assets: Asset[],
    prioritySymbols: string[]
  ) => {
    const assetsCopy = [...assets];
    assetsCopy.sort((a, b) => {
      const aPriority = prioritySymbols.includes(a.symbol) ? -1 : 0;
      const bPriority = prioritySymbols.includes(b.symbol) ? -1 : 0;
      return aPriority - bPriority;
    });

    return assetsCopy;
  };

  // Logique de filtrage des assets
  const filteredAssets =
    searchTerm.length < 3
      ? sortAssetsWithPriority(assets, priorityAssetsSymbols).slice(0, 40)
      : assets.filter((asset: Asset) =>
          asset.symbol.toLowerCase().includes(searchTerm.toLowerCase())
        );

  return (
    <>
      <a className="btn-asset-selector title-24 white" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="18"
          height="18"
          fill="rgba(255,255,255,1)"
        >
          <path d="M3 4H21V6H3V4ZM3 11H21V13H3V11ZM3 18H21V20H3V18Z" />
        </svg>{" "}
          Asset
      </a>

      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="start"
        className="offcanvas-asset-selector"
      >
        <Offcanvas.Header closeButton />
        <Offcanvas.Body>
          {loadingAssets ? (
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            <>
              <div className="d-flex align-items-center gx-2 justify-content-between mb-3">
                <input
                  className="input-blue"
                  type="text"
                  placeholder="Search your asset"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </div>
              <div className="p-20-s">
                <Row className="mb-2">
                  <Col className="text-super-mini white-50">MARKET</Col>
                </Row>
              </div>
              {filteredAssets.map((asset) => (
                <button
                  key={asset.id}
                  className="dashboard-asset-choice mb-10 w-100"
                  onClick={() => handleAssetSelected(asset)}
                >
                  <div className="row align-items-center justify-content-center d-flex my-auto mx-auto">
                    <div className="col-12">
                      <p className="title-16 mb-0 white text-start">
                        {asset.symbol}
                      </p>
                    </div>
                  </div>
                </button>
              ))}
              {filteredAssets.length === 0 && searchTerm && (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "100%" }}
                >
                  <h5 className="title-16 text-white">
                    No assets match your search
                  </h5>
                </div>
              )}
            </>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default AssetSelectorOffcanvas;
