import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../../hooks/Auth/AuthProvider";
import BicolorImg from "../../media/logo/bicolor.png";
import AssetSelectorOffcanvas from "./Modal/assetSelectorOffCanvas";
import { Asset, AssetBeforeInit } from "../../interfaces/asset";
import { useRefreshLong } from "../../hooks/Refresh/useRefresh";
import { useOrders } from "../../hooks/Order/useOrder";
import { usePositions } from "../../hooks/Positions/usePositions";
import { useAssets } from "../../hooks/Assets/useAssets";
import { toast } from "react-toastify";
import { UserStatus } from "../../interfaces/user";
import { useWebSocket } from "../../hooks/Websocket/WebSocket";
import { Button, Modal } from "react-bootstrap";

export const Dashboard = () => {
  const {
    fetchCurrentPriceForCrypto,
    fetchCurrentPriceForAsset,
    currentPrice,
    assets,
    changePrice,
    lowPrice,
    highPrice,
  } = useAssets();
  const { orders, createOrder, loadingCreateOrder, closeOrder } = useOrders();
  const { refreshPositions } = useWebSocket();
  const { positions, closePosition, getAllPositions } = usePositions();
  const { currentUser, logout } = useAuth();
  const [assetSelected, setAssetSelected] = useState<Asset | AssetBeforeInit>({
    class: "us_equity",
    easy_to_borrow: true,
    exchange: "NASDAQ",
    fractionable: true,
    id: "b0b6dd9d-8b9b-48a9-ba46-b9d54906e415",
    maintenance_margin_requirement: 30,
    marginable: true,
    name: "Apple Inc. Common Stock",
    shortable: true,
    status: "active",
    symbol: "AAPL",
    tradable: true,
  });
  const [loading, setLoading] = useState(false);
  const [investment, setInvestment] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [leverage, setLeverage] = useState(1);
  const [meansOfPayment, setMeansOfPayment] = useState("buy");
  const [activeTabFormeansOfPayment, setActiveTabFormeansOfPayment] =
    useState("long-tab");
  const [timeInForce, setTimeInForce] = useState("gtc");
  const [orderType, setOrderType] = useState("market");
  const [activeTab, setActiveTab] = useState("market-long-tab");
  const [estimatedCost, setEstimatedCost] = useState(0);
  const [limitPrice, setLimitPrice] = useState(null);
  const [stopPrice, setStopPrice] = useState(null);
  const [stopLoss, setStopLoss] = useState("");
  const [takeProfit, setTakeProfit] = useState("");
  const [dashboardError, setDashboardError] = useState("");
  const refresh = useRefreshLong();
  const [lastModified, setLastModified] = useState(null);
  const [profit, setProfit] = useState(0);
  const [balance, setBalance] = useState(0);
  const [target, setTarget] = useState(0);
  const [funding, setFunding] = useState(0);
  const [showWithdrawModal, setShowWithdrawModal] = React.useState(false);
  const [showAssuranceModal, setShowAssuranceModal] = React.useState(false);
  const [show, setShow] = useState(true);
  const [showLimitLossModal, setShowLimitLossModal] = useState(false);
  const [showPhaseCompletedModal, setShowPhaseCompletedModal] = useState(false);
  const [amount, setAmount] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [isError, setIsError] = useState(false);
  const [cash, setCash] = useState(0);

  useEffect(() => {
    getAllPositions();
    console.log(changePrice);
  }, [refreshPositions, currentUser]);

  useEffect(() => {
    if (currentUser && currentUser.challenge) {
      if (currentUser.challengeStep === 0) {
        const initialTarget = currentUser.challenge.targets[0];
        setTarget(initialTarget);
      } else if (currentUser.challengeStep === 1) {
        const nextTarget = currentUser.challenge.targets[1];
        setTarget(nextTarget);
      }
    }

    if (currentUser.status === UserStatus.CLOSED) {
      setShowLimitLossModal(true);
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser.challengeStep === 0) {
      const newFunding = target * (currentUser.challenge.funding / 100);
      setFunding(newFunding);
    } else if (currentUser.challengeStep === 1) {
      const newTarget =
        currentUser.challenge.targets[1] + currentUser.challenge.targets[0];
      const newFunding = newTarget * (currentUser.challenge.funding / 100);
      setFunding(newFunding);
    }
  }, [target]);

  // Fontion pour changer le leverage
  const handleLeverageClick = (newLeverage) => {
    setLeverage(newLeverage);
  };

  // Fontion pour changer le type d'order
  const handleTabClick = (newOrderType: string, tabId: string) => {
    setOrderType(newOrderType);
    setActiveTab(tabId);
  };

  // Fontion pour changer le meansOfPayment
  const handleTabClickPayment = (paymentType: string, tabId: string) => {
    setMeansOfPayment(paymentType);
    setActiveTabFormeansOfPayment(tabId);
  };
  // Récupère le prix actuel de l'asset sélectionné toutes les 30sec
  useEffect(() => {
    if (!assetSelected) return;
    if (assetSelected.class === "crypto") {
      fetchCurrentPriceForCrypto(assetSelected);
    } else if (assetSelected.class === "us_equity") {
      fetchCurrentPriceForAsset(assetSelected);
    }
  }, [assetSelected, refresh]);

  const tradingViewWidgetRef = useRef(null);

  useEffect(() => {
    if (tradingViewWidgetRef.current) {
      tradingViewWidgetRef.current.innerHTML = "";
    }
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
    script.async = true;
    script.innerHTML = JSON.stringify({
      autosize: true,
      symbol: assetSelected.symbol,
      interval: "D",
      timezone: "Etc/UTC",
      theme: "dark",
      style: "1",
      locale: "en",
      enable_publishing: false,
      allow_symbol_change: true,
      calendar: false,
      support_host: "https://www.tradingview.com",
    });
    const timeoutId = setTimeout(() => {
      if (tradingViewWidgetRef.current) {
        tradingViewWidgetRef.current.appendChild(script);
      }
    }, 100);
    return () => clearTimeout(timeoutId);
  }, [assetSelected.symbol]); // Ce useEffect est déclenché chaque fois que le symbole sélectionné change

  // Reset les inputs si changement d'ordre
  useEffect(() => {
    setQuantity(0);
    setLimitPrice(0);
    setStopLoss("");
    setTakeProfit("");
    setInvestment(0);
    setDashboardError("");
    setStopPrice("");
    setLimitPrice("");
    if (assetSelected && assetSelected.class === "crypto") {
      setTimeInForce("gtc");
    }
  }, [orderType, assetSelected, loadingCreateOrder]);

  useEffect(() => {
    const profit =
      parseFloat(currentUser.alpacaAccount.equity) -
      parseFloat(currentUser.alpacaAccount.last_equity);
    setProfit(profit);

    const initialAccountValue = 1000000;
    const ratio =
      (parseFloat(currentUser.alpacaAccount.equity) - initialAccountValue) /
      initialAccountValue;
    const balance =
      currentUser.challenge.funding + currentUser.challenge.funding * ratio;
    setBalance(balance + profit);

    const cashAvailable =
      balance - Number(currentUser.alpacaAccount.position_market_value);
    setCash(cashAvailable);
  }, [currentUser, refreshPositions]);

  // Pour calculer le coût estimé si l'ordre est de type limit
  useEffect(() => {
    if (orderType === "limit") {
      setEstimatedCost(limitPrice * Number(quantity));
    }
  }, [limitPrice, quantity, orderType]);

  useEffect(() => {
    if (
      investment > 0 &&
      lastModified === "investment" &&
      orderType === "market"
    ) {
      const newQuantity = parseFloat((investment / currentPrice).toFixed(2));
      setQuantity(newQuantity);
    } else if (investment <= 0 && lastModified === "investment") {
      setQuantity(0);
    }
  }, [investment, currentPrice, orderType, lastModified]);

  useEffect(() => {
    if (
      Number(quantity) > 0 &&
      lastModified === "quantity" &&
      orderType === "market"
    ) {
      const newInvestment = parseFloat(
        (Number(quantity) * currentPrice).toFixed(2)
      );
      setInvestment(newInvestment);
    } else if (
      Number(quantity) <= 0 &&
      lastModified === "quantity" &&
      orderType === "market"
    ) {
      setInvestment(0);
    }
  }, [quantity, currentPrice, orderType, lastModified]);

  // Fonction pour gérer les input pour accepter uniquement les chiffres et le point
  const handleInputChange = (e, setterFunction) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      setterFunction(value);
    }
  };

  const handleInvestmentChange = (e) => {
    const newInvestment = e.target.value;
    if (/^[0-9]*\.?[0-9]*$/.test(newInvestment)) {
      setInvestment(newInvestment);
    }
    setLastModified("investment");
  };

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    if (!isNaN(Number(inputValue)) && inputValue.trim() !== "") {
      if (/^(\d+(\.\d*)?|\.\d+)$/.test(inputValue)) {
        setQuantity(Number(inputValue));
      } else {
        toast.error("Invalid number format");
      }
    } else {
      setQuantity(0);
    }
    setLastModified("quantity");
  };

  // Fonction pour ouvrir un ordre
  const handleOpenOrder = async () => {
    const buyingPower = parseInt(currentUser.alpacaAccount.buying_power);

    if (leverage * investment > buyingPower) {
      setDashboardError("Insufficient buying power, reduce your leverage");
      return;
    }
    if (!assetSelected) {
      setDashboardError("Please choose an asset to continue");
      return;
    }
    if (orderType === "market" && !investment && !quantity) {
      toast.error("Please enter a valid quantity and investment");
      return;
    }
    if (orderType === "limit" && !limitPrice && !quantity) {
      toast.error("Please enter a valid quantity and limit price");
      return;
    }
    if (
      meansOfPayment === "buy" &&
      stopLoss &&
      currentPrice < Number(stopLoss)
    ) {
      toast.error("The stop loss must be lower than the current price");
      return;
    }
    if (
      meansOfPayment === "sell" &&
      stopLoss &&
      currentPrice > Number(stopLoss)
    ) {
      toast.error("The stop loss must be greater than the current price");
      return;
    }
    if (
      meansOfPayment === "buy" &&
      orderType === "limit" &&
      limitPrice < currentPrice
    ) {
      toast.error("The limit price must be lower than the current price");
      return;
    }
    if (
      meansOfPayment === "sell" &&
      orderType === "limit" &&
      limitPrice > currentPrice
    ) {
      toast.error("The limit price must be higher than the current price");
      return;
    }
    if (cash < Number(quantity) * currentPrice) {
      toast.error("Insufficient cash");
      return;
    }
    if (
      (orderType !== "market" || orderType === "market") &&
      timeInForce !== "day" &&
      assetSelected.class === "us_equity" &&
      Number(quantity) % 1 !== 0
    ) {
      toast.error(
        "Number of shares to trade. Can be split only for market in 'DAY' order type."
      );
      return;
    }
    const order: any = {
      type: orderType,
      side: meansOfPayment,
      symbol: assetSelected?.symbol,
      qty: quantity.toString(),
      assetClass: assetSelected.class,
      leverage,
      timeInForce,
    };
    if (orderType === "limit" && limitPrice) {
      order.limit_price = limitPrice;
    }
    if (orderType === "limit" && stopPrice) {
      order.stop_price = stopPrice;
    }
    if (stopLoss) {
      order.stopLoss = stopLoss.toString();
    }
    if (takeProfit) {
      order.takeProfit = takeProfit.toString();
    }
    createOrder(order, assetSelected.class);
  };

  const handleNavigate = () => {
    window.location.href = "/signup";
  };

  return (
    <>
      <Modal
        style={{ borderRadius: "30px" }}
        show={show}
        onHide={() => setShow(false)}
        centered
        aria-labelledby="newsletter-modal-title"
        className="modal fade"
      >
        <Modal.Header closeButton />
        <Modal.Body className="text-center modal-body">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="72"
            height="72"
            viewBox="0 0 72 72"
            fill="none"
            className="mb-3"
          >
            <path
              d="M36 66C19.431 66 6 52.569 6 36C6 19.431 19.431 6 36 6C52.569 6 66 19.431 66 36C66 52.569 52.569 66 36 66ZM36 60C42.3652 60 48.4697 57.4714 52.9706 52.9706C57.4714 48.4697 60 42.3652 60 36C60 29.6348 57.4714 23.5303 52.9706 19.0294C48.4697 14.5286 42.3652 12 36 12C29.6348 12 23.5303 14.5286 19.0294 19.0294C14.5286 23.5303 12 29.6348 12 36C12 42.3652 14.5286 48.4697 19.0294 52.9706C23.5303 57.4714 29.6348 60 36 60ZM39 36H51V42H33V21H39V36Z"
              fill="#F4A342"
            />
          </svg>
          <h2 className="title-24 white mb-30">
            Hi trader and welcome on your nextfunded challenge!
          </h2>
          <h3 className="title-16 white-70">
            Unfortunately MT4 and MT5 accounts have been migrated to nextfunded
            custom trading dashboard as MT4 and MT5 accounts are still under
            maintenance. We apologize for the inconvenience, once maintenance is
            over all your account data will be migrated back to MT4 or MT5.{" "}
            <br /> <br />
            We wish you the best of luck in your trading journey
          </h3>
        </Modal.Body>
      </Modal>
      <div className="body-black">
        {/*Navbar Desktop*/}
        <nav className="fixed-top d-none d-md-block">
          <div className="nav-dashboard-container">
            <div className="navbarz-dashboard row align-items-center">
              <div className="col-3">
                <div className="row">
                  <div className="col-4">
                    <h5 className="text-mini white-70 d-inline-block mb-0">
                      Balance
                    </h5>
                    <h4 className="title-16 white mb-0">
                      $
                      {balance.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </h4>
                  </div>
                  <div className="col-4">
                    <h5 className="text-mini white-70 d-inline-block mb-0">
                      24h PNL
                    </h5>
                    <h4
                      className={`title-16 white mb-0 ${
                        Number(currentUser.alpacaAccount.equity) -
                          Number(currentUser.alpacaAccount.last_equity) >=
                        0
                          ? "green"
                          : "red"
                      }`}
                    >
                      {(() => {
                        const percentageChange =
                          ((Number(currentUser.alpacaAccount.equity) -
                            Number(currentUser.alpacaAccount.last_equity)) /
                            Number(currentUser.alpacaAccount.last_equity)) *
                          100;
                        if (
                          percentageChange < 0 &&
                          Math.abs(percentageChange) < 1
                        ) {
                          return "<1%";
                        } else if (
                          percentageChange > 0 &&
                          percentageChange < 0.1
                        ) {
                          return "<0.1%";
                        } else {
                          return `${
                            percentageChange >= 0 ? "+" : ""
                          }${percentageChange.toFixed(3)}%`;
                        }
                      })()}
                    </h4>
                  </div>
                  <div className="col-4">
                    <h5 className="text-mini white-70 d-inline-block mb-0">
                      24h Profit
                    </h5>
                    <h4
                      className={`title-16 mb-0 ${
                        profit > 0 ? "green" : "red"
                      }`}
                    >
                      {profit > 0
                        ? `$ +${Number(profit).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}`
                        : `$ ${Number(profit).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}`}
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-6 text-center">
                <img
                  src={BicolorImg}
                  style={{ width: "150px", height: "auto" }}
                  alt=""
                />
              </div>
              <div className="col-3 text-end">
                <a
                  href="#"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRight"
                  aria-controls="offcanvasRight"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={32}
                    height={32}
                    fill="rgba(255,255,255,1)"
                  >
                    <path d="M16 18V20H5V18H16ZM21 11V13H3V11H21ZM19 4V6H8V4H19Z" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </nav>
        <div
          className="nav-mobile offcanvas offcanvas-end"
          tabIndex={-1}
          id="offcanvasRight"
          aria-labelledby="offcanvasRightLabel"
        >
          <div className="offcanvas-header " style={{ marginTop: 10 }}>
            <div className="d-flex justify-content-between align-items-center w-100">
              {/* Conteneur pour l'image, l'email et le bouton Edit */}
              <div className="d-flex align-items-center">
                {/* Image */}
                <span
                  className="profile-picture-dashboard title-16 white"
                  style={{}}
                >
                  T
                </span>
                {/* Email et Edit */}
                <div className="ms-2">
                  {/* ms-2 ajoute un peu d'espace à gauche */}
                  <span className="title-16 white d-block">
                    {currentUser.email}
                  </span>
                </div>
              </div>
              {/* Bouton de fermeture */}
              <button
                type="button"
                className="btn-close text-reset white"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              />
            </div>
          </div>
          <div
            className="offcanvas-body d-flex flex-column justify-content-between"
            style={{ height: "100%", padding: "0px 20px" }}
          >
            <div className="row gx-2">
              {/* Challenge */}
              <div className="col-12 mb-10">
                <div
                  className="challenge-card-dashboard"
                  style={{
                    backgroundColor: "rgba(255,255,255,0.1)",
                    padding: 15,
                    borderRadius: 10,
                  }}
                >
                  <div className="row">
                    <div className="col-6">
                      <p className="text-super-mini white mb-0">Challenge</p>
                      <h3 className="title-24 white">Premium</h3>
                    </div>
                  </div>
                  <p className="text-regular white-50">
                    Target : {target}% | {funding}$
                  </p>
                  <div
                    className="progress"
                    role="progressbar"
                    aria-label="Example with label"
                    aria-valuenow={
                      currentUser.challengeStep === 0
                        ? 0
                        : currentUser.challengeStep === 1
                        ? 50
                        : currentUser.status === UserStatus.FUNDED
                        ? 100
                        : 0
                    }
                    aria-valuemin={0}
                    aria-valuemax={100}
                  >
                    <div
                      className="progress-bar"
                      style={{
                        width: `${
                          currentUser.status === UserStatus.FUNDED
                            ? 100
                            : currentUser.challengeStep === 0
                            ? 0
                            : currentUser.challengeStep === 1
                            ? 50
                            : 100
                        }%`,
                      }}
                    />
                  </div>
                </div>
              </div>
              {/* Balance */}
              <div className="col-12 mb-10">
                <div
                  className="challenge-card-dashboard"
                  style={{
                    backgroundColor: "rgba(255,255,255,0.1)",
                    padding: 15,
                    borderRadius: 10,
                  }}
                >
                  <div className="row">
                    <div className="col-6">
                      <p className="text-super-mini white mb-0">All time</p>
                      <h3 className="title-16 mb-30 white">Balance</h3>
                    </div>
                    <div className="col-6 text-end">
                      <span
                        className={`text-super-mini ${
                          balance - currentUser.challenge.funding >= 0
                            ? "green-pill"
                            : "red-pill"
                        }`}
                      >
                        {(() => {
                          const percentageChange =
                            ((balance - currentUser.challenge.funding) /
                              currentUser.challenge.funding) *
                            100;
                          if (
                            percentageChange < 0 &&
                            Math.abs(percentageChange) < 1
                          ) {
                            return "<1%";
                          } else if (
                            percentageChange > 0 &&
                            percentageChange < 0.009
                          ) {
                            return "<0.1%";
                          } else if (
                            percentageChange > 0 &&
                            percentageChange < 0.001
                          ) {
                            return "<0.001%";
                          } else {
                            return `${
                              percentageChange >= 0 ? "+" : ""
                            }${percentageChange.toFixed(3)}%`;
                          }
                        })()}
                      </span>
                    </div>
                    <h3 className="title-24 white">
                      $
                      {balance.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </h3>
                    <div className="col-4 text-end">
                      {currentUser.status === UserStatus.FUNDED && (
                        <a
                          className="nav-button-transparent"
                          href="/withdraw"
                          style={{ zIndex: 999999 }}
                        >
                          Withdraw
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* 24h PNL */}
              <div className="col-6 mb-10">
                <div
                  className="challenge-card-dashboard"
                  style={{
                    backgroundColor: "rgba(255,255,255,0.1)",
                    padding: 15,
                    borderRadius: 10,
                  }}
                >
                  <div className="row">
                    <div className="col-6">
                      <p className="text-super-mini white mb-0">24h</p>
                      <h3 className="title-16 mb-30 white">PNL</h3>
                    </div>
                    <div className="col-6">
                      <span
                        className={`text-super-mini ${
                          Number(currentUser.alpacaAccount.equity) -
                            Number(currentUser.alpacaAccount.last_equity) >=
                          0
                            ? "green-pill"
                            : "red-pill"
                        }`}
                      >
                        {(() => {
                          const percentageChange =
                            ((Number(currentUser.alpacaAccount.equity) -
                              Number(currentUser.alpacaAccount.last_equity)) /
                              Number(currentUser.alpacaAccount.last_equity)) *
                            100;
                          if (
                            percentageChange < 0 &&
                            Math.abs(percentageChange) < 1
                          ) {
                            return "<1%";
                          } else if (
                            percentageChange > 0 &&
                            percentageChange < 0.009
                          ) {
                            return "<0.1%";
                          } else if (
                            percentageChange > 0 &&
                            percentageChange < 0.001
                          ) {
                            return "<0.001%";
                          } else {
                            return `${
                              percentageChange >= 0 ? "+" : ""
                            }${percentageChange.toFixed(3)}%`;
                          }
                        })()}
                      </span>
                    </div>
                    <h3
                      className={`title-24 ${
                        Number(currentUser.alpacaAccount.equity) -
                          Number(currentUser.alpacaAccount.last_equity) >=
                        0
                          ? "green"
                          : "red"
                      }`}
                    >
                      $
                      {(
                        Number(currentUser.alpacaAccount.equity) -
                        Number(currentUser.alpacaAccount.last_equity)
                      ).toFixed(2)}
                    </h3>
                  </div>
                </div>
              </div>
              {/* All time PNL */}
              <div className="col-6 mb-10">
                <div
                  className="challenge-card-dashboard"
                  style={{
                    backgroundColor: "rgba(255,255,255,0.1)",
                    padding: 15,
                    borderRadius: 10,
                  }}
                >
                  <div className="row">
                    <div className="col-6">
                      <p className="text-super-mini white mb-0">All time</p>
                      <h3 className="title-16 mb-30 white">PNL</h3>
                    </div>
                    <div className="col-6">
                      <span
                        className={`text-super-mini ${
                          balance - currentUser.challenge.funding < 0
                            ? "red-pill"
                            : "green-pill"
                        }`}
                      >
                        {(() => {
                          const percentageChange =
                            ((balance - currentUser.challenge.funding) /
                              currentUser.challenge.funding) *
                            100;
                          if (
                            percentageChange < 0 &&
                            Math.abs(percentageChange) < 1
                          ) {
                            return "<1%";
                          } else if (
                            percentageChange > 0 &&
                            percentageChange < 0.1
                          ) {
                            return "<0.1%";
                          } else {
                            return `${
                              percentageChange >= 0 ? "+" : ""
                            }${percentageChange.toFixed(3)}%`;
                          }
                        })()}
                      </span>
                    </div>
                    <h3
                      className={`title-24 ${
                        balance - currentUser.challenge.funding < 0
                          ? "red"
                          : "green"
                      }`}
                    >
                      ${(balance - currentUser.challenge.funding).toFixed(2)}
                    </h3>
                  </div>
                </div>
              </div>
              {/* Total trades */}
              <div className="col-6 mb-10">
                <div
                  className="challenge-card-dashboard"
                  style={{
                    backgroundColor: "rgba(255,255,255,0.1)",
                    padding: 15,
                    borderRadius: 10,
                  }}
                >
                  <div className="row">
                    <div className="col-6">
                      <p className="text-super-mini white mb-0">Total</p>
                      <h3 className="title-16 mb-30 white">Trades</h3>
                    </div>
                    <h3 className="title-24 green">
                      {currentUser.totalTrades}
                    </h3>
                  </div>
                </div>
              </div>
              {/* Winning trades */}

              {/*Average winning per trade*/}

              {/*Average winning per trade*/}

              {/* Trading Volume */}
              <div className="col-6 mb-10">
                <div
                  className="challenge-card-dashboard"
                  style={{
                    backgroundColor: "rgba(255,255,255,0.1)",
                    padding: 15,
                    borderRadius: 10,
                  }}
                >
                  <div className="row">
                    <div className="col-12">
                      <p className="text-super-mini white mb-0">Trading</p>
                      <h3 className="title-16 mb-30 white">Volume</h3>
                    </div>
                    <h3 className="title-24 white">
                      ${currentUser.tradingVolume}
                    </h3>
                  </div>
                </div>
              </div>
              {/* Most traded Asset */}
            </div>
            <div>
              <hr className="hr-white-50  mb-20 d-flex justify-content-between" />
              <a
                className="a-link m-3"
                href="https://www.instagram.com/next_funded/"
              >
                Instagram
              </a>
              <a
                className="a-link"
                href="https://discord.gg/Sd7XdA5DYQ
"
              >
                Discord
              </a>
            </div>
          </div>
        </div>

        {/* Order History Modal*/}
        <div
          className="modal fade"
          id="orderhistorymodal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-scrollable modal-xl modal-dialog-centered modal-dialog-centered-mobile">
            <div
              className="modal-content"
              style={{ borderRadius: "30px", maxWidth: "100%" }}
            >
              <div className="modal-header p-0 mb-10">
                <h2 className="title-24 white">Orders history</h2>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body p-0">
                <table className="table fixed-thead">
                  <thead className="p-20-s">
                    <tr>
                      <th scope="col">
                        <span className="top-title-mini-extra white">Type</span>
                      </th>
                      <th scope="col">
                        <span className="top-title-mini-extra white">Pair</span>
                      </th>
                      <th scope="col">
                        <span className="top-title-mini-extra white">Size</span>
                      </th>
                      <th scope="col">
                        <span className="top-title-mini-extra white">
                          Value USD
                        </span>
                      </th>
                      <th scope="col">
                        <span className="top-title-mini-extra white">
                          State
                        </span>
                      </th>
                      <th scope="col">
                        <span className="top-title-mini-extra white">
                          Entry
                        </span>
                      </th>
                      <th scope="col">
                        <span className="top-title-mini-extra white">Date</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="scrollable-tbody">
                    {orders
                      .filter(
                        (order) =>
                          order.status === "filled" ||
                          order.status === "canceled"
                      )
                      .map((order, index) => (
                        <tr className="tr-dashboard" key={index}>
                          <th scope="row">
                            <span className="top-title-mini green">
                              {order.type}
                            </span>
                          </th>
                          <td>
                            <span className="top-title-mini white">
                              {order.symbol}
                            </span>
                          </td>
                          <td>
                            <span className="top-title-mini white">
                              {order.filled_qty === "0"
                                ? "-"
                                : order.filled_qty}
                            </span>
                          </td>
                          <td>
                            <span className="top-title-mini white">
                              {Number(order.filled_qty) === 0
                                ? "-"
                                : `$${(
                                    Number(order.filled_qty) *
                                    Number(order.filled_avg_price)
                                  ).toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}`}
                            </span>
                          </td>

                          <td>
                            <span
                              className={`top-title-mini ${
                                order.status === "filled" ? "green" : "red"
                              }`}
                            >
                              {order.status}
                            </span>
                          </td>
                          <td>
                            <span className="top-title-mini white">
                              {order.filled_avg_price === null
                                ? "-"
                                : `$${Number(
                                    order.filled_avg_price
                                  ).toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}`}
                            </span>
                          </td>

                          <td>
                            <span className="top-title-mini white">
                              {(() => {
                                const createdAt = new Date(order.created_at);
                                const now = new Date();
                                const diffMs = +now - +createdAt;
                                const diffSecs = diffMs / 1000;
                                const diffMins = diffSecs / 60;
                                const diffHours = diffMins / 60;
                                const diffDays = diffHours / 24;

                                if (diffMins < 60) {
                                  return `${Math.round(diffMins)} minutes ago`;
                                } else if (diffHours < 24) {
                                  return `${Math.round(diffHours)} hours ago`;
                                } else {
                                  return `${Math.round(diffDays)} days ago`;
                                }
                              })()}
                            </span>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* POSITIONS HISTORY MODAL */}
        <div
          className="modal fade"
          id="positionhistorymodal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-scrollable modal-xl modal-dialog-centered modal-dialog-centered-mobile">
            <div
              className="modal-content"
              style={{ borderRadius: "30px", maxWidth: "100%" }}
            >
              <div className="modal-header p-0 mb-10">
                <h2 className="title-24 white">Positions history</h2>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body p-0">
                {/*Si il est vide
                     <div class="row">
                        <div class="col-12 text-center p-50">
                           <h3 class="title-16 white-70">No history for the moment</h3>
                        </div>
                     </div>
                     
                     */}
                <table className="table fixed-thead">
                  <thead className="p-20-s">
                    <tr>
                      <th scope="col">
                        <span className="top-title-mini-extra white">Type</span>
                      </th>
                      <th scope="col">
                        <span className="top-title-mini-extra white">Pair</span>
                      </th>
                      <th scope="col">
                        <span className="top-title-mini-extra white">Size</span>
                      </th>
                      <th scope="col">
                        <span className="top-title-mini-extra white">
                          Value USD
                        </span>
                      </th>
                      <th scope="col">
                        <span className="top-title-mini-extra white">
                          State
                        </span>
                      </th>
                      <th scope="col">
                        <span className="top-title-mini-extra white">
                          Entry
                        </span>
                      </th>
                      <th scope="col">
                        <span className="top-title-mini-extra white">TP</span>
                      </th>
                      <th scope="col">
                        <span className="top-title-mini-extra white">
                          P/L %
                        </span>
                      </th>
                      <th scope="col">
                        <span className="top-title-mini-extra white">
                          Profit USD
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="scrollable-tbody">
                    <tr className="tr-dashboard">
                      <th scope="row">
                        <span className="top-title-mini green">Long</span>
                      </th>
                      <td>
                        <span className="top-title-mini white">USD/EUR</span>
                      </td>
                      <td>
                        <span className="top-title-mini white">1202</span>
                      </td>
                      <td>
                        <span className="top-title-mini white">$5405.23</span>
                      </td>
                      <td>
                        <span className="top-title-mini green">Filled</span>
                      </td>
                      <td>
                        <span className="top-title-mini white">$1.0812</span>
                      </td>
                      <td>
                        <span className="top-title-mini white">$1.0912</span>
                      </td>
                      <td>
                        <span className="top-title-mini white">10.2%</span>
                      </td>
                      <td className="text-start">
                        <span className="top-title-mini green me-1">
                          $120000.23
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <nav className=" navbarz-dashboard-mobile fixed-top d-block d-md-none">
          <div className="nav-dashboard-container">
            <div className="row align-items-center justify-content-between mx-auto my-auto">
              <div className="col-auto">
                <img
                  src={BicolorImg}
                  style={{ width: "150px", height: "auto" }}
                  alt=""
                />
              </div>
              <div className="col-auto">
                <a
                  href="#"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRight"
                  aria-controls="offcanvasRight"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={32}
                    height={32}
                    fill="rgba(255,255,255,1)"
                  >
                    <path d="M16 18V20H5V18H16ZM21 11V13H3V11H21ZM19 4V6H8V4H19Z" />
                  </svg>
                </a>
                <div
                  className="nav-mobile offcanvas offcanvas-end"
                  tabIndex={-1}
                  id="offcanvasRight"
                  aria-labelledby="offcanvasRightLabel"
                >
                  <div
                    className="offcanvas-header mb-40"
                    style={{ marginTop: "10px" }}
                  >
                    <img
                      src={BicolorImg}
                      className="img-fluid"
                      style={{ width: "150px", height: "auto" }}
                      alt=""
                    />
                    <button
                      type="button"
                      className="btn-close text-reset white"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    />
                  </div>
                  <div
                    className="offcanvas-body d-flex flex-column justify-content-between"
                    style={{ height: "100%" }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </nav>

        {/* Assurance Modal */}
        {/* Limit Loss Reached Modal */}
        <Modal
          show={showLimitLossModal}
          onHide={() => setShowLimitLossModal(false)}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header></Modal.Header>
          <Modal.Body className="text-center">
            <h2 className="title-40 white">Loss limit reached</h2>
            <p className="text-regular white mb-30">
              Unfortunately, you've exceeded the loss limit for your challenge,
              and your current attempt has ended. But don't let this setback
              stop you!
            </p>
            <a href="/signup" className="blue-btn w-100 d-block mb-10">
              Choose a New Challenge
            </a>
            <button
              onClick={() => logout()}
              className="transparent-btn w-100 d-block mb-10"
            >
              Log Out
            </button>
          </Modal.Body>
        </Modal>

        {/* Phase Succeed Reached Modal */}
        <Modal
          show={showPhaseCompletedModal}
          onHide={() => setShowPhaseCompletedModal(false)}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="text-center">
            <div className="mb-20">
              <span className="blue-pill mb-10">Success</span>
            </div>
            <h2 className="title-40 white">Phase Completed!</h2>
            <p className="text-regular white mb-30">
              You've successfully completed this phase of the challenge! You're
              one step closer to achieving your trading goals. Get ready for the
              next phase.
            </p>
          </Modal.Body>
        </Modal>
        <section style={{ padding: "78px 0 70px 0" }}>
          <div className="custom-dashboard-container">
            <div className="row gx-2">
              <div className="col-md-8 align-items-center ">
                <div
                  className="dashboard-card w-100 mb-10"
                  style={{ zIndex: 1029, position: "relative" }}
                >
                  <div className="row">
                    <div
                      className="col-md-4 col-12 d-flex align-items-center"
                      style={{}}
                    >
                      {loading ? (
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        <AssetSelectorOffcanvas
                          setAssetsSelected={setAssetSelected}
                          assets={assets}
                        />
                      )}
                    </div>
                    <div className="col-md-2 col-3 d-flex align-items-center margintopmobile">
                      <div>
                        <h5 className="text-mini white-70 d-inline-block mb-0">
                          Price
                        </h5>
                        <h4 className="title-16 white mb-0">${currentPrice}</h4>
                      </div>
                    </div>
                    <div className="col-md-2 col-3 d-flex align-items-center margintopmobile">
                      <div>
                        <h5 className="text-mini white-70 d-inline-block mb-0">
                          24h change
                        </h5>
                        <h4
                          className={`title-16 mb-0 ${
                            isNaN(changePrice)
                              ? "white"
                              : changePrice < 0
                              ? "red"
                              : "green"
                          }`}
                        >
                          {isNaN(changePrice)
                            ? "-"
                            : changePrice > 0
                            ? `+${changePrice.toFixed(2)}`
                            : changePrice.toFixed(2)}
                          %
                        </h4>
                      </div>
                    </div>
                    <div className="col-md-2 col-3 d-md-block d-flex align-items-center margintopmobile">
                      <div>
                        <h5 className="text-mini white-70 d-inline-block mb-10 mb-0">
                          24h high
                        </h5>
                        <h4 className="title-16 white mb-0">
                          {highPrice === null || isNaN(highPrice)
                            ? "-"
                            : `$${highPrice}`}
                        </h4>
                      </div>
                    </div>
                    <div className="col-md-2 col-3 d-md-block d-flex align-items-center margintopmobile">
                    <div>
                        <h5 className="text-mini white-70 d-inline-block mb-10 mb-0">
                          24h high
                        </h5>
                        <h4 className="title-16 white mb-0">
                          {lowPrice === null || isNaN(lowPrice)
                            ? "-"
                            : `$${lowPrice}`}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="dashboard-card w-100 mb-10"
                  style={{ zIndex: 1028, position: "relative" }}
                >
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-12" style={{ height: "500px" }}>
                          <div
                            className="tradingview-widget-container"
                            ref={tradingViewWidgetRef}
                            style={{ height: "100%", width: "100%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dashboard-card-position d-none d-lg-block w-100 mb-10">
                  <div className="row">
                    <div className="col-6">
                      <h4 className="title-24 white">Positions</h4>
                    </div>
                    <div className="col-6 text-end"></div>
                  </div>
                  <div className="table-responsive">
                    <table className="table fixed-thead">
                      <thead className="p-20-s">
                        <tr>
                          <th scope="col">
                            <span className="top-title-mini-extra white">
                              Type
                            </span>
                          </th>
                          <th scope="col">
                            <span className="top-title-mini-extra white">
                              Pair
                            </span>
                          </th>
                          <th scope="col">
                            <span className="top-title-mini-extra white">
                              Size
                            </span>
                          </th>
                          <th scope="col">
                            <span className="top-title-mini-extra white">
                              Value USD
                            </span>
                          </th>
                          <th scope="col">
                            <span className="top-title-mini-extra white">
                              Entry
                            </span>
                          </th>
                          <th scope="col">
                            <span className="top-title-mini-extra white">
                              P/L %
                            </span>
                          </th>
                          <th scope="col">
                            <span className="top-title-mini-extra white">
                              Profit USD
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="scrollable-tbody">
                        {positions.length === 0 ? (
                          <tr>
                            <td colSpan={9} className="text-center">
                              <div className="p-50">
                                <h3 className="title-16 white-70">
                                  No positions for the moment
                                </h3>
                              </div>
                            </td>
                          </tr>
                        ) : (
                          positions.map((position) => (
                            <tr className="tr-dashboard" key={position.symbol}>
                              <th scope="row">
                                <a
                                  onClick={(e) => {
                                    e.preventDefault();
                                    closePosition(position.symbol);
                                  }}
                                  className="text-decoration-none me-2"
                                  style={{ zIndex: 999999 }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="12"
                                    height="12"
                                    fill="rgba(255,255,255,1)"
                                  >
                                    <path d="M10.5859 12L2.79297 4.20706L4.20718 2.79285L12.0001 10.5857L19.793 2.79285L21.2072 4.20706L13.4143 12L21.2072 19.7928L19.793 21.2071L12.0001 13.4142L4.20718 21.2071L2.79297 19.7928L10.5859 12Z"></path>
                                  </svg>
                                </a>
                                <span className="top-title-mini green">
                                  {position.side}
                                </span>
                              </th>
                              <td>
                                <span className="top-title-mini white">
                                  {position.symbol}
                                </span>
                              </td>
                              <td>
                                <span className="top-title-mini white">
                                  {Number(position.qty).toFixed(2)}
                                </span>
                              </td>
                              <td>
                                <span className="top-title-mini white">
                                  $
                                  {Number(position.market_value).toLocaleString(
                                    "en-US",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                                </span>
                              </td>
                              <td>
                                <span className="top-title-mini white">
                                  $
                                  {Number(
                                    position.avg_entry_price
                                  ).toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </span>
                              </td>
                              <td>
                                <span
                                  className="top-title-mini"
                                  style={{
                                    color:
                                      Number(
                                        position.unrealized_intraday_plpc
                                      ) > 0
                                        ? "green"
                                        : "red",
                                  }}
                                >
                                  {Math.abs(
                                    Number(position.unrealized_intraday_plpc)
                                  ) < 0.0001
                                    ? Number(
                                        position.unrealized_intraday_plpc
                                      ) > 0
                                      ? ">0.01%"
                                      : "<-0.01%"
                                    : `${(
                                        Number(
                                          position.unrealized_intraday_plpc
                                        ) * 100
                                      ).toFixed(2)}%`}
                                </span>
                              </td>
                              <td className="text-start">
                                <span
                                  className="top-title-mini me-1"
                                  style={{
                                    color:
                                      Number(position.market_value) -
                                        Number(position.avg_entry_price) *
                                          Number(position.qty) >
                                      0
                                        ? "green"
                                        : "red",
                                  }}
                                >
                                  $
                                  {(
                                    Number(position.market_value) -
                                    Number(position.avg_entry_price) *
                                      Number(position.qty)
                                  ).toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </span>
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {/*ORDERS DESKTOP*/}
                <div className="dashboard-card-position d-none d-lg-block w-100 mb-10">
                  <div className="row">
                    <div className="col-6">
                      <h4 className="title-24 white">Orders</h4>
                    </div>
                    <div className="col-6 text-end">
                      <a
                        className="nav-button-transparent"
                        data-bs-toggle="modal"
                        data-bs-target="#orderhistorymodal"
                        style={{ zIndex: 999999 }}
                      >
                        History
                      </a>
                    </div>
                  </div>
                  {orders.length === 0 ? (
                    <div className="row">
                      <div className="col-12 text-center p-50">
                        <h3 className="title-16 white-70">
                          No orders for the moment
                        </h3>
                      </div>
                    </div>
                  ) : (
                    <table className="table fixed-thead">
                      <thead className="p-20-s">
                        <tr>
                          <th scope="col">
                            <span className="top-title-mini-extra white">
                              Type
                            </span>
                          </th>
                          <th scope="col">
                            <span className="top-title-mini-extra white">
                              Pair
                            </span>
                          </th>
                          <th scope="col">
                            <span className="top-title-mini-extra white">
                              Size
                            </span>
                          </th>
                          <th scope="col">
                            <span className="top-title-mini-extra white">
                              Value USD
                            </span>
                          </th>
                          <th scope="col">
                            <span className="top-title-mini-extra white">
                              State
                            </span>
                          </th>
                          <th scope="col">
                            <span className="top-title-mini-extra white">
                              Entry
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="scrollable-tbody">
                        {orders
                          .filter(
                            (order) =>
                              order.status !== "filled" &&
                              order.status !== "canceled"
                          )
                          .map((order, index) => (
                            <tr className="tr-dashboard" key={index}>
                              <th scope="row">
                                <a
                                  onClick={(e) => {
                                    e.preventDefault();
                                    closeOrder(order.id);
                                  }}
                                  href="#"
                                  className="text-decoration-none me-2"
                                  style={{ zIndex: 999999, cursor: "pointer" }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width={12}
                                    height={12}
                                    fill="rgba(255,255,255,1)"
                                  >
                                    <path d="M10.5859 12L2.79297 4.20706L4.20718 2.79285L12.0001 10.5857L19.793 2.79285L21.2072 4.20706L13.4143 12L21.2072 19.7928L19.793 21.2071L12.0001 13.4142L4.20718 21.2071L2.79297 19.7928L10.5859 12Z" />
                                  </svg>
                                </a>
                                <span className="top-title-mini green">
                                  {order.type}
                                </span>
                              </th>
                              <td>
                                <span className="top-title-mini white">
                                  {order.symbol}
                                </span>
                              </td>
                              <td>
                                <span className="top-title-mini white">
                                  {order.qty}
                                </span>
                              </td>
                              <td>
                                <span className="top-title-mini white">-</span>
                              </td>
                              <td>
                                <span
                                  className={`top-title-mini ${
                                    order.status === "new" ? "orange" : "white"
                                  }`}
                                >
                                  {order.status}
                                </span>
                              </td>
                              <td>
                                <span className="top-title-mini white">
                                  {isNaN(parseFloat(order.filled_avg_price))
                                    ? "-"
                                    : parseFloat(
                                        order.filled_avg_price
                                      ).toFixed(2)}
                                </span>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="dashboard-card-trade mb-10">
                  <div className="row">
                    <div className="col-md-12 d-inline-block ">
                      {/* Onglets Principaux: Long et Short */}
                      <ul
                        className="nav nav-tabs mb-10 w-100"
                        id="mainTab"
                        role="tablist"
                      >
                        {[
                          { id: "long-tab", label: "Long" }, // Notez le changement ici pour correspondre à votre question
                          { id: "short-tab", label: "Short" }, // Notez le changement ici
                        ].map((tab) => (
                          <li
                            key={tab.id}
                            className="nav-item w-50"
                            role="presentation"
                          >
                            <button
                              className={`w-100 title-16 ${
                                activeTabFormeansOfPayment === tab.id
                                  ? "button-tab-active"
                                  : "button-tab-inactive"
                              }`}
                              id={tab.id}
                              data-bs-toggle="tab"
                              data-bs-target={`#${tab.id}`}
                              type="button"
                              role="tab"
                              aria-controls={tab.id}
                              aria-selected={
                                activeTabFormeansOfPayment === tab.id
                              }
                              onClick={() =>
                                handleTabClickPayment(
                                  tab.label === "Long" ? "buy" : "sell",
                                  tab.id
                                )
                              }
                            >
                              {tab.label}
                            </button>
                          </li>
                        ))}
                      </ul>

                      {/* Contenu des Onglets Principaux */}
                      <div className="tab-content" id="mainTabContent">
                        {/* Contenu pour l'Onglet Long */}
                        <div
                          className="tab-pane fade show active "
                          id="long"
                          role="tabpanel"
                          aria-labelledby="long-tab"
                        >
                          <ul
                            className="nav nav-tabs mb-20 p-20-s w-100"
                            id="longSubTab"
                            role="tablist"
                          >
                            {[
                              {
                                id: "market-long-tab",
                                label: "Market",
                                orderType: "market",
                              },
                              {
                                id: "limit-long-tab",
                                label: "Limit",
                                orderType: "limit",
                              },
                            ].map((tab) => (
                              <li
                                key={tab.id}
                                className="nav-item w-50"
                                role="presentation"
                              >
                                <button
                                  className={`w-100 a-link-underline ${
                                    activeTab === tab.id
                                      ? "a-link-underline-active"
                                      : ""
                                  }`}
                                  id={tab.id}
                                  data-bs-toggle="tab"
                                  data-bs-target={`#${tab.id.replace(
                                    "-tab",
                                    ""
                                  )}`}
                                  type="button"
                                  role="tab"
                                  aria-controls={tab.id.replace("-tab", "")}
                                  aria-selected={activeTab === tab.id}
                                  onClick={() =>
                                    handleTabClick(tab.orderType, tab.id)
                                  }
                                >
                                  {tab.label}
                                </button>
                              </li>
                            ))}
                          </ul>

                          <div className="tab-content" id="longSubTabContent">
                            <div
                              className="tab-pane fade show active"
                              id="market-long"
                              role="tabpanel"
                              aria-labelledby="market-long-tab"
                            >
                              <div className="p-20-s">
                                <div className="input-group-dashboard mb-20">
                                  <div className="row">
                                    <div className="col-md-7 col-6">
                                      <p className="white-70">Quantity</p>
                                      <input
                                        className="title-24 w-100 input-dashboard"
                                        type="text"
                                        required={orderType === "market"}
                                        placeholder="0.0"
                                        value={
                                          leverage >= 2
                                            ? (quantity * leverage).toString()
                                            : quantity
                                            ? quantity
                                            : ""
                                        }
                                        min={0}
                                        onChange={(e) =>
                                          handleQuantityChange(e)
                                        }
                                      />
                                    </div>

                                    <div className="col-md-5 col-6 d-flex flex-column justify-content-between text-end my-auto">
                                      <p className="white-70">
                                        Leverage :{" "}
                                        <span className="white leverage-display">
                                          {leverage}x
                                        </span>
                                      </p>
                                      <h5 className="title-24 white">
                                        {assetSelected.symbol}
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/*1 input*/}
                              <div className="p-20-s">
                                {/*1 input*/}
                                <div className="input-group-dashboard  mb-10">
                                  <div className="row">
                                    <div className="col-md-7 col-6">
                                      <p className="white-70">Pay</p>
                                      <input
                                        className="title-24 w-100 input-dashboard"
                                        type="text"
                                        required={orderType === "market"}
                                        placeholder="0.0"
                                        value={investment ? investment : ""}
                                        min={0}
                                        onChange={(e) =>
                                          handleInvestmentChange(e)
                                        }
                                      />
                                    </div>
                                    <div className="col-md-5 col-6 d-flex flex-column justify-content-between text-end my-auto">
                                      <p className="white-70">
                                        Cash Available :{" "}
                                        <span className="white">
                                          $
                                          {cash.toLocaleString("en-US", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })}
                                        </span>
                                      </p>
                                      <h5 className="title-24 white ">$</h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/*1 input*/}

                              {/*1 input*/}
                              <div className="p-20-s">
                                <div className="input-group-dashboard mb-20">
                                  <div className="row">
                                    {/* Colonne pour l'input "Stop Loss" */}
                                    <div className="col-md-6 col-6">
                                      <p className="white-70">
                                        Stop loss (optional)
                                      </p>
                                      <input
                                        className="title-24 w-100 input-dashboard"
                                        type="text"
                                        placeholder="0.0"
                                        value={stopLoss}
                                        min="0"
                                        onChange={(e) =>
                                          handleInputChange(e, setStopLoss)
                                        }
                                      />
                                    </div>
                                    {/* Colonne pour l'input "Take Profit" */}
                                    <div className="col-md-6 col-6">
                                      <p className="white-70">
                                        Take profit (optional)
                                      </p>
                                      <input
                                        className="title-24 w-100 input-dashboard"
                                        type="text"
                                        placeholder="0.0"
                                        value={takeProfit}
                                        min="0"
                                        onChange={(e) =>
                                          handleInputChange(e, setTakeProfit)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="tab-pane fade"
                              id="limit-long"
                              role="tabpanel"
                              aria-labelledby="limit-long-tab"
                            >
                              {/* Contenu pour Limit sous Long */}
                              <div className="p-20-s">
                                <div className="input-group-dashboard mb-10">
                                  <div className="row">
                                    <div className="col-md-7 col-6">
                                      <p className="white-70">Quantity</p>
                                      <input
                                        type="text"
                                        className="title-24 w-100 input-dashboard"
                                        placeholder={"0.0"}
                                        required={orderType === "limit"}
                                        value={
                                          leverage >= 2
                                            ? (quantity * leverage).toString()
                                            : quantity
                                        }
                                        min={0}
                                        onChange={(e) =>
                                          handleInputChange(e, setQuantity)
                                        }
                                      />
                                    </div>
                                    <div className="col-md-5 col-6 d-flex flex-column justify-content-between text-end my-auto">
                                      <p className="white-70">
                                        Leverage :{" "}
                                        <span className="white leverage-display">
                                          {leverage}x
                                        </span>
                                      </p>
                                      <h5 className="title-24 white">
                                        {assetSelected.symbol}
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="p-20-s">
                                <div className="row">
                                  {/* Premier input pour le Limit Price */}
                                  <div className="col-md-6 col-6">
                                    <div className="input-group-dashboard mb-10">
                                      <p className="white-70">Limit Price</p>
                                      <input
                                        className="title-24 w-100 input-dashboard"
                                        type="text"
                                        placeholder="0.0"
                                        value={limitPrice > 0 ? limitPrice : ""}
                                        min="0"
                                        required={orderType === "limit"}
                                        onChange={(e) =>
                                          handleInputChange(e, setLimitPrice)
                                        }
                                      />
                                    </div>
                                  </div>

                                  {/* Deuxième input pour le Stop Price */}
                                  <div className="col-md-6 col-6">
                                    <div className="input-group-dashboard mb-10">
                                      <p className="white-70">Stop Price</p>
                                      <input
                                        className="title-24 w-100 input-dashboard"
                                        type="text"
                                        placeholder="0.0"
                                        value={stopPrice > 0 ? stopPrice : ""}
                                        min="0"
                                        required={orderType === "stop"}
                                        onChange={(e) =>
                                          handleInputChange(e, setStopPrice)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/*1 input*/}

                              {/*1 input*/}
                              <div className="p-20-s">
                                <div className="input-group-dashboard mb-20">
                                  <div className="row">
                                    {/* Colonne pour l'input "Stop Loss" */}
                                    <div className="col-md-6 col-6">
                                      <p className="white-70">
                                        Stop loss (optional)
                                      </p>
                                      <input
                                        className="title-24 w-100 input-dashboard"
                                        type="text"
                                        placeholder="0.0"
                                        value={stopLoss}
                                        min="0"
                                        onChange={(e) =>
                                          handleInputChange(e, setStopLoss)
                                        }
                                      />
                                    </div>
                                    {/* Colonne pour l'input "Take Profit" */}
                                    <div className="col-md-6 col-6">
                                      <p className="white-70">
                                        Take profit (optional)
                                      </p>
                                      <input
                                        className="title-24 w-100 input-dashboard"
                                        type="text"
                                        placeholder="0.0"
                                        value={takeProfit}
                                        min="0"
                                        onChange={(e) =>
                                          handleInputChange(e, setTakeProfit)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/*1 input*/}
                              <div className="p-20-s">
                                <div className="input-group-dashboard mb-20">
                                  <div className="row">
                                    <div className="col-md-7 col-6">
                                      <p className="white-70">Estimated Cost</p>
                                      <input
                                        className="title-24 w-100 input-dashboard"
                                        type="string"
                                        placeholder={"0.0"}
                                        value={estimatedCost}
                                        readOnly
                                      />
                                    </div>
                                    <div className="col-md-5 col-6 d-flex flex-column justify-content-between text-end my-auto">
                                      <h5 className="title-24 white">$</h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/*1 input*/}
                            </div>
                          </div>
                        </div>
                        {/* Contenu pour l'Onglet Short */}
                        <div
                          className="tab-pane fade"
                          id="short"
                          role="tabpanel"
                          aria-labelledby="short-tab"
                        >
                          {/* Sous-onglets pour Short */}
                          <ul
                            className="nav nav-tabs mb-20 p-20-s w-100"
                            id="shortSubTab"
                            role="tablist"
                          >
                            <li className="nav-item w-33" role="presentation">
                              <button
                                className="a-link-underline2 a-link-underline-active2 active w-100"
                                id="market-short-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#market-short"
                                type="button"
                                role="tab"
                                aria-controls="market-short"
                                aria-selected="true"
                              >
                                Market
                              </button>
                            </li>
                            <li className="nav-item w-33" role="presentation">
                              <button
                                className="a-link-underline2 w-100"
                                id="limit-short-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#limit-short"
                                type="button"
                                role="tab"
                                aria-controls="limit-short"
                                aria-selected="false"
                              >
                                Limit
                              </button>
                            </li>
                            <li className="nav-item  w-33" role="presentation">
                              <button
                                className="a-link-underline2 w-100"
                                id="tpsl-short-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#tpsl-short"
                                type="button"
                                role="tab"
                                aria-controls="tpsl-short"
                                aria-selected="false"
                              >
                                TP/SL
                              </button>
                            </li>
                          </ul>
                          {/* Contenu des Sous-onglets pour Short */}
                          <div className="tab-content" id="shortSubTabContent">
                            <div
                              className="tab-pane fade show active"
                              id="market-short"
                              role="tabpanel"
                              aria-labelledby="market-short-tab"
                            >
                              <div className="p-20-s">
                                {/*1 input*/}
                                <div className="input-group-dashboard  mb-10">
                                  <div className="row">
                                    <div className="col-md-7 col-6">
                                      <p className="white-70">Pay</p>
                                      <input
                                        className="title-24 w-100 input-dashboard"
                                        type="number"
                                        placeholder={"0.0"}
                                      />
                                    </div>
                                    <div className="col-md-5 col-6 d-flex flex-column justify-content-between text-end my-auto">
                                      <p className="white-70">
                                        Cash Available :{" "}
                                        <span className="white">
                                          {" "}
                                          $
                                          {cash.toLocaleString("en-US", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })}
                                        </span>
                                      </p>
                                      <h5 className="title-24 white">
                                        {assetSelected?.symbol}
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/*1 input*/}
                              {/*1 input*/}
                              <div className="p-20-s">
                                <div className="input-group-dashboard mb-20">
                                  <div className="row">
                                    <div className="col-md-7 col-6">
                                      <p className="white-70">Short</p>
                                      <input
                                        type="number"
                                        className="title-24 w-100 input-dashboard"
                                        placeholder={"0.0"}
                                      />
                                    </div>
                                    <div className="col-md-5 col-6 d-flex flex-column justify-content-between text-end my-auto">
                                      <p className="white-70">
                                        Leverage :{" "}
                                        <span className="white leverage-display">
                                          {leverage}x
                                        </span>
                                      </p>
                                      <h5 className="title-24 white">$</h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/*1 input*/}
                            </div>
                            <div
                              className="tab-pane fade"
                              id="limit-short"
                              role="tabpanel"
                              aria-labelledby="limit-short-tab"
                            >
                              <div className="p-20-s">
                                {/*1 input*/}
                                <div className="input-group-dashboard  mb-10">
                                  <div className="row">
                                    <div className="col-md-7 col-6">
                                      <p className="white-70">Close</p>
                                      <input
                                        className="title-24 w-100 input-dashboard"
                                        type="number"
                                        placeholder={"0.0"}
                                      />
                                    </div>
                                    <div className="col-md-5 col-6 d-flex flex-column justify-content-between text-end my-auto">
                                      <p className="white-70">
                                        Balance :{" "}
                                        <span className="white">
                                          {" "}
                                          $
                                          {balance.toLocaleString("en-US", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })}
                                        </span>
                                      </p>
                                      <h5 className="title-24 white">
                                        {assetSelected?.symbol}
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/*1 input*/}
                              {/*1 input*/}
                              <div className="p-20-s">
                                <div className="input-group-dashboard mb-20">
                                  <div className="row">
                                    <div className="col-md-7 col-6">
                                      <p className="white-70">Price</p>
                                      <input
                                        className="title-24 w-100 input-dashboard"
                                        type="number"
                                        placeholder={"0.0"}
                                      />
                                    </div>
                                    <div className="col-md-5 col-6 d-flex flex-column justify-content-between text-end my-auto">
                                      <h5 className="title-24 white">
                                        USD/EUR
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="tab-pane fade"
                              id="tpsl-short"
                              role="tabpanel"
                              aria-labelledby="tpsl-short-tab"
                            >
                              <div className="p-20-s">
                                {/*1 input*/}
                                <div className="input-group-dashboard  mb-10">
                                  <div className="row">
                                    <div className="col-md-7 col-6">
                                      <p className="white-70">Close</p>
                                      <input
                                        className="title-24 w-100 input-dashboard"
                                        type="number"
                                        placeholder={"0.0"}
                                        onChange={(e) => {
                                          const value = e.target.value.replace(
                                            /^\$/,
                                            ""
                                          );
                                          //fonction set le prix
                                        }}
                                      />
                                    </div>
                                    <div className="col-md-5 col-6 d-flex flex-column justify-content-between text-end my-auto">
                                      <p className="white-70">
                                        Balance :{" "}
                                        <span className="white">
                                          {" "}
                                          $
                                          {balance.toLocaleString("en-US", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })}
                                        </span>
                                      </p>
                                      <h5 className="title-24 white">
                                        USD/EUR
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/*1 input*/}
                              {/*1 input*/}
                              <div className="p-20-s">
                                <div className="input-group-dashboard mb-20">
                                  <div className="row">
                                    <div className="col-md-7 col-6">
                                      <p className="white-70">Price</p>
                                      <input
                                        className="title-24 w-100 input-dashboard"
                                        type="number"
                                        placeholder={"0.0"}
                                        onChange={(e) => {
                                          const value = e.target.value.replace(
                                            /^\$/,
                                            ""
                                          );
                                          //fonction set le prix
                                        }}
                                      />
                                    </div>
                                    <div className="col-md-5 col-6 d-flex flex-column justify-content-between text-end my-auto">
                                      <h5 className="title-24 white">
                                        USD/EUR
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/*time in force select*/}

                        <div className="mb-3 p-20-s">
                          <select
                            id="orderTypeSelect"
                            className="form-select "
                            value={timeInForce}
                            onChange={(e) => setTimeInForce(e.target.value)}
                            disabled={
                              assetSelected && assetSelected.class === "crypto"
                            }
                          >
                            <option value="">Sélectionnez un Type</option>
                            <option value="day">Day</option>
                            <option value="gtc">
                              GTC - Good till Canceled
                            </option>
                            <option value="fok">Fill or Kill</option>
                            <option value="ioc">Immediate or Cancel</option>
                            <option value="opg">At the Open</option>
                            <option value="cls">At the Close</option>
                          </select>
                        </div>
                        {/*leverage slider*/}
                        <div className="p-20-s mb-20">
                          <p className="text-mini white-70">
                            Choose your leverage
                          </p>
                          <div className="row">
                            <div className="input-group-dashboard mb-20">
                              <input
                                value={leverage ? leverage : ""}
                                onChange={(e) => {
                                  const value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  );
                                  setLeverage(parseFloat(value));
                                }}
                                className="title-24 w-100 input-dashboard"
                                type="text"
                                placeholder="Leverage"
                                disabled={false}
                              />
                            </div>
                            {[2, 4, 10, 20].map((lev) => (
                              <div key={lev} className="col-md-3 col-3">
                                <button
                                  className={`top-title-mini leverage-choice w-100 mb-5 ${
                                    leverage.toString() === `${lev}`
                                      ? "selected"
                                      : ""
                                  }`}
                                  onClick={() => handleLeverageClick(`${lev}`)}
                                >
                                  {lev}x
                                </button>
                              </div>
                            ))}
                          </div>
                        </div>

                        {/*leverage slider*/}
                        {/*1 info*/}
                        <div className="p-20-s">
                          <div className="info-trade-row">
                            <div className="row">
                              <div className="col-md-6 col-6">
                                <p className="text white-70 mb-0">Leverage</p>
                              </div>
                              <div className="col-md-6 col-6 text-end">
                                <p className="text-bold white mb-0 leverage-display">
                                  {leverage}x
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/*1 info*/}
                        <hr className="hr-white-30" />
                        {/*1 info*/}
                        <div className="p-20-s">
                          <div className="info-trade-row">
                            <div className="row">
                              <div className="col-md-6 col-6">
                                <p className="text white-70 mb-10">
                                  Entry Price
                                </p>
                              </div>
                              <div className="col-md-6 col-6 text-end">
                                <p className="text-bold white mb-10">
                                  ${currentPrice}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/*1 info*/}
                        <hr className="hr-white-30" />
                        {/*1 info*/}
                        <div className="p-20-s mb-20">
                          <div className="info-trade-row"></div>
                        </div>
                        {/*1 info*/}
                        <div className="p-20-s text-center">
                          {loadingCreateOrder ? (
                            <div
                              className="spinner-border text-success"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          ) : (
                            <a
                              onClick={handleOpenOrder}
                              className="green-btn d-block"
                            >
                              Open Trade
                            </a>
                          )}
                          {dashboardError && (
                            <p className="text-danger">{dashboardError}</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* POSITIONS MOBILE/TABLETTE */}
                <div className="dashboard-card-position d-block d-lg-none w-100 mb-10">
                  <div className="row">
                    <div className="col-6">
                      <h4 className="title-24 white">Positions</h4>
                    </div>
                    <div className="col-6 text-end"></div>
                  </div>
                  <div className="table-responsive">
                    <table className="table fixed-thead">
                      <thead className="p-20-s">
                        <tr>
                          <th scope="col">
                            <span className="top-title-mini-extra white">
                              Type
                            </span>
                          </th>
                          <th scope="col">
                            <span className="top-title-mini-extra white">
                              Pair
                            </span>
                          </th>
                          <th scope="col">
                            <span className="top-title-mini-extra white">
                              Size
                            </span>
                          </th>
                          <th scope="col">
                            <span className="top-title-mini-extra white">
                              Value USD
                            </span>
                          </th>
                          <th scope="col">
                            <span className="top-title-mini-extra white">
                              Entry
                            </span>
                          </th>
                          <th scope="col">
                            <span className="top-title-mini-extra white">
                              P/L %
                            </span>
                          </th>
                          <th scope="col">
                            <span className="top-title-mini-extra white">
                              Profit USD
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="scrollable-tbody">
                        {positions.length === 0 ? (
                          <tr>
                            <td colSpan={9} className="text-center">
                              <div className="p-50">
                                <h3 className="title-16 white-70">
                                  No positions for the moment
                                </h3>
                              </div>
                            </td>
                          </tr>
                        ) : (
                          positions.map((position) => (
                            <tr className="tr-dashboard" key={position.symbol}>
                              <th scope="row">
                                <a
                                  className="text-decoration-none me-2"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    closePosition(position.symbol);
                                  }}
                                  data-bs-toggle="modal"
                                  data-bs-target="#closetrade"
                                  style={{ zIndex: 999999 }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="12"
                                    height="12"
                                    fill="rgba(255,255,255,1)"
                                  >
                                    <path d="M10.5859 12L2.79297 4.20706L4.20718 2.79285L12.0001 10.5857L19.793 2.79285L21.2072 4.20706L13.4143 12L21.2072 19.7928L19.793 21.2071L12.0001 13.4142L4.20718 21.2071L2.79297 19.7928L10.5859 12Z"></path>
                                  </svg>
                                </a>
                                <span className="top-title-mini green">
                                  {position.side}
                                </span>
                              </th>
                              <td>
                                <span className="top-title-mini white">
                                  {position.symbol}
                                </span>
                              </td>
                              <td>
                                <span className="top-title-mini white">
                                  {Number(position.qty).toFixed(2)}
                                </span>
                              </td>
                              <td>
                                <span className="top-title-mini white">
                                  $
                                  {Number(position.market_value).toLocaleString(
                                    "en-US",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                                </span>
                              </td>
                              <td>
                                <span className="top-title-mini white">
                                  $
                                  {Number(
                                    position.avg_entry_price
                                  ).toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </span>
                              </td>
                              <td>
                                <span
                                  className="top-title-mini"
                                  style={{
                                    color:
                                      Number(
                                        position.unrealized_intraday_plpc
                                      ) > 0
                                        ? "green"
                                        : "red",
                                  }}
                                >
                                  {Math.abs(
                                    Number(position.unrealized_intraday_plpc)
                                  ) < 0.0001
                                    ? Number(
                                        position.unrealized_intraday_plpc
                                      ) > 0
                                      ? ">0.01%"
                                      : "<-0.01%"
                                    : `${(
                                        Number(
                                          position.unrealized_intraday_plpc
                                        ) * 100
                                      ).toFixed(2)}%`}
                                </span>
                              </td>
                              <td className="text-start">
                                <span
                                  className="top-title-mini me-1"
                                  style={{
                                    color:
                                      Number(position.market_value) -
                                        Number(position.avg_entry_price) *
                                          Number(position.qty) >
                                      0
                                        ? "green"
                                        : "red",
                                  }}
                                >
                                  $
                                  {(
                                    Number(position.market_value) -
                                    Number(position.avg_entry_price) *
                                      Number(position.qty)
                                  ).toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </span>
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* POSITIONS MOBILE/TABLETTE */}
                {/* ORDERS MOBILE/TABLETTE */}
                <div className="dashboard-card-position d-block d-lg-none w-100 mb-10">
                  <div className="row">
                    <div className="col-6">
                      <h4 className="title-24 white">Orders</h4>
                    </div>
                    <div className="col-6 text-end">
                      <a
                        className="nav-button-transparent"
                        data-bs-toggle="modal"
                        data-bs-target="#orderhistorymodal"
                        style={{ zIndex: 999999 }}
                      >
                        History
                      </a>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table fixed-thead">
                      <thead className="p-20-s">
                        <tr>
                          <th scope="col">
                            <span className="top-title-mini-extra white">
                              Type
                            </span>
                          </th>
                          <th scope="col">
                            <span className="top-title-mini-extra white">
                              Pair
                            </span>
                          </th>
                          <th scope="col">
                            <span className="top-title-mini-extra white">
                              Size
                            </span>
                          </th>
                          <th scope="col">
                            <span className="top-title-mini-extra white">
                              State
                            </span>
                          </th>
                          <th scope="col">
                            <span className="top-title-mini-extra white">
                              Entry
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="scrollable-tbody">
                        {orders.length === 0 ? (
                          <tr>
                            <td colSpan={9} className="text-center">
                              <div className="p-50">
                                <h3 className="title-16 white-70">
                                  No orders for the moment
                                </h3>
                              </div>
                            </td>
                          </tr>
                        ) : (
                          orders
                            .filter(
                              (order) =>
                                order.status !== "filled" &&
                                order.status !== "canceled"
                            )
                            .map((order, index) => (
                              <tr className="tr-dashboard" key={index}>
                                <th scope="row">
                                  <a
                                    onClick={(e) => {
                                      e.preventDefault();
                                      closeOrder(order.id);
                                    }}
                                    href="#"
                                    className="text-decoration-none me-2"
                                    style={{
                                      zIndex: 999999,
                                      cursor: "pointer",
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      width={12}
                                      height={12}
                                      fill="rgba(255,255,255,1)"
                                    >
                                      <path d="M10.5859 12L2.79297 4.20706L4.20718 2.79285L12.0001 10.5857L19.793 2.79285L21.2072 4.20706L13.4143 12L21.2072 19.7928L19.793 21.2071L12.0001 13.4142L4.20718 21.2071L2.79297 19.7928L10.5859 12Z" />
                                    </svg>
                                  </a>
                                  <span className="top-title-mini green">
                                    {order.type}
                                  </span>
                                </th>
                                <td>
                                  <span className="top-title-mini white">
                                    {order.symbol}
                                  </span>
                                </td>
                                <td>
                                  <span className="top-title-mini white">
                                    {order.qty}
                                  </span>
                                </td>
                                <td>
                                  <span
                                    className={`top-title-mini ${
                                      order.status === "new"
                                        ? "orange"
                                        : "status-canceled"
                                    }`}
                                  >
                                    {order.status}
                                  </span>
                                </td>
                                <td>
                                  <span className="top-title-mini white">
                                    {isNaN(parseFloat(order.filled_avg_price))
                                      ? "-"
                                      : parseFloat(
                                          order.filled_avg_price
                                        ).toFixed(2)}
                                  </span>
                                </td>
                              </tr>
                            ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
