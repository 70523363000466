import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext, useAuth } from "../../hooks/Auth/AuthProvider";
import logoBicolor from "../../media/logo/bicolor.png";
import { useContext, useEffect, useState } from "react";
import { UserStatus } from "../../interfaces/user";

export const Signin = () => {

  const {
    signInWithEmailAndPwd,
    authError,
    currentUser
  } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [redirect, setRedirect] = useState(queryParams.get("redirect"))

  const signupLink =
  redirect === "affiliateDashboard"
      ? "/signup?redirect=affiliateDashboard"
      : "/challenge";

      useEffect(() => {
    
        if (currentUser) {
          if (redirect === "affiliateDashboard") {
             return navigate("/affiliate");
          } else {
            if (currentUser.status === "ACTIVE" || currentUser.status === "FUNDED") {
              navigate("/app");
            } else if (currentUser.status === "PENDING" || currentUser.status === "CLOSED") {
              navigate("/challenge");
            }   else if (authError) {
               setLoading(false);
            }
          } 
        }
      }, [currentUser, navigate, location.search]);


      const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        const email = formData.get("email") as string;
        const password = formData.get("password") as string;
      
        setLoading(true);
      
        const user = await signInWithEmailAndPwd(email, password);
        if (redirect === "affiliateDashboard") {
          return navigate("/affiliate");
        } 
        if (user && (user.status === UserStatus.ACTIVE || user.status === UserStatus.FUNDED)) {
          return navigate("/app");
        }
        else if (user && (user.status === UserStatus.PENDING || user.status === UserStatus.CLOSED)) {
          return navigate("/challenge");
        } 
        else {
          setLoading(false); 
        }
      };   
      
  return (
    <>
      <div className="bg-full">
        <section className=" section-100 section-full d-flex justify-content-center align-items-center">
          <div className="custom-container">
            <div className="row justify-content-center">
              <div className="col-md-5 text-center">
                <Link to="/">
                  <img
                    className="mb-20"
                    src={logoBicolor}
                    style={{ width: "150px", height: "auto" }}
                    alt=""
                  />
                </Link>
                <h1 className="title-50 white mb-30">Sign in</h1>
                <p className="white mb-30">Welcome back !</p>
                <form onSubmit={handleSubmit}>
                  <div className="input-groupp mb-20 text-start">
                    <span className="input-label white mb-10 d-block">
                      E-mail
                    </span>
                    <input
                      required
                      name="email"
                      className="input-blue d-block"
                      type="email"
                      placeholder="Enter your pseudo"
                    />
                  </div>
                  <div className="input-groupp mb-20 text-start">
                    <span className="input-label white mb-10 d-block">
                      Password
                    </span>
                    <input
                      required
                      name="password"
                      className="input-blue d-block"
                      type="password"
                      placeholder="Enter your password"
                    />
                  </div>
                  {authError && (
                    <p className="text-danger red mb-10">{authError}</p>
                  )}
                  <button
                    className="blue-btn w-100 d-block mb-10"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </>
                    ) : (
                      "Connexion"
                    )}
                  </button>
                </form>
                <Link className="a-link" to="/forgotpassword">
                  Forgot your password ?
                </Link>
                <p className="text-regular white m-2">
                  Do you want to create an account ?{" "}
                  <a href={signupLink} className="a-link">
                    Sign up here
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
