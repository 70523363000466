import { useEffect, useState } from "react";
import logoBicolor from "../../media/logo/bicolor.png";
import { useAuth } from "../../hooks/Auth/AuthProvider";
import { Link, useLocation, useNavigate } from "react-router-dom";
import biglogoglow from "../../media/biglogoglow.svg";
import Hotjar from "@hotjar/browser";
import { toast } from "react-toastify";

export const SignupForm = () => {
  const {
    currentUser,
    createAccount,
    authError,
    setAuthError,
  } = useAuth();
  const [userForm, setUserForm] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const location = useLocation();
  const navigate = useNavigate();
  const [signUpLoading, setSignUpLoading] = useState(false);
  const [formDone, setFormDone] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState(false);
  const getRedirectParam = () => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get("redirect");
  };

  const redirectParam = getRedirectParam();

  useEffect(() => {
    const redirectParam = getRedirectParam();

    if (currentUser) {
      if (redirectParam === "affiliateDashboard") {
        navigate("/affiliate");
      } else {
        if (
          currentUser.status === "ACTIVE" ||
          currentUser.status === "FUNDED"
        ) {
          navigate("/app");
        } else if (
          currentUser.status === "PENDING" ||
          currentUser.status === "CLOSED"
        ) {
          navigate("/challenge");
        }
      }
    }
  }, [currentUser, navigate, location.search]);

  const isObjectFieldsValid = (obj) => {
    return Object.values(userForm).every((value) => value.trim() !== "");
  };

  const handleSubmit = async () => {
    setSubmitted(true);
    if (userForm.password !== userForm.confirmPassword) {
      toast.error("Passwords do not match.");
      return false;
    }
    if (!isObjectFieldsValid(userForm) && !currentUser) {
      toast.error("Please fill out all required fields.");
      return false;
    }
    setSignUpLoading(true);

    try {
      setFormDone(true);
      await createAccount(userForm.email, userForm.password, {
        firstName: userForm.firstname,
        lastName: userForm.lastname,
      });
      return true;
    } catch (error) {
      console.log(error);
      return false;
    } finally {
      setSignUpLoading(false);
    }
  };

  return (
    <div>
      <section className=" section-100 section-full d-flex justify-content-center align-items-center">
        <div className="custom-container">
          <div className="row gx-5 align-items-center">
            <div className="col-md-6">
              <div className="mb-50">
                <a
                  data-bs-toggle="modal"
                  data-bs-target="#attention"
                  style={{ zIndex: 999999 }}
                >
                  <img
                    src={logoBicolor}
                    style={{ width: "180px", height: "auto" }}
                    alt=""
                    data-aos="fade-in"
                    data-aos-easing="linear"
                    data-aos-anchor-placement="top-bottom"
                  />
                </a>
              </div>
              <div className="col-md-12">
                <div className="row d-flex ">
                  <div className="col-md-10 col-12">
                    <h2
                      className="title-40 white mb-30"
                      data-aos="fade-in"
                      data-aos-easing="linear"
                      data-aos-anchor-placement="top-bottom"
                    >
                      Create your account
                    </h2>
                    <p className="text-regular white-70 mb-30 ">
                      Tailor your account to suit your trading style and
                      preference.
                    </p>
                    <div className="row ">
                      <div className="col-md-6 mb-20">
                        <span className="input-label white mb-10 d-block text-start">
                          Firstname
                        </span>
                        <input
                          required
                          name="firstname"
                          className={`input-blue d-block ${
                            submitted && !userForm.firstname
                              ? "input-error"
                              : ""
                          }`}
                          type="text"
                          placeholder="Enter your Firstname"
                          value={userForm.firstname}
                          onChange={(e) =>
                            setUserForm({
                              ...userForm,
                              firstname: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="col-md-6 mb-20">
                        <span className="input-label white mb-10 d-block text-start">
                          Lastname
                        </span>
                        <input
                          required
                          name="lastname"
                          className={`input-blue d-block ${
                            submitted && !userForm.lastname
                              ? "input-error"
                              : ""
                          }`}
                          type="text"
                          placeholder="Enter your Lastname"
                          value={userForm.lastname}
                          onChange={(e) =>
                            setUserForm({
                              ...userForm,
                              lastname: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <hr className="hr-white-50 mb-30" />
                    <div className="mb-20">
                      <span className="input-label white mb-10 d-block text-start">
                        E-mail
                      </span>
                      <input
                        required
                        name="email"
                        className={`input-blue d-block ${
                          submitted && !userForm.email
                            ? "input-error"
                            : ""
                        }`}
                        type="email"
                        placeholder="Enter your e-mail"
                        value={userForm.email}
                        onChange={(e) =>
                          setUserForm({
                            ...userForm,
                            email: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="mb-20">
                      <span className="input-label white mb-10 d-block text-start">
                        Password
                      </span>
                      <input
                        required
                        name="password"
                        className={`input-blue d-block ${
                          submitted && !userForm.password
                            ? "input-error"
                            : ""
                        }`}
                        type="password"
                        placeholder="Choose your password"
                        value={userForm.password}
                        onChange={(e) =>
                          setUserForm({
                            ...userForm,
                            password: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className=" mb-30">
                      <span className="input-label white mb-10 d-block text-start">
                        Confirm password
                      </span>
                      <input
                        required
                        name="confirmPassword"
                        className={`input-blue d-block ${
                          submitted && !userForm.confirmPassword
                            ? "input-error"
                            : ""
                        }`}
                        type="password"
                        placeholder="Confirm your password"
                        value={userForm.confirmPassword}
                        onChange={(e) =>
                          setUserForm({
                            ...userForm,
                            confirmPassword: e.target.value,
                          })
                        }
                      />
                    </div>

                    {authError && (
                      <p className="text-regular mb-10">{authError}</p>
                    )}
                  </div>
                </div>
                <button
                  className="blue-btn w-80"
                  onClick={handleSubmit}
                  disabled={signUpLoading}
                >
                  {signUpLoading ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                  ) : (
                    "Create account"
                  )}
                </button>
              </div>
              <p className="text-regular white m-2">
                Already have an account ?{" "}
                <a href={ redirectParam === "affiliateDashboard" ? '/signin?redirect=affiliateDashboard' : "/signin" } className="a-link">
                  Log In here
                </a>
              </p>
            </div>
            <div className="col-md-5 offset-md-1 text-center justify-content-center d-none d-md-block">
              <img className="img-fluid biglogoglow" src={biglogoglow} alt="" />
            </div>
          </div>
        </div>
      </section>
      <div
        className="modal fade "
        id="attention"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div
            className="modal-content text-center"
            style={{ borderRadius: "30px" }}
          >
            <div className="modal-header text-end">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <h2 className="title-50 red">Attention !</h2>
              <p className="text-regular white mb-30">
                You are about to leave the challenge registration process. If
                you return to the homepage, any entered information may not be
                saved. Are you sure you want to leave?
              </p>
              <button
                className="blue-btn w-100 d-block mb-10"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Continue registration
              </button>
              <a className="a-link" href="index.html">
                Quit
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
