import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import pricingData from "../../config/pricing.json";
import pricingDATA from "../../config/pricing.json";
import ReactPixel from "react-facebook-pixel";
import { Foot } from "../../components/foot";
import { Nav } from "../../components/nav";
import Upi from "../../media/upi.png";
import CARDImg from "../../media/securepayments.png";
import VisaImg from "../../media/logo/visa-fill.svg";
import AmexImg from "../../media/logo/amex-fill.svg";
import MasterCardImg from "../../media/logo/mastercard-line.svg";
import { numberWithSpaces } from "../../utils/number";
import { Modal, Spinner } from "react-bootstrap";
import { API_URL } from "../../config/api";
import axios from "axios";
import { AuthContext, useAuth } from "../../hooks/Auth/AuthProvider";
import { useCheckout } from "../../hooks/Checkout/useCheckout";
import { useSlowRefresh } from "../../hooks/Refresh/useRefresh";
import { UserStatus } from "../../interfaces/user";
import {
  formatCVV,
  formatCardNumber,
  formatExpirationDate,
  checkFormatDate,
  detectCardType,
} from "../../utils/card";
import { toast } from "react-toastify";

export interface PostCheckoutData {
  challengeId: number;
  insurance: boolean;
  profitSplit: boolean;
  discountCode?: string;
  referral?: string;
  cardNumber?: string;
  cardExpiryDate?: string;
  cardCCV?: string;
}
export const CheckoutForm = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const checkoutId = queryParams.get("session_id");
  const options = queryParams.get("options");
  const insuranceAdded = options === "1" || options === "3";
  const profitSplit = options === "2" || options === "3";
  const indexSelected = parseInt(queryParams.get("indexselected"), 10);
  const selectedPlatform = queryParams.get("platform");
  const [selectedMethodPayment, setSelectedMethodPayment] = useState("card");
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [show, setShow] = useState(false);
  const refresh = useSlowRefresh();
  const navigate = useNavigate();
  const { getCheckout, checkDiscountCode } = useCheckout();
  const [cardDetails, setCardDetails] = useState({
    cardNumber: "",
    cardExpiryDate: "",
    cardCCV: "",
  });
  const referral = queryParams.get("ref");
  const [challengeId, setChallengeId] = useState(
    queryParams.get("challenge-id")
  );
  const [currency, setCurrency] = useState(
    queryParams.get("currency") || "eur"
  );
  const [selectedPhase, setSelectedPhase] = useState(null);
  const [challengeSize, setChallengeSize] = useState(null);
  const [promo, setPromo] = useState(queryParams.get("promo"));
  const [totalPrice, setTotalPrice] = useState(null);
  const [discountActive, setDiscountActive] = useState(false);
  const { jswToken } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [promoError, setPromoError] = useState("");
  const [cardType, setCardType] = useState(null);

  const handlePromoCode = async (promoCode: string) => {
    if (promoCode.length < 1) return;

    const discount = await checkDiscountCode(promoCode.toUpperCase());

    if (!discount) {
      setPromoError("Invalid promo code");
      return;
    }

    if (
      !discount.challenges.some(
        (item) => item.challengeId === parseInt(challengeId, 10)
      )
    ) {
      setDiscountActive(false);
      setPromoError("Invalid challenge for this code");
      return;
    }

    setPromoError("");
    setDiscountActive(true);
    setTotalPrice((prevPrice) =>
      Math.round(prevPrice - prevPrice * (discount.discount / 100))
    );
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const challengeIdParam = searchParams.get("challenge-id");
    const challengeIndex = parseInt(challengeIdParam, 10) - 1;

    if (isNaN(challengeIndex) || challengeIndex < 0) {
      console.error("Invalid challenge index");
      return;
    }
    const determinedCategory =
      challengeIndex >= 0 && challengeIndex <= 7 ? "1" : "2";
    setSelectedPhase(determinedCategory);
    const challenges = pricingDATA[currency]?.[determinedCategory];
    if (challenges) {
      let selectedChallenge;
      if (determinedCategory === "2") {
        selectedChallenge = challenges[challengeIndex - 8];
      } else {
        selectedChallenge = challenges[challengeIndex];
      }
      if (selectedChallenge) {
        setChallengeSize(selectedChallenge.size);
        setTotalPrice(selectedChallenge.base);

        // Apply promo code if present in URL
        if (promo) {
          handlePromoCode(promo);
        }
      }
    } else {
      console.error("Challenge index out of bounds or challenges not found");
    }
  }, [currency, challengeId, promo, pricingDATA]);

  useEffect(() => {
    setTotalPrice((prev) => {
      if (prev === null || selectedPhase === null || isNaN(indexSelected)) {
        return prev;
      }

      const challenges = pricingDATA[currency]?.[selectedPhase];
      if (!challenges || indexSelected >= challenges.length) {
        console.error("Index selected out of bounds or challenges not found");
        return prev;
      }

      let newPrice = prev;
      if (options === "3") {
        newPrice += challenges[indexSelected].insurance;
        newPrice += challenges[indexSelected].pf;
      } else if (options === "2") {
        newPrice = challenges[indexSelected].pf;
      } else if (options === "1") {
        newPrice += challenges[indexSelected].insurance;
      }

      return parseFloat(newPrice.toFixed(2));
    });
  }, [options, challengeId, currency, selectedPhase, indexSelected]);

  const handlePaiementCard = async () => {
    if (
      !cardDetails.cardNumber ||
      !cardDetails.cardExpiryDate ||
      !cardDetails.cardCCV
    ) {
      toast.error("Please fill in all card details.");
      return;
    }
    const formattedExpiryDate = checkFormatDate(cardDetails.cardExpiryDate);
    console.log(formattedExpiryDate);
    if (!formattedExpiryDate) {
      toast.error(
        "Please enter a valid expiration date in the format MM/YYYY."
      );
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${jswToken}`,
        "Content-Type": "application/json",
      },
    };

    const requestData: PostCheckoutData = {
      challengeId: Number(challengeId),
      insurance: options === "1" || options === "3",
      profitSplit: options === "2" || options === "3",
      cardNumber: cardDetails.cardNumber.replace(/\s+/g, ""),
      cardExpiryDate: checkFormatDate(cardDetails.cardExpiryDate),
      cardCCV: cardDetails.cardCCV,
    };

    if (promo) {
      requestData.discountCode = promo;
    }

    if (referral) {
      requestData.referral = referral;
    }

    try {
      console.log(requestData);
      const response = await axios.post(
        `${API_URL}/checkout/create-checkout`,
        requestData,
        config
      );
      const data = response.data;
      return data.urlRedirect;
    } catch (error) {
      console.error("Error fetching the checkout URL:", error);
      return null;
    }
  };

  const handleCreateCheckoutInpi = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${jswToken}`,
        "Content-Type": "application/json",
      },
    };

    const requestData: PostCheckoutData = {
      challengeId: Number(challengeId),
      insurance: options === "1" || options === "3",
      profitSplit: options === "2" || options === "3",
    };

    if (promo) {
      requestData.discountCode = promo;
    }

    if (referral) {
      requestData.referral = referral;
    }

    try {
      const response = await axios.post(
        `${API_URL}/checkout/create-checkout-upi`,
        requestData,
        config
      );
      const data = response.data;
      return data.redirectUrl;
    } catch (error) {
      console.log(error);
      console.error("Error fetching the INPI checkout URL:", error);
      return null;
    }
  };

  const handleProceedToPayment = async () => {
    setLoading(true);
    const url = await handlePaiementCard();
    if (url) {
      window.location.href = url;
    } else {
      setLoading(false);
      toast.error(
        "An error occurred while processing your payment. Please try again."
      );
    }
  };

  const handleProceedToInpiPayment = async () => {
    setLoading(true);
    const url = await handleCreateCheckoutInpi();
    if (url) {
      window.location.href = url;
    } else {
      toast.error(
        "An error occurred while processing your payment. Please try again."
      );
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "expirationDate") {
      const formattedValue = formatExpirationDate(value);
      setCardDetails((prevDetails) => ({
        ...prevDetails,
        cardExpiryDate: formattedValue,
      }));
    } else if (name === "cvv") {
      const formattedValue = formatCVV(value);
      setCardDetails((prevDetails) => ({
        ...prevDetails,
        cardCCV: formattedValue,
      }));
    } else if (name === "cardNumber") {
      const formattedValue = formatCardNumber(value);
      setCardDetails((prevDetails) => ({
        ...prevDetails,
        cardNumber: formattedValue,
      }));
      setCardType(detectCardType(formattedValue.replace(/\s+/g, "")));
    } else {
      setCardDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
    }
  };

  return (
    <>
      <Nav />
      <section className="section-100">
        <div className="checkout-progress">
          <div className="checkout-progress-stage ">
            <span className="checkout-progress-stage__number title-16">1</span>
            <div className="checkout-progress-stage__content">
              <div className="flex-col">
                <span className="title-16 ">Previous stage</span>
                <br />
                <span className="title-18">Choose Your Account</span>
              </div>
            </div>
          </div>
          <div className="checkout-progress-stage checkout-progress-stage--active">
            <span className="checkout-progress-stage__number title-16">2</span>
            <div className="checkout-progress-stage__content">
              <div className="flex-col">
                <span className="title-16 white-70">You Are Here</span>
                <br />
                <span className="title-18">Review Your Order</span>
              </div>
            </div>
          </div>
          <div className="checkout-progress-stage">
            <span className="checkout-progress-stage__number title-16">3</span>
            <div className="checkout-progress-stage__content">
              <div className="flex-col">
                <span className="title-16 white-70">Last Stage</span>
                <br />
                <span className="title-18">Start trading</span>
              </div>
            </div>
          </div>
        </div>
        <div className="container justify-content-center d-flex">
          <div className="col-12 col-md-6" style={{ top: "100px" }}>
            <div className="card-payment text-center mt-5">
              <h3 className="title-24 bold blue-gradient">Review Your Order</h3>
              <div className="background-transparent mt-4 p-3">
                <div className="row mt-4">
                  <div className="col-12">
                    <div className="text-white">
                      <div className="row align-items-center">
                        <h4 className="col-6 text-start title-16 ">
                          NextFunded Challenge #{challengeId}
                        </h4>
                        <p className="col-6 title-16 text-end blue-gradient">
                          {totalPrice ? `${totalPrice}$` : "-$"}
                        </p>
                      </div>
                      <hr className="hr-white-50" />
                      <div className="row justify-content-around">
                        <div className="col-12 col-lg-5 card-payment-transparent mb-3 mb-lg-0">
                          <p className="text-start title-16 m-0">Balance</p>
                          <p className="text-end title-16 m-0 blue-gradient">
                            {challengeSize
                              ? numberWithSpaces(challengeSize) + "$"
                              : "-$"}
                          </p>
                        </div>
                        <div className="col-12 col-lg-5 card-payment-transparent">
                          <p className="text-start title-16 m-0">Platform</p>
                          <p className="text-end title-16 m-0 blue-gradient">
                            MetaTrader{selectedPlatform}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <section className="section-30 background-transparent  mt-4">
                <h3 className="title-24 bold blue-gradient">
                  Choose Payment Method
                </h3>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => setSelectedMethodPayment("card")}
                  className={`background-transparent py-3 my-3 container ${
                    selectedMethodPayment === "card"
                      ? "payment-selector-selected"
                      : ""
                  }`}
                >
                  <div className="row">
                    <div className="col-12 d-flex flex-column flex-lg-row align-items-start align-items-lg-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <input
                          type="radio"
                          name="phaseSelection"
                          checked={selectedMethodPayment === "card"}
                          onChange={() => {}}
                        />
                        <span className="title-16 white d-inline mx-2">
                          Pay by card
                        </span>
                      </div>
                      <img
                        src={CARDImg}
                        alt="UPI"
                        className="mt-2 mt-lg-0 ms-lg-2 img-fluid"
                        style={{ height: "17px" }}
                      />
                    </div>
                  </div>
                  {selectedMethodPayment === "card" && (
                    <>
                      <div className="row mb-3 mt-3">
                        <div className="col-12 position-relative">
                          <input
                            className="input-blue"
                            type="text"
                            placeholder="Card Number"
                            name="cardNumber"
                            value={cardDetails.cardNumber}
                            onChange={handleChange}
                            style={{ paddingRight: "40px" }}
                          />
                          {cardType && (
                            <img
                              src={
                                cardType === "visa"
                                  ? VisaImg
                                  : cardType === "mastercard"
                                  ? MasterCardImg
                                  : AmexImg
                              }
                              alt={cardType}
                              className="position-absolute white-70"
                              style={{
                                width: "35px",
                                height: "auto",
                                right: "10px",
                                top: "50%",
                                transform: "translateY(-50%)",
                              }}
                            />
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-5 col-12 mb-3">
                          <input
                            className="input-blue d-block"
                            type="text"
                            placeholder="Expiration Date (MM/YY)"
                            name="expirationDate"
                            value={cardDetails.cardExpiryDate}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-5 col-12 offset-md-2">
                          <input
                            className="input-blue d-block "
                            type="text"
                            placeholder="CVV"
                            name="cvv"
                            value={cardDetails.cardCCV}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => setSelectedMethodPayment("crypto")}
                  className={`background-transparent py-3 my-3 container ${
                    selectedMethodPayment === "crypto"
                      ? "payment-selector-selected"
                      : ""
                  }`}
                >
                  <div className="row">
                    <div className="col-12 d-flex flex-column flex-lg-row align-items-start align-items-lg-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <input
                          type="radio"
                          name="phaseSelection"
                          checked={selectedMethodPayment === "crypto"}
                          onChange={() => setSelectedMethodPayment("crypto")}
                        />
                        <span className="title-16 white mx-2">UPI payment</span>
                      </div>
                      <img
                        src={Upi}
                        alt="UPI"
                        className="mt-2 mt-lg-0 ms-lg-2 img-fluid"
                        style={{ height: "17px" }}
                      />
                    </div>
                  </div>
                </div>
              </section>

              <section className=" background-transparent  mt-4">
                <h3 className="title-24 bold blue-gradient">
                  Total Cost: <span>{totalPrice}$</span>
                </h3>
              </section>
              {selectedMethodPayment === "card" ? (
                <button
                  className="blue-btn p-3 mt-3 w-80"
                  onClick={handleProceedToPayment}
                  disabled={loading}
                  style={{ position: "relative", minWidth: "200px" }}
                >
                  {loading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="sr-only">Loading...</span>
                    </>
                  ) : (
                    "Proceed to payment"
                  )}
                  <span style={{ visibility: "hidden", position: "absolute" }}>
                    Proceed to payment
                  </span>
                </button>
              ) : (
                <button
                  disabled={loading}
                  className="blue-btn p-3 mt-3 w-80"
                  onClick={handleProceedToInpiPayment}
                  style={{ position: "relative", minWidth: "200px" }}
                >
                  {loading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </>
                  ) : (
                    "  Proceed to payment"
                  )}
                </button>
              )}
            </div>
          </div>
        </div>
      </section>
      <Foot />
    </>
  );
};
