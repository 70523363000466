import React, { useState } from 'react';
import { useWithdraw } from '../../hooks/Withdraw/useWithdraw';
import { Withdraws } from '../../interfaces/withdraw';

export const Withdraw = () => {
    const [amount, setAmount] = useState("");
    const [walletAddress, setWalletAddress] = useState("");
    const [isError, setIsError] = useState(false); 
    const {createWithdraw} = useWithdraw();

    const handleSubmit = (event) => {
        event.preventDefault();
        const withdraw : Withdraws = {
            wallet: walletAddress,
            funds: amount
        };
        console.log(withdraw);
    };

    return (
        <div className="text-center custom-container section-100">
            <div className="mb-20">
                <span className="blue-pill mb-10">5% Max withdraw</span>
            </div>
            <h2 className="title-50 white">Withdraw</h2>
            <p className="text-regular white mb-30">
                After sending your withdraw request, you will receive a confirmation by e-mail in 24 hours.
            </p>
            <h5 className="title-16 white mb-30">Funds available: $5,039.12</h5>
            <form onSubmit={handleSubmit}>
                <div className="input-group mb-20 text-start">
                    <span className="input-label white mb-10 d-block">Available balance</span>
                    <input
                        required
                        className="input-blue d-block w-100"
                        type="text"
                        placeholder="$5,023.92"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                    />
                </div>
                <div className="input-group mb-20 text-start">
                    <span className="input-label white mb-10 d-block">ETH wallet address</span>
                    <input
                        required
                        className="input-blue d-block w-100"
                        type="password"
                        placeholder="Enter your wallet address"
                        value={walletAddress}
                        onChange={(e) => setWalletAddress(e.target.value)}
                    />
                </div>
                <div className="d-grid gap-2 d-md-flex justify-content-md-center d-flex justify-content-center">
                    <button className="blue-btn mb-10" style={{ width: "50%" }} type="submit">Send request</button>
                </div>
            </form>
        </div>
    );
};
