import React from "react";
import { API_URL } from "../config/api";
import axios from "axios";
import { toast } from "react-toastify";
import { FirebaseError } from "firebase/app";


export const getData = async (jswToken: string, url: string, setData: (data: any) => void) => {
    try {
      const response = await axios.get(`${API_URL}${url}`, {
        headers: {
          Authorization: `Bearer ${jswToken}`, 
        },
      });
      setData(response.data);
    } catch (error) {
      console.error("Erreur lors de la récupération de la data :", error);
    }
  };

  export const postDataWithToken = async (
    jswToken: string,
    url: string,
    messageSuccess: string,
    setLoading: (loading: boolean) => void,
    data?: any,
  ) => {
    setLoading(true); // Débute le chargement
    try {
      const response = await axios.post(`${API_URL}${url}`, data, {
        headers: {
          Authorization: `Bearer ${jswToken}`,
        },
      });
      console.log(response);
      toast.success(messageSuccess);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false); // Termine le chargement
    }
  };
  




// Définir un type pour les erreurs d'authentification
export type AuthErrorType = 'user-not-found' | 'auth/invalid-credential' | 'other' | 'auth/email-already-exists' | 'auth/invalid-email' | 'auth/weak-password';

// Function to get the error message based on the error type
export const getAuthErrorMessage = (error: FirebaseError): string => {
  switch (error.code) {
    case 'auth/user-not-found':
      return "User not found.";
    case 'auth/invalid-credential':
      return "Invalid email or password.";
    case 'auth/email-already-exists':
      return "An account with this email already exists.";
    case 'auth/invalid-email':
      return "Invalid email.";
    case 'auth/weak-password':
      return "Password must be at least 6 characters long.";
    default:
      return "An error occurred while signing in.";
  }
};


    