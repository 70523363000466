/*
 Pour que ce soit clean, la config firebase qui est donnée dans le dashboard de firebase
 https://console.firebase.google.com/u/2/project/nextfunded-3c394/settings/general/web:YmU0ODBlMjQtZTFkMS00Y2NhLTk0NmItZmRhMzU4Y2FjNGJk
*/
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

export const firebaseConfig = {
    apiKey: "AIzaSyBf1Kxv0c6Ko8fgf06pFka9iWUG6WuV2CQ",
    authDomain: "nextfunded-3c394.firebaseapp.com",
    projectId: "nextfunded-3c394",
    storageBucket: "nextfunded-3c394.appspot.com",
    messagingSenderId: "793460553097",
    appId: "1:793460553097:web:da222caea63e7776865d58",
    measurementId: "G-7XR9MD7L4S"
};

const app = initializeApp(firebaseConfig)
export const auth = getAuth(app);


export default app


