import { useEffect, useState } from "react";
import { Order } from "../../interfaces/order";
import axios from "axios";
import { API_URL } from "../../config/api";
import { useAuth } from "../Auth/AuthProvider";
import { toast } from "react-toastify";
import { usePositions } from "../Positions/usePositions";

export const useOrders = () => {

    const [orders, setOrders] = useState<Order[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [loadingOrders, setLoadingOrders] = useState<boolean>(false);
    const [loadingCreateOrder, setLoadingCreateOrder] = useState<boolean>(false);
    const { jswToken } = useAuth();

    
    const createOrder = async (order: Order, asssetClass : string) => {
        console.log(order);
        setLoadingCreateOrder(true);
        try {
            const response = await axios.post(`${API_URL}/orders/create-order`, order, {
                headers: {
                    Authorization: `Bearer ${jswToken}`,
                },
            });
            setOrders([...orders, response.data as Order])
            getAllOrders();
            toast.success("Order created successfully");
            return response.data as Order;

        } catch (error) {
            console.error("ORDER" +  error);
            setError(error)
            toast.error(error.message)
        } finally {
            setLoadingCreateOrder(false);
        }
    }


    const closeOrder = async (id: string) => {
        try {
          const response = await axios.post(`${API_URL}/orders/close-order/${id}`, {}, {
            headers: {
              Authorization: `Bearer ${jswToken}`,
            },
          });
          setOrders(orders.filter(order => order.id !== id));
          toast.success("Order closed successfully");
          return response.data as Order; 
        } catch (error) {
          console.error("ORDER" + error);
          toast.error(error.message)
        }
    };
      

    const getOrderById = async (id: number) => {
        try {
            const response = await axios.get(`${API_URL}/orders/${id}`, {
                headers: {
                    Authorization: `Bearer ${jswToken}`,
                },
            });
            setOrders([...orders, response.data as Order])
            return response.data as Order;
        } catch (error) {
            console.error("ORDER" + error);
        }
    }

    const getAllOrders = async () => {
        setLoadingOrders(true);
        try {
            const response = await axios.get(`${API_URL}/orders/all`, {
                headers: {
                    Authorization: `Bearer ${jswToken}`,
                },
            });
            setOrders(response.data as Order[])
        } catch (error) {
            console.error("ORDER" + error);
            setError(error)
        } finally {
            setLoadingOrders(false);
        }
    }

    useEffect(() => {
        getAllOrders();
    }, [])


    return { orders, getAllOrders, createOrder, getOrderById, closeOrder, loadingOrders, loadingCreateOrder }
}