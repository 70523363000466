import React, { useState } from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { toast } from "react-toastify";
import logoBicolor from "../../media/logo/bicolor.png";
import { Link, useNavigate } from "react-router-dom";

export const Forgotpassword = () => {
  const [email, setEmail] = useState("");
  const [ForgotPasswordError, setForgotPasswordError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleResetPassword = async () => {
    const auth = getAuth();
    setLoading(true);
    try {
      await sendPasswordResetEmail(auth, email);
      toast.success("Your password reset request has been sent. Please check your email for instructions on how to reset your password.");
      navigate("/");
    } catch (err) {
      setForgotPasswordError(
        "Failed to send password reset email. Please try again." + err.message
      );
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <div className="bg-full">
        <section className=" section-100 section-full d-flex justify-content-center align-items-center">
          <div className="custom-container">
            <div className="row justify-content-center">
              <div className="col-md-5 text-center">
                <Link to="/">
                  <img
                    className="mb-20"
                    src={logoBicolor}
                    style={{ width: "150px", height: "auto" }}
                    alt=""
                  />
                </Link>
                <h1 className="title-50 white mb-30">Forget your password ?</h1>
                <p className="white mb-30">
                  Enter your e-mail and you will receive a mail to set your new
                  password
                </p>
                <div className="input-group mb-20">
                  <input
                    className="input-blue d-block"
                    type="email"
                    placeholder="Enter your e-mail"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                {ForgotPasswordError && (
                  <p className="text-regular red">{ForgotPasswordError}</p>
                )}
                <button
                  onClick={handleResetPassword}
                  className="blue-btn mb-20 w-100"
                  style={{ zIndex: 999999 }}
                >
                  Reset my password
                  {loading && (
                    <span
                      className="spinner-border spinner-border-sm "
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
