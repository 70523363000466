import { useAuth } from "../hooks/Auth/AuthProvider";
import bicolorLogo from "../media/logo/bicolor.png";

export const Nav = () => {

  const { currentUser } = useAuth();
    const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <>
      {/* DESKTOP */}
      <nav className="fixed-top d-none d-md-block">
        <div className="nav-container">
          <div className="navbarz row align-items-center justify-content-between">
            <div className="col-auto">
              <a href="/">
              <img
                src={bicolorLogo}
                style={{ width: "150px", height: "auto" }}
                alt=""
              />
            </a>
            </div>
            {
              !currentUser && (
                <a
                  style={{ cursor: "pointer", width:"150px" }}
                  className="blue-btn  mr-10 text-center  p-1"
                  href="/signin"
                >
                  Sign in 
                </a>
              )
            }

          </div>
        </div>
      </nav>

      {/* MOBILE */}
      <nav className=" navbarz-mobile fixed-top d-block d-md-none">
        <div className="nav-container">
          <div className="row align-items-center justify-content-between mx-auto my-auto">
            <div className="col-auto">
            <a href="/">
              <img
                src={bicolorLogo}
                style={{ width: "150px", height: "auto" }}
                alt=""
              />
              </a>
            </div>
            {
              !currentUser && (
                <a
              style={{ cursor: "pointer", width:"150px" }}
              className="blue-btn w-50 mr-10 text-center  p-1"
              href="/signin"
            >
              Sign in 
            </a>
              )
            }
          </div>
        </div>
      </nav>
    </>
  );
};
