import { useContext, useEffect, useState } from "react";
import { AuthContext, useAuth } from "../../hooks/Auth/AuthProvider";
import { useSlowRefresh } from "../../hooks/Refresh/useRefresh";
import { useNavigate } from 'react-router-dom';
import { UserStatus } from "../../interfaces/user";
import ReactPixel from 'react-facebook-pixel';
import { useCheckout } from "../../hooks/Checkout/useCheckout";

export const SuccessPage = () => {

  const refresh = useSlowRefresh();
  const navigate = useNavigate();
  const { getUser, currentUser, jswToken } = useContext(AuthContext);
  const { getCheckout } = useCheckout();
  const [showBtn, setShowBtn] = useState(false);

  const searchParams = new URLSearchParams(window.location.search);
  const sessionId = searchParams.get("session_id");


  useEffect(() => {
    const getUserStatus = async () => {
      const user = await getUser(jswToken);

      if (user && user.status === UserStatus.ACTIVE) {
        const checkout = await getCheckout(sessionId);

        if (!checkout) return

        if ((checkout.status === 'SUCCEEDED' || checkout.status === 'AWAITING_APPROVAL')) {
          ReactPixel.track('Purchase', {
            value: checkout.totalPrice ? checkout.totalPrice : 0,
            currency: 'USD',
            discountCode: checkout.discountCodeId ? checkout.discountCodeId : '',
            challenge: checkout.challengeId ? checkout.challengeId : '',
            insurance: checkout.insurance ? checkout.insurance : '',
            profitSplit: checkout.profitSplit ? checkout.profitSplit : '',
          });
          setShowBtn(true);
        }
      }
    }
    getUserStatus();
  }, [refresh]);

  return (
    <div>
      <div>
        <section className="section-100 section-full">
          <div className="custom-container">
            <div className="row justify-content-center align-items-center">
              <div className="col-md-5 text-center">
                <div className="mb-30">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={80} height={80} fill="rgba(0,182,122,1)"><path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z" data-aos="fade-in" data-aos-easing="linear" data-aos-anchor-placement="top-bottom" data-aos-delay={400} /></svg>
                </div>
                <h2 className="title-50 white mb-20" data-aos="fade-in" data-aos-easing="linear" data-aos-anchor-placement="top-bottom" data-aos-delay={400}>Congratulations</h2>
                <p className="text-regular white mb-50 " data-aos="fade-in" data-aos-easing="linear" data-aos-anchor-placement="top-bottom" data-aos-delay={500}>Your payment has been successfully accepted. Please hold on for a moment while we finalize your registration. You'll be all set to go shortly!</p>
                {
                  !showBtn ? (
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <></>
                  )
                }
                <div>
                  {
                    showBtn ? (
                      <div className="row justify-content-center align-items-center">
                        <div className="col-md-6 text-center">
                          <a href="/app"
                            className="blue-btn w-80 mt-4"
                          >
                            Go to APP
                          </a>
                        </div>
                      </div>
                    ) :
                      (
                        <h5 className="text-mini blue-gradient" data-aos="fade-in" data-aos-easing="linear" data-aos-anchor-placement="top-bottom" data-aos-delay={700}>Please do not leave this page while <br />
                          we are finalizing your registration.</h5>
                      )
                  }

                </div>
              </div>

            </div>

          </div>
        </section>
        {/*start trading journey*/}
      </div>
      {/* Sign in modal */}
      <div className="modal fade " id="attention" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content text-center" style={{ borderRadius: '30px' }}>
            <div className="modal-header text-end">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body">
              <h2 className="title-50 red">Attention !</h2>
              <p className="text-regular white mb-30">You are about to leave the challenge registration process. If you return to the homepage, any entered information may not be saved. Are you sure you want to leave?</p>
              <button className="blue-btn w-100 d-block mb-10" data-bs-dismiss="modal" aria-label="Close">Continue registration</button>
              <a className="a-link" href="index.html">Quit</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}